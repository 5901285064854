import { AxiosClient } from './axios-client';
import { ILocation } from '../constants';

const path = 'locations';
export const LocationsApi = {
  getLocations: async (offset?: number, limit?: number, order?: 'asc' | 'desc') => {
    const response: any[] = await AxiosClient.get(`${path}/all`);
    return response;
  },
  getLocationsByCategory: async (category_id: number) => {
    const response: any[] = await AxiosClient.get(`${path}/byCategory?category=${category_id}`);
    return response;
  },
  getLocation: async (id: any) => {
    const response: ILocation = await AxiosClient.get(`${path}?id=${id}`);
    return response;
  },
  deleteLocation: (id: any)=> {
    return AxiosClient.delete(`${path}?id=${id}`);
  },
  addLocation: (data: any) => {
    let body = data;
    if (data.category_id){
      body = { ...data, category_id: parseInt(data.category_id) }
    }else{
      delete body.category_id;
    }
    return AxiosClient.post(`${path}`, body);
  },
  updateLocation: (data: any)=>{
    let body = data;
    if (data.category_id){
      body = { ...data, category_id: parseInt(data.category_id) }
    }else{
      body = { ...data, category_id: 0 }; // set category_id = null
    }
    if (data.cities_secondary){
      data.cities_secondary.push({ value:data.city_code, label: '' });
      data.cities_secondary = data.cities_secondary.filter((item: {value: string; label: string})=> item.value !==null );
      body = { ...data, cities_secondary: data.cities_secondary.map((item: {value: string; label: string})=> (item.value)) }
    }
    return AxiosClient.patch(`${path}`, body);
  },
  importLocation: (file: any) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return AxiosClient.post(`${path}/import`, formData);
  },
  importLocationV2: async (data: any[]) => {
    return await AxiosClient.post(`${path}/import`, data);
  },
  getDescriptionTranslation: async (skip: number, take: number) => {
    const res: {count: number, tags: any[]} = await AxiosClient.get(`${path}/translation?skip=${skip}&take=${take}`);
    return res;
  },
  filterDescriptionTranslation: async (skip: number, take: number, condition: {keyword?: string; category_id?: string; country_code?:string}) => {
    const queryString = {};
    if (condition?.keyword){
      Object.assign(queryString, { keyword: condition.keyword });
    }
    if(condition?.country_code){
      Object.assign(queryString, { country_code: condition.country_code });
    }
    if(condition?.category_id){
      Object.assign(queryString, { category_id: condition.category_id });
    }
    Object.assign(queryString, { skip, take });
    const res: {count: number, tags: any[]} = await AxiosClient.get(`${path}/translation`, { params: queryString });
    return res;
  },
  updateDescriptionTranslation: (data: {locale: string, location: string, description: string}[])=> {
    return AxiosClient.patch(`${path}/translation/multiple`, data);
  },
  getCount: async () => {
    const res: {_all: number} = await AxiosClient.get(`${path}/translation/count`);
    return res._all;
  }
}
