import {
  Box,
  Button,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { IOptionItem } from '../../../constants';
import React, { useContext, useEffect, useState } from 'react';
import { ICoupon, paymentColumns } from '../constants';
import {  grey } from '@mui/material/colors';
import { CouponApi } from '../../../api/coupon.api';
import { CouponTable } from '../components/CouponTable';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../constants';
import { DropdownSearch } from '../../../components/DropdownSearch';
import { LangContext } from '../../../context/language.context';
import { Toast } from '../../../components/Toast';

const brandsList: IOptionItem[] = [
  { value: 'ZE', label: 'Hertz' },
  { value: 'ZR', label: 'Dollar' },
  { value: 'ZT', label: 'Thrifty' },
  { value: 'FF', label: 'Firefly' },
];

export const Coupon = (): JSX.Element => {
  const { dispatch: { translate } } = useContext(LangContext);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [brand, setBrand] = useState('');
  const [cdpNumber, setCDPNumber] = useState('');
  const [pcNumber, setPCNumber] = useState('');
  const [updateCounter, setUpdateCounter] = useState(0);
  const navigate = useNavigate();
  const handleOnchangePage = () => {};

  const [arr, setArr] = useState<any[]>([]);

  const mapData = (fetched_arr: any[]) => {
    const processed_arr: ICoupon[] = [];
    fetched_arr.forEach((c) => {
      const c2: ICoupon = {
        id: c.id,
        no: 'no',
        type: c.type,
        coupon_name: c.coupon_name,
        coupon_code: c.coupon_code,
        brand: c.brand,
        destination: c.destination,
        offer_description: c.offer_description,
        corporate_discount_number: c.corporate_discount_number,
        promotion_code: c.promotion_code,
        min_days: c.min_days,
        car_group: c.car_group,
        pickup_start_time: c.pickup_start_time,
        pickup_end_time: c.pickup_end_time,
        order_start_time: c.order_start_time,
        order_end_time: c.order_end_time,
        issued_count: c.issued_count,
        used_count: c.used_count,
        status: c.status,
        remark: c.remark,
      };
      processed_arr.push(c2);
    });
    return processed_arr;
  };

  const fetchData = async () => {
    try {
      const fetched_arr = await CouponApi.getAll();
      const processed_arr = mapData(fetched_arr);
      setArr(processed_arr);
    } catch (e) {
      setArr([]);
      console.error(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [updateCounter]);

  const handleOnClickRow = (row: number | null) => {
    if (row != null) {
      setSelectedRow(row);
    } else {
      console.log('No row is selected');
    }
  };

  const handleOnClickAddUpdateButton = () => {
    if (selectedRow == null) {
      navigate('/' + RoutePath.ADD_UPDATE_COUPON);
    } else {
      navigate('/' + RoutePath.ADD_UPDATE_COUPON, { state: arr[selectedRow] });
    }
  };

  const handleOnSelectBrand = (brand: IOptionItem) => {
    setBrand(brand.value);
  };

  const handleOnChangeInputCDPNumber = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    e as React.ChangeEvent<HTMLInputElement>;
    // console.log(e.target.value);
    setCDPNumber(e.target.value);
  };

  const handleOnChangeInputPCNumber = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    e as React.ChangeEvent<HTMLInputElement>;
    // console.log(e.target.value);
    setPCNumber(e.target.value);
  };

  const handleOnClickExtract = async () => {
    if (!brand && !cdpNumber && !pcNumber){
      fetchData();
      return;
    }
    try {
      const fetched_arr = await CouponApi.getFilteredCoupons(
        brand,
        cdpNumber,
        pcNumber,
      );
      const processed_arr = mapData(fetched_arr);
      setArr(processed_arr);
    } catch (e) {
      setArr([]);
      console.error(e);
    }
  };

  const handleUpdateStatus = async (row: any) => {
    try{
      await CouponApi.updateCoupon(row.id, {
        status: row.status === 'Inactive' ? 'Active' : 'Inactive',
      });
      setUpdateCounter(updateCounter + 1);
      await Toast.fire({
        title: row.status === 'Inactive' ? 'Active' : 'Deactivate',
        icon: 'success',
      });
    }catch (e) {
      await Toast.fire({
        title: 'Something wrong happened, coupon could not be updated.',
        icon: 'warning',
      });
      return;
    }
  }

  return (
    <Box sx={{ flex: 1 }}>
      <Stack direction="column" spacing={2}>
        <Stack direction="column" bgcolor="#ffff">
          <Typography
            variant="h3"
            sx={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: grey[200],
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              borderTopColor: grey[100],
              p: 2,
            }}
          >
            Coupon
          </Typography>
          <Stack
            direction="row"
            sx={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: grey[200],
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
              p: 2,

              boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%)',
            }}
          >
            <Stack direction="column" minWidth={200} pr={2}>
              <Typography>{translate('supplier')}</Typography>
              <DropdownSearch
                value={brand}
                options={brandsList}
                onSelect={handleOnSelectBrand}
              />
            </Stack>
            <Stack direction="column" minWidth={200} pr={2}>
              <Typography>CDP No.</Typography>
              <OutlinedInput
                size="small"
                onChange={handleOnChangeInputCDPNumber}
              ></OutlinedInput>
            </Stack>
            <Stack direction="column" minWidth={200} pr={2}>
              <Typography>{translate('promotionCouponNumber')}</Typography>
              <OutlinedInput
                size="small"
                onChange={handleOnChangeInputPCNumber}
              ></OutlinedInput>
            </Stack>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                borderRadius: '50%',
                width: 70,
                height: 70,
                ml: 'auto',
                mr: 0,
                fontSize: 12,
                fontWeight: 'bold',
              }}
              onClick={handleOnClickExtract}
            >
              {translate('search')}
            </Button>
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3"></Typography>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={{
              borderRadius: 4,
            }}
            onClick={handleOnClickAddUpdateButton}
          >
            {`${translate('add')}/${translate('update')}`}
          </Button>
        </Stack>

        <CouponTable
          loading={false}
          columns={paymentColumns}
          data={arr}
          onChangePage={handleOnchangePage}
          onClickRow={handleOnClickRow}
          setStatus={handleUpdateStatus}
        ></CouponTable>
      </Stack>
    </Box>
  );
};
