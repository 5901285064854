import { AxiosClient } from './axios-client';

const PATH = 'errors';
export const ErrorMessageApi = {
  list: async (skip:number , take: number) => {
    const result: {count: number, tags: any[]} = await AxiosClient.get(`${PATH}?skip=${skip}&take=${take}`);
    return result;
  },
  filter: async (skip:number , take: number, keyword: string) => {
    const result: {count: number, tags: any[]} = await AxiosClient.get(`${PATH}?skip=${skip}&take=${take}&keyword=${keyword}`);
    return result;
  },
  create: (error_code: string, english_message: string, korean_message: string) => {
    return AxiosClient.post(PATH, { error_code, english_message, korean_message });
  },
  update: (error_code: string, english_message: string, korean_message: string) => {
    return AxiosClient.patch(`${PATH}/${error_code}`, { english_message, korean_message });
  },
  updateMultiple: (data: any[]) => {
    return AxiosClient.patch(`${PATH}/multiple`, data );
  },
  del: (error_code: string) => {
    return AxiosClient.delete(`${PATH}/${error_code}`);
  },
  importXlsx: (data: any[]) => {
    return AxiosClient.post(`${PATH}/import`, data);
  }
}
