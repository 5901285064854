import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

interface IDialogProps{
    open: boolean;
    title: string;
    content: string;
    agreeBtnText: string;
    disagreeBtnText: string;
    handleAgree: () => void;
    handleDisagree: () => void;
}

export default function ResponsiveDialog(props: IDialogProps): JSX.Element{
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { open, title, content, agreeBtnText, disagreeBtnText, handleAgree, handleDisagree } = props;
  return(
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleDisagree}
      aria-labelledby="responsive-dialog-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleDisagree}>
          {disagreeBtnText}
        </Button>
        <Button onClick={handleAgree} autoFocus>
          {agreeBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}