import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, signin, signout } from '../../state';
import { REFRESH_TOKEN_NAME, TOKEN_NAME } from '../../constants';
import Cookies from 'universal-cookie';
import { BackdropLoading } from '../BackdropLoading';
import { AuthApi } from '../../api';
import { UserContext } from '../../context/user.context';

const cookies = new Cookies();

export const PrivateRoute = (): JSX.Element => {
  const { dispatch: { setCurrentUser } } = useContext(UserContext);
  const isSignedIn = useSelector((state: RootState) => state.signedin);
  const [isLoaded, setIsLoaded] = useState(false);

  const disptach = useDispatch();

  // check if access token hasn't expired, if it has expired, then try to refresh tokens
  const checkAccessToken = async (accessToken: string, refreshToken: string) => {
    const user = await AuthApi.getMe();
    if (user) {
      disptach(signin(accessToken, refreshToken));
      setCurrentUser(user);
    } else {
      const refreshedTokens: any = await AuthApi.refreshTokens(refreshToken);
      if (refreshedTokens) {
        const { access_token, refresh_token, user } = refreshedTokens;
        disptach(signin(access_token, refresh_token));
        setCurrentUser(user);
      } else {
        disptach(signout());
        setCurrentUser(null);
      }
    }
    setIsLoaded(true);
  }


  useEffect(() => {
    const token = cookies.get(TOKEN_NAME);
    const refresh_token = cookies.get(REFRESH_TOKEN_NAME);
    if (token && refresh_token) {
      checkAccessToken(token, refresh_token);
    } else {
      setIsLoaded(true);
    }
  }, []);


  return (
    <>
      {
        !isLoaded ? <BackdropLoading/> :
          (isSignedIn ? <Outlet/> : <Navigate to='/sign-in'/>)
      }
    </>
  );
}
