import { KeyValue } from '../../../constants';

export const OptionalServices: KeyValue = {
  7: 'Infant seat',
  8: 'Child seat',
  9: 'Booter seat',
  13: 'Navigator',
};

export const SupplierOptions: KeyValue = {
  ZE: '허츠',
  ZR: '달러',
  OT: '온라인트래블',
  RC: '리치',
  TM: '투몬'
};
