import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/material';
import { useContext } from 'react';
import { LangContext } from '../../context/language.context';

export const BackdropLoading = ():JSX.Element=>{
  const { dispatch: { translate } } = useContext(LangContext);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      onClick={()=>{}}
    >
      <Stack sx={{ alignItems: 'center' }}>
        <CircularProgress color="inherit" />
        {translate('loading')}...
      </Stack>
    </Backdrop>
  );
}
