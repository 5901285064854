import { AxiosClient } from './axios-client';
import { ICity } from '../constants';

const path = 'cities';
export const CitiesApi = {
  getCities: async (offset?: number, limit?: number, order?: 'asc' | 'desc') => {
    const response: any[] = await AxiosClient.get(`${path}/all`);
    return response;
  },
  addCity: (data: ICity)=>{
    return AxiosClient.post(`${path}`, data);
  },
  removeCity: (code: string) => {
    return AxiosClient.delete(`${path}?code=${code}`);
  },
  getCity: async (code: string)=>{
    const response: any = await AxiosClient.get(`${path}?code=${code}`);
    return response;
  },
  updateCity: (city: ICity) => {
    return AxiosClient.patch(`${path}`, city);
  },
  getTranslation: async (skip: number, take: number) => {
    const res : {count: number, tags: any[]} = await AxiosClient.get(`${path}/translation?skip=${skip}&take=${take}`);
    return res;
  },
  filterTranslation: async (skip: number, take: number, condition: {keyword?: string, country_code?: string}) => {
    const queryString = {};
    if (condition?.keyword){
      Object.assign(queryString, { keyword: condition.keyword });
    }
    if(condition?.country_code){
      Object.assign(queryString, { country_code: condition.country_code });
    }
    Object.assign(queryString, { skip, take });
    const res : {count: number, tags: any[]} = await AxiosClient.get(`${path}/translation`, { params: queryString });
    return res;
  },
  updateTranslation: (data: {locale: string, city_name: string, city_code: string}[])=> {
    return AxiosClient.patch(`${path}/translation/multiple`, data);
  },
}
