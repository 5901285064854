import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Toast } from '../../../../components/Toast';
import { LangContext } from '../../../../context/language.context';

interface Props {
    open: boolean;
    onClose: () => void;
    onSubmit: (tag: string, message: string, locale: string) => void;
    locale: string;
}

export const AddTagDialog = (props: Props): JSX.Element => {
  const { open, onClose, onSubmit, locale } = props;
  const { dispatch: { translate } } = useContext(LangContext);
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async () => {
    if (!name || !message){
      await Toast.fire({
        title: 'Required fields must be filled out.',
        icon: 'warning'
      });
      return;
    }
    onSubmit(name, message, locale);
    setName('');
    setMessage('');
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{translate('add')+' ' + translate('tag')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={($event) => {
            setName($event.target.value)
          }}
        />
        <TextField
          margin="dense"
          label="Default locale"
          type="text"
          fullWidth
          value={locale}
          disabled
        />
        <TextField
          margin="dense"
          label="Translation"
          type="text"
          fullWidth
          value={message}
          onChange={($event) => {
            setMessage($event.target.value)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button  onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}
