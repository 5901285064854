import React from 'react';
import {  Menu, MenuItem, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

interface Props {
    anchorEl: any;
    languages: any[];
    onChange: (lang: string) => void;
    onClose: () => void;
}

export const LanguageModal = (props: Props): JSX.Element => {
  const { anchorEl, languages, onChange, onClose } = props;
  const open = Boolean(anchorEl);

  return(
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={onClose}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {
        languages.map((value, index) => (
          <MenuItem key={index} sx={{ paddingY: '0rem' }}>
            <Stack direction='row' onClick={()=> onChange(value.language) }>
              <Stack justifyContent="center"  mr={1}>
                <img
                  src={value.icon}
                  alt="flag"
                  style={{ maxHeight: '1.5rem', width: 'auto' }}
                />
              </Stack>
              <Stack justifyContent="center">
                <Typography variant="overline" display="block" gutterBottom>
                  {value.language}
                </Typography>
              </Stack>
            </Stack>
          </MenuItem>
        ))
      }
    </Menu>
  );
}
