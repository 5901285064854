import { AxiosClient } from './axios-client';
const PATH = 'agency';
export const ClientApi = {
  list: async () => {
    const result: any[] = await AxiosClient.get(PATH);
    return result;
  },
  getOne: async (id: any) => {
    const result: any = await AxiosClient.get(`${PATH}/${id}`);
    return result;
  },
  create: (name: string)=>{
    return AxiosClient.post(PATH, { name });
  },
  update: (id: any, name: string)=>{
    return AxiosClient.patch(`${PATH}/${id}`, { name });
  },
  destroy: (id: any) => {
    return AxiosClient.delete(`${PATH}/${id}`);
  }
}
