import React, { useState } from 'react';
import { IColumn } from '../../../../constants';
import {
  Chip,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow, Tooltip,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import './couponTable.css';

const uuid = require('uuid');

const ClickableChip = (props: {
  value: string;
  onClick: () => void;
}) => {
  const { value, onClick } = props;
  return (
    <Tooltip title={value.toLowerCase() === 'active' ? 'Click to deactivate' : 'Click to active'} placement="left-start">
      <Chip color={value.toLowerCase() === 'active' ? 'success' : 'default'} label={value} onClick={onClick} />
    </Tooltip>
  )
}

interface Props {
  loading: boolean;
  columns: IColumn[];
  data: any[];
  disableActions?: boolean;
  onView?: (data: any) => void;
  onDelete?: (data: any) => void;
  onChangePage?: (page: number, rowsPerPage: number) => void;
  onClickRow?: (item: any) => void;
  setStatus: (data: any) => void;
}

export const CouponTable = (props: Props): JSX.Element => {
  const {
    loading,
    columns,
    data,
    onView,
    onDelete,
    onChangePage,
    disableActions,
    onClickRow,
    setStatus,
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowSelected, setRowSelected] = useState<number | null>(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (onChangePage) {
      onChangePage(page, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (onChangePage) {
      onChangePage(page, rowsPerPage);
    }
  };

  const handleClick = (index: number) => {
    if (onClickRow) {
      if (index != rowSelected) {
        onClickRow(index);
        setRowSelected(index);
      } else {
        onClickRow(null);
        setRowSelected(null);
      }
    }
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: '100vh' }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          aria-labelledby="tableTitle"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.db_field}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <CircularProgress color="inherit" />
            ) : (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      // hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() => handleClick(index)}
                      className={`${
                        rowSelected == index ? 'row-selected' : ''
                      } table-row`}
                    >
                      {columns.map((column) => {
                        const field = column.db_field;
                        // @ts-ignore
                        const value = row[field];
                        if (column.db_field === 'no') {
                          return (
                            <TableCell key={index} align={column.align}>
                              <b>{index + 1}</b>
                            </TableCell>
                          );
                        }
                        return column.db_field === 'actions' ? (
                          <TableCell key={uuid.v4()}>
                            <Stack spacing={0.5} direction="row">
                              <IconButton
                                disabled={disableActions}
                                onClick={() => (onView ? onView(row) : {})}
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                disabled={disableActions}
                                onClick={() => (onDelete ? onDelete(row) : {})}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        ) : (
                          column.db_field === 'status' ? (
                            <TableCell key={uuid.v4()} align={column.align}>
                              <ClickableChip value={value} onClick={() => setStatus(row)} />
                            </TableCell>
                          ) :
                            (
                              <TableCell key={uuid.v4()} align={column.align}>
                                {column.format ? column.format(value) : value}
                              </TableCell>)
                        );
                      })}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
