import { IColumn } from '../../../constants';
import { format } from 'date-fns';
import { brandsList, countriesList } from '../pages/AddUpdateCoupon';

export interface IHeaderCell {
  // db_field: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: any) => any;
  // This part is add by Ha
  colspan?: number;
  rowspan?: number;
  // horizontalAlign?: boolean;
}

export interface IHeaderRow {
  cells: IHeaderCell[];
}

export interface IHeaderRows {
  rows: IHeaderRow[];
}

export const FakeDataPayment: IHeaderRows = {
  rows: [
    {
      cells: [],
    },
  ],
};

export const headerColumnRows: IHeaderCell[][] = [
  [
    { label: 'No', rowspan: 4 },
    { label: 'Brand', rowspan: 4, minWidth: 100 },
    { label: 'Type', rowspan: 4, minWidth: 70 },
    { label: 'Destination', rowspan: 4, minWidth: 70 },
    { label: 'Offer description', rowspan: 4, minWidth: 70 },
    { label: 'Min days', rowspan: 4, minWidth: 70 },
    { label: 'Car group', rowspan: 4, minWidth: 70 },
    { label: 'Pickup start Date & Time', rowspan: 4, minWidth: 200 },
    { label: 'Return end Date & Time', rowspan: 4, minWidth: 200 },
    { label: 'Order start Date & Time', rowspan: 4, minWidth: 200 },
    { label: 'Order end Date & Time', rowspan: 4, minWidth: 200 },
    { label: 'Issued count', rowspan: 4, minWidth: 70 },
    { label: 'Used count', rowspan: 4, minWidth: 70 },
    { label: 'Status', rowspan: 4, minWidth: 70 },
  ],
];

const renderBrandCell = (value: string) => {
  let arr = value.split(',');
  arr = arr.map((item)=> (brandsList[item]));
  return <span>{arr.join(',')}</span>;
}

const renderDestinationCell = (value: string) => {
  let arr = value.split(',');
  arr = arr.map((item)=> (countriesList[item]));
  return <span>{arr.join(',')}</span>;
}

export const paymentColumns: IColumn[] = [
  { db_field: 'no', label: 'No.', minWidth: 70 },
  {
    db_field: 'type',
    label: 'Type',
  },
  { db_field: 'coupon_name', label: 'Coupon Name' },
  {
    db_field: 'brand',
    label: 'Supplier',
    format: (value) => renderBrandCell(value),
  },
  {
    db_field: 'destination',
    label: 'Destination',
    format: (value) => renderDestinationCell(value),
  },
  {
    db_field: 'offer_description',
    label: 'Offer description',
  },
  { db_field: 'corporate_discount_number', label: 'CDP Code' },
  { db_field: 'promotion_code', label: 'Promotion Code' },
  {
    db_field: 'min_days',
    label: 'Min days',
  },
  {
    db_field: 'car_group',
    label: 'Car group',
  },
  {
    db_field: 'pickup_start_time',
    label: 'Pickup Start Time',
    format: (value) => <span>{format(new Date(value), 'PPpp')}</span>,
    minWidth: 150,
  },
  {
    db_field: 'pickup_end_time',
    label: 'Pickup End Time',
    format: (value) => <span>{format(new Date(value), 'PPpp')}</span>,
    minWidth: 150,
  },
  {
    db_field: 'order_start_time',
    label: 'Order Start Time',
    format: (value) => <span>{format(new Date(value), 'PPpp')}</span>,
    minWidth: 150,
  },
  {
    db_field: 'order_end_time',
    label: 'Order End Time',
    format: (value) => <span>{format(new Date(value), 'PPpp')}</span>,
    minWidth: 150,
  },
  {
    db_field: 'issued_count',
    label: 'Issued count',
  },
  {
    db_field: 'used_count',
    label: 'Used count',
  },
  {
    db_field: 'status',
    label: 'Status',
  },
  {
    db_field: 'remark',
    label: 'Remark',
  },
];

export interface ICoupon {
  id: string;
  no: string;
  type: string;
  coupon_name: string;
  coupon_code: string;
  brand: string;
  destination: string;
  offer_description: string;
  corporate_discount_number: string;
  promotion_code: string;
  min_days: number;
  car_group: string;
  pickup_start_time: Date;
  pickup_end_time: Date;
  order_start_time: Date;
  order_end_time: Date;
  issued_count: number;
  used_count: number;
  status: string;
  remark: string;
}
