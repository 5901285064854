import { KeyValue } from '../../../constants';

export const AdminPermissionLabel: KeyValue = {
  1: 'Reservation Management',
  2: 'Client Management',
  3: 'Payment Management',
  4: 'System Management',
  5: 'User Management',
}

export const AdminPermissionsGroup = [
  [1],
  [2],
  [3],
  [4],
  [5],
]
