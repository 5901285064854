import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { grey } from '@mui/material/colors';

interface Props {
  listItems: string[];
  onChange: (checkedList: any) => void;
  initialChecked?: number[];
}

export default function CheckboxList(props: Props) {
  const { listItems, onChange, initialChecked } = props;
  const [checked, setChecked] = React.useState<number[]>(initialChecked ?? []);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    onChange(newChecked);
  };

  React.useEffect(() => {
    console.log('aaaa', initialChecked);
    setChecked(initialChecked ?? []);
  }, [initialChecked]);

  return (
    // <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        border: 1,
        borderColor: grey[400],
        borderRadius: 2,
      }}
      dense
    >
      {/* {listItems.map((value) => {
        const labelId = `checkbox-list-label-${value}`; */}
      {listItems.map((value, index) => {
        const labelId = `checkbox-list-label-${index}`;

        return (
          <ListItem
            key={index}
            // secondaryAction={
            //   <IconButton edge="end" aria-label="comments">
            //     <CommentIcon />
            //   </IconButton>
            // }
            disablePadding
          >
            <ListItemButton
              role={undefined}
              onClick={handleToggle(index)}
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(index) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              {/* <ListItemText id={labelId} primary={`Line item ${value}`} /> */}
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
