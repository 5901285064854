import { AxiosClient } from './axios-client';
import axios from 'axios';

const path = 'upload';

export const UploadApi = {
  uploadLogo: async (fileData: any, fileInfo: any) => {
    // get presigned url from S3
    const signedUrlRes: any = await AxiosClient.post(`${path}/signed-put-url`, {
      file_name: fileInfo.name,
      file_type: fileInfo.type
    });
    const { signedUrl, fileUrl } = signedUrlRes;

    // put file to this signed url
    const binary = atob(fileData.split(',')[1])
    const array = []
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i))
    }
    const blobData = new Blob([new Uint8Array(array)], { type: fileInfo.type })
    try{
      await axios.put(`${signedUrl}`, blobData, { headers: { 'Content-Type': fileInfo.type } });
    }
    catch (e){
      console.log(e);
      return null;
    }

    return fileUrl;
  },
  // uploadLogoV2: async (file: any)=> {
  //     // get presigned post url from s3
  //     const signedUrl: {url: string, fields: any} = await AxiosClient.post(`${path}/signed-post-url`, {
  //         file_name: file.name,
  //         file_type: file.type
  //     });
  //
  //     const formData = new FormData();
  //     formData.append('Content-Type', file.type);
  //     Object.entries(signedUrl.fields).forEach(([k, v]) => {
  //         // @ts-ignore
  //         formData.append(k, v);
  //     });
  //     formData.append('file', file); // The file has be the last element
  //
  //     return axios.post(signedUrl.url, formData, {
  //         headers: {'Content-Type': 'multipart/form-data'},
  //     });
  // }
}
