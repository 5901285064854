import * as React from 'react';
// import SwipeableViews from 'react-swipeable-views';
import { SubmitHandler, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
// import { ReactComponent as USIcon } from '../public/us_flag.svg';
// import { ReactComponent as KRIcon } from '../public/kr_flag.svg';
import {
  Button,
  InputLabel,
  Stack,
} from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { KRIcon, USIcon } from './Flags';
import SaveIcon from '@mui/icons-material/Save';
import { Toast } from '../../../components/Toast';
import { FAQApi } from '../../../api/faq.api';
import { useNavigate } from 'react-router-dom';

interface Props {
  tabList?: any[];
  data?: Inputs;
}

// class FAQClass {
//   id: number;
//   question_kr: string;
//   answer_kr: string;
//   question_us: string;
//   answer_us: string;
//   sort_order: number;
//   status: string;

//   constructor(
//     id: number,
//     question_kr: string,
//     answer_kr: string,
//     question_us: string,
//     answer_us: string,
//     sort_order: number,
//     status: string,
//   ) {
//     this.id = id;
//     this.question_kr = question_kr;
//     this.answer_kr = answer_kr;
//     this.question_us = question_us;
//     this.answer_us = answer_us;
//     this.sort_order = sort_order;
//     this.status = status;
//   }
// }

type Inputs = {
  id: number;
  question_kr: string;
  answer_kr: string;
  question_us: string;
  answer_us: string;
  sort_order: number;
  status: string;
};

const styles = {
  hidden: {
    display: 'none',
  },
  box_show: {
    padding: 15,
  },
  tab_active: {
    padding: '10px 20px',
    borderStyle: 'solid',
    borderColor: grey[300],
    borderWidth: 1,
    borderBottomColor: '#fff',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  tab_inactive: {
    padding: '10px 20px',
    borderStyle: 'solid',
    borderBottomColor: grey[300],
    borderBottomWidth: 1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
};

export default function FAQTabs(props: Props) {
  const { data } = props;
  const [showError, setShowError] = React.useState(false);
  const [showKR, setShowKR] = React.useState(true);
  const [showUS, setShowUS] = React.useState(false);

  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const checkShowError = async (dat: Inputs) => {
    const tkr = dat.question_kr.trim();
    const dkr = dat.answer_kr.trim();
    const tus = dat.question_us.trim();
    const dus = dat.answer_us.trim();
    const order = dat.sort_order;
    console.log('order', order);
    const status = dat.status;
    console.log(tkr, dkr, tus, dus);
    if (tkr == '' || dkr == '' || tus == '' || dus == '') {
      // alert('Fields should not be empty or only contains space');
      await Toast.fire({
        title: 'Fields should not be empty or only contains space.',
        icon: 'warning',
      });
      setShowError(true);
      return;
    } else {
      // handle Save button
      if (data) {
        try {
          const newData = {
            id: data?.id as number,
            question_kr: tkr,
            answer_kr: dkr,
            question_us: tus,
            answer_us: dus,
            sort_order: order,
            status: status,
          };
          const response = await FAQApi.updateFAQ(newData);
          console.log('response', response);
          await Toast.fire({
            title: 'Successfully Update FAQ',
            icon: 'success',
          });
          navigate(-1);
          return;
        } catch (error) {
          await Toast.fire({
            title: 'Something wrong, can not update or create FAQ',
            icon: 'warning',
          });
          console.error(error);
          // setShowError(false);
          return;
        }
      } else {
        // if data dont have data, meaning create new faq
        try {
          const newFAQData = {
            question_kr: tkr,
            answer_kr: dkr,
            question_us: tus,
            answer_us: dus,
            sort_order: order,
            status: status,
          };
          const response = await FAQApi.addFAQ(newFAQData);
          console.log('response', response);
          await Toast.fire({
            title: 'Successfully Create FAQ',
            icon: 'success',
          });
          navigate(-1);
          return;
        } catch (error) {
          await Toast.fire({
            title: 'Something wrong, can not create FAQ',
            icon: 'warning',
          });
          console.error(error);
          return;
        }
      }
    }
  };

  const onSubmit: SubmitHandler<Inputs> = (dat: Inputs) => {
    // if (!checkShowError()) {
    console.log('nhan duoc khi submit', dat);
    setShowError(false);
    // console.log('nhan duoc khi submit', dat);
    checkShowError(dat);
    // }
  };

  const handleOnClickKR = () => {
    setShowKR(true);
    setShowUS(false);
  };

  const handleOnClickUS = () => {
    setShowUS(true);
    setShowKR(false);
  };

  React.useEffect(() => {
    console.log('data trong useEffect: ', data);
    if (data) {
      setValue('question_kr', data.question_kr);
      setValue('answer_kr', data.answer_kr);
      setValue('question_us', data.question_us);
      setValue('answer_us', data.answer_us);
      setValue('sort_order', data.sort_order);
      setValue('status', data.status);
    } else {
      setValue('question_kr', '');
      setValue('answer_kr', '');
      setValue('question_us', '');
      setValue('answer_us', '');
      setValue('sort_order', 0);
      setValue('status', 'Active');
    }
  }, [data]);

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
      <form
        onSubmit={handleSubmit(onSubmit, () => {
          console.log('oninvalid');
          setShowError(true);
        })}
      >
        <Stack direction="row">
          <Button
            onClick={handleOnClickKR}
            style={showKR ? styles.tab_active : styles.tab_inactive}
          >
            <KRIcon /> <span style={{ marginLeft: 10 }}>KR</span>
          </Button>
          <Button
            onClick={handleOnClickUS}
            style={showUS ? styles.tab_active : styles.tab_inactive}
          >
            <USIcon /> <span style={{ marginLeft: 10 }}>US</span>
          </Button>
          <Button fullWidth disabled style={styles.tab_inactive}></Button>
        </Stack>
        <Box style={showKR ? {} : styles.hidden}>
          <Stack spacing={2}>
            <Box
              p={1}
              style={{
                borderColor: grey[300],
                borderWidth: 1,
                borderStyle: 'solid',
                borderTopColor: '#fff',
                borderBottomColor: grey[300],
                padding: 20,
              }}
            >
              <Stack spacing={2}>
                <Stack>
                  <InputLabel>
                    Title in Korean{' '}
                    <span
                      style={{
                        color: red[500],
                        fontSize: 12,
                        fontStyle: 'italic',
                      }}
                    >
                      <b>*</b>
                    </span>
                  </InputLabel>
                  <input
                    defaultValue={data?.question_kr}
                    {...register('question_kr', {
                      required: 'Could not be empty',
                    })}
                  />
                  {errors.question_kr && (
                    <span
                      style={{
                        color: red[500],
                        fontSize: 12,
                        fontStyle: 'italic',
                      }}
                    >
                      {errors.question_kr.message}
                    </span>
                  )}
                </Stack>
                <Stack>
                  <InputLabel>
                    Description{' '}
                    <span
                      style={{
                        color: red[500],
                        fontSize: 12,
                        fontStyle: 'italic',
                      }}
                    >
                      <b>*</b>
                    </span>
                  </InputLabel>
                  <textarea
                    // defaultValue={data?.answer_kr}
                    {...register('answer_kr', {
                      required: 'Could not be empty',
                    })}
                  />
                  {errors.answer_kr && (
                    <span
                      style={{
                        color: red[500],
                        fontSize: 12,
                        fontStyle: 'italic',
                      }}
                    >
                      {errors.answer_kr.message}
                    </span>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Box style={showUS ? {} : styles.hidden}>
          <Stack spacing={2}>
            <Box
              p={1}
              style={{
                borderColor: grey[300],
                borderWidth: 1,
                borderStyle: 'solid',
                borderTopColor: '#fff',
                padding: 20,
              }}
            >
              <Stack spacing={2}>
                <Stack>
                  <InputLabel>
                    Title in English{' '}
                    <span
                      style={{
                        color: red[500],
                        fontSize: 12,
                        fontStyle: 'italic',
                      }}
                    >
                      <b>*</b>
                    </span>
                  </InputLabel>
                  <input
                    // defaultValue={data?.question_us}
                    {...register('question_us', {
                      required: 'Could not be empty',
                    })}
                  />
                  {errors.question_us && (
                    <span
                      style={{
                        color: red[500],
                        fontSize: 12,
                        fontStyle: 'italic',
                      }}
                    >
                      {errors.question_us.message}
                    </span>
                  )}
                </Stack>
                <Stack>
                  <InputLabel>
                    Description{' '}
                    <span
                      style={{
                        color: red[500],
                        fontSize: 12,
                        fontStyle: 'italic',
                      }}
                    >
                      <b>*</b>
                    </span>
                  </InputLabel>
                  <textarea
                    // defaultValue={data?.answer_us}
                    {...register('answer_us', {
                      required: 'Could not be empty',
                    })}
                  />
                  {errors.answer_us && (
                    <span
                      style={{
                        color: red[500],
                        fontSize: 12,
                        fontStyle: 'italic',
                      }}
                    >
                      {errors.answer_us.message}
                    </span>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Stack spacing={2} marginTop={2}>
          <Stack direction="row" spacing={2}>
            <Stack>
              <InputLabel>
                Set Order{' '}
                <span
                  style={{
                    color: red[500],
                    fontSize: 12,
                    fontStyle: 'italic',
                  }}
                >
                  <b>*</b>
                </span>
              </InputLabel>
              <input
                type="number"
                // defaultValue={data?.sort_order}
                {...register('sort_order', {
                  min: {
                    value: 0,
                    message: 'Could not less than 0',
                  },
                  max: {
                    value: 10,
                    message: 'Could not greater than 10',
                  },
                })}
              />
              {errors.sort_order && (
                <span
                  style={{
                    color: red[500],
                    fontSize: 12,
                    fontStyle: 'italic',
                  }}
                >
                  {errors.sort_order.message}
                </span>
              )}
            </Stack>
            <Stack>
              <InputLabel>
                Status{' '}
                <span
                  style={{
                    color: red[500],
                    fontSize: 12,
                    fontStyle: 'italic',
                  }}
                >
                  <b>*</b>
                </span>
              </InputLabel>
              <select
                style={{ height: 22, width: 170 }}
                defaultValue={data?.status}
                {...register('status')}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </Stack>
          </Stack>
          {showError && (
            <span
              style={{ color: red[500], fontSize: 12, fontStyle: 'italic' }}
            >
              Please check all inputs for Korean and English tabs
            </span>
          )}

          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              style={{
                borderColor: grey[500],
                color: 'black',
                borderWidth: 2,
              }}
              startIcon={<SaveIcon />}
              type="submit"
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
}
