import * as React from 'react';
import {
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  Stack,
} from '@mui/material';
import Button from '@mui/material/Button';
import UpdateIcon from '@mui/icons-material/Update';
import { DropdownSearch } from '../../../../components/DropdownSearch';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { DateRangePicker } from '../../../../components/DateRangePicker';
import { format } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import { useContext } from 'react';
import { LangContext } from '../../../../context/language.context';
const companies = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'ZE',
    label: '허츠',
  },
  {
    value: 'ZR',
    label: '달러',
  },
  {
    value: 'ZT',
    label: '쓰리프티',
  },
  {
    value: 'OT',
    label: '온라인트래블',
  },
  {
    value: 'RC',
    label: '리치',
  },
  {
    value: 'TM',
    label: '투몬',
  },
];
interface Props {
  condition: any;
  onChange: (value: any) => void;
  onFilter: () => void;
  onRefresh: (start_date: string, end_date: string) => void;
}
export const FilterBox = (props: Props) => {
  const { condition, onChange, onFilter, onRefresh } = props;
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  const formatDisplayDate = (dates: any) => {
    return `${format(dates.startDate, 'PP')} - ${format(dates.endDate, 'PP')}`;
  };
  return (
    <React.Fragment>
      <Card sx={{ marginBottom: '2rem' }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" spacing={3}>
            <FormControl fullWidth>
              <Typography variant="body1">{translate('company')}</Typography>
              <DropdownSearch
                value={condition.company}
                placeholder={translate('select')}
                options={companies}
                onSelect={(option) => {
                  onChange({ ...condition, company: option.value });
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography variant="body1">{translate('date')}</Typography>
              <DateRangePicker
                text={condition.displayDate}
                state={condition.date}
                onClean={() => {
                  onChange({
                    ...condition,
                    displayDate: '',
                    ISOStartDate: '',
                    ISOEndDate: '',
                  });
                }}
                onChange={(item) => {
                  onChange({
                    ...condition,
                    date: [item['selection']],
                    displayDate: formatDisplayDate(item['selection']),
                    ISOStartDate: format(
                      item['selection'].startDate,
                      'yyyy-MM-dd',
                    ),
                    ISOEndDate: format(item['selection'].endDate, 'yyyy-MM-dd'),
                  });
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography variant="body1">{translate('keyword')}</Typography>
              <TextField
                value={condition.keyword}
                size="small"
                id="keyword"
                onChange={(e) => {
                  onChange({ ...condition, keyword: e.target.value });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle clean datetime"
                        onClick={() => {
                          onChange({ ...condition, keyword: '' });
                        }}
                        edge="end"
                      >
                        <BackspaceOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Stack
              direction="column"
              justifyContent="end"
              sx={{ minWidth: '100px' }}
            >
              <Button variant="outlined" size="large" onClick={onFilter}>
                {translate('search')}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
