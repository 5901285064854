import React, { useContext, useEffect, useState } from 'react';
import { LangContext } from '../../../../context/language.context';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ClientApi, UserApi } from '../../../../api';
import Swal from 'sweetalert2';
import { Toast } from '../../../../components/Toast';
import { IColumn, RoutePath } from '../../../../constants';
import { Box } from '@mui/material';
import { PageHeader } from '../../../../components/PageHeader';
import { FilterBox } from '../../../../components/FilterBox';
import { DataTable } from '../../../../components/DataTable';
import { format } from 'date-fns';
import { CreateClientDialog } from '../../components/CreateClientDialog';
const renderDatetime = (value: any)=> {
  return <span>{format(new Date(value), 'yy-MM-dd')} {format(new Date(value), 'p')}</span>
}
const columns : IColumn[] = [
  {
    db_field: 'no',
    label: 'No.',
    minWidth: 70
  },
  {
    db_field: 'id',
    label: "Client's Number",
    align: 'left'
  },
  {
    db_field: 'name',
    label: "Client's Name",
    align: 'left',
  },
  {
    db_field: 'created_date',
    label: 'Created Date',
    align: 'left',
    format: (value) => renderDatetime(value),
  },
  {
    db_field: 'actions',
    label: '',
    minWidth: 50,
    align: 'right'
  }
];
export const Clients = (): JSX.Element => {
  const { dispatch: { translate } } = useContext(LangContext);
  const navigate = useNavigate();
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [users, setUsers] = useState<any[]>([]);
  const { status, error, data } = useQuery<any[], Error>(
    ['getAllClients', updated],
    ClientApi.list,
  );
  useEffect(()=> {
    if (status === 'success'){
      setUsers(data);
      setLoading(false);
    }

    if (status === 'loading' || status === 'idle'){
      setLoading(true);
    }
  },[data, status]);

  if (status === 'error' && !data) {
    return <div>{error?.message}</div>;
  }

  const handleDelete = async (data: any) => {
    Swal.fire({
      title: translate('delete') + '?',
      text: translate('confirmDelete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1976d2',
      cancelButtonColor: '#d33',
      confirmButtonText: translate('yes'),
      cancelButtonText: translate('cancel')
    }).then(async (result) => {
      if (result.isConfirmed) {
        try{
          await ClientApi.destroy(data.id);
          setUpdated(!updated);
          await Toast.fire({
            icon: 'success',
            title: 'Delete successful'
          })
        }catch (e:any) {
          await Toast.fire({
            icon: 'error',
            title: e.message
          })
        }
      }
    })
  }

  const handleView = (data:any) => {
    navigate(`/${RoutePath.CLIENTS}/${data.id}`);
  }
  const handleFilter = () => {
    if (keyword === ''){
      // @ts-ignore
      setUsers(data);
      return;
    }
    // @ts-ignore
    const filterData = data.filter((client) =>
      client.name.toLowerCase().startsWith(keyword.toLowerCase()));
    setUsers(filterData);
  }
  return (
    <>
      <Box sx={{ flex: 1 }}>
        <PageHeader pageTitle='Clients' actionTitle={'New'} onAction={() => setIsOpenDialog(true)}/>
        <CreateClientDialog open={isOpenDialog} onClose={() => setIsOpenDialog(false)} onSuccess={() => setUpdated(!updated) } />
        <FilterBox keyword={keyword} placeholer={'Search Client Name'} onChange={setKeyword} onFilter={handleFilter} />
        <DataTable
          loading={loading}
          columns={columns}
          data={users}
          onView={handleView}
          onDelete={handleDelete}/>
      </Box>
    </>
  );
}
