import { Box, Stack,Typography } from '@mui/material'
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { ExchangeRateHistoryAPI } from '../../../api/exchange-rate-history.api';
import { DataTable } from '../../../components/DataTable'
import { IColumn } from '../../../constants'
import { IExchangeRateHistory } from '../constants/columns';
import { format } from 'date-fns';

const exchangeColumns: IColumn[] = [
  { db_field: 'date', label: 'Date', format: (value) => (<span>{format(new Date(value), 'PPpp')}</span>) },
  { db_field: 'fromCode', label: 'From' },
  { db_field: 'rate', label: 'Rate' },
]

export const ExchangeRateHistory = () => {
  const [data,setData] = useState<IExchangeRateHistory[]>([]);

  const fetchData = async () => {
    const dat: IExchangeRateHistory[] = await ExchangeRateHistoryAPI.getAll();
    const sortedData = dat.sort((a,b)=>new Date(b.date).getTime() - new Date(a.date).getTime());
    setData(sortedData);
  }

  useEffect (()=>{
    fetchData();
  },[]);

  return (
    <Box sx={{ flex: 1 }}>
      <Stack direction="column" >
        <Typography
          variant="h3"
          bgcolor={'#fff'}
          sx={{
            borderWidth: '0.1px',
            borderStyle: 'solid',
            borderColor: grey[200],
            borderRadius: 1,
            borderTopColor: grey[100],
            p: 2,
          }}
        >
            Exchange Rate History
        </Typography>
      </Stack>
      <DataTable loading={false} columns={exchangeColumns} data={data} />
    </Box>
  )
}
