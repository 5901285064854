import { Box } from '@mui/material';
import { PageHeader } from '../../../../components/PageHeader';
import React  from 'react';
import { TranslationTabTable } from '../../components/TranslationTabTable';

export const Translation = () => {
  return(
    <Box sx={{ flex: 1, maxWidth: '100vw' }}>
      <PageHeader pageTitle='Translation'/>
      <TranslationTabTable/>
    </Box>
  )
}
