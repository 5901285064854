import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';
import { SIDEBAR_WIDTH } from '../../constants';
import { useState } from 'react';
import { styled } from '@mui/material/styles';

const drawerWidth = SIDEBAR_WIDTH;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  minHeight: '100vh',
  maxWidth: '100vw',
  backgroundColor: 'var(--background-color)'
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const Layout = (): JSX.Element => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleOpenSidebar = () => {
    setMobileOpen(true);
  }
  const handleCloseSidebar = () => {
    setMobileOpen(false);
  }
  const handleHome = () => {
    // don't open sidebar
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline/>
      <Header onOpenSidebar={handleHome} open={mobileOpen}/>
      <Sidebar mobileOpen={mobileOpen} onClose={handleCloseSidebar}/>
      <Main open={mobileOpen}>
        <DrawerHeader />
        <Outlet/>
      </Main>
    </Box>
  )
}
