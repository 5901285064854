import { Grid, Paper, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import ActiveLastBreadcrumbs from '../../../../components/Breadcrumbs';
import { CONTINENTS, IAirport, IBreadcrumb, ICity, ICountry, ICountryTranslation, RoutePath } from '../../../../constants';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataTable } from '../../../../components/DataTable';
import { CountriesApi } from '../../../../api';
import { useParams } from 'react-router-dom';
import { BackdropLoading } from '../../../../components/BackdropLoading';
import { citiesColumns, countryTransColumns, airportColumns } from '../../constants';
import { Toast } from '../../../../components/Toast';
import { DropdownSearch } from '../../../../components/DropdownSearch';


const breadcrumbs: IBreadcrumb[] = [
  { name: 'Countries', href: RoutePath.COUNTRIES },
  { name: 'Country', href: '#' }
]

export const CountryDetail = (): JSX.Element => {
  const { code } = useParams();
  const [updateCounter, setUpdateCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [generalData, setGeneralData] = useState<ICountry>({
    code: '', code_3: '', currency: '', name: '', continent: ''
  });
  const [drafData, setDrafData] = useState<ICountry>({
    code: '', code_3: '', currency: '', name: '', continent: ''
  });
  const [translation, setTranslation] = useState<ICountryTranslation[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [airports, setAirports] = useState<IAirport[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState('Loading...');

  useEffect(() => {
    fetchData();
  }, [updateCounter]);

  const fetchData = async () => {
    try{
      const data = await CountriesApi.getCountry(code as string);

      // get general data
      setGeneralData({
        code: data.code,
        code_3: data.code_3,
        name: data.name,
        continent: data.continent,
        currency: data.currency
      });
      // set draf data
      setDrafData({
        code: data.code,
        code_3: data.code_3,
        name: data.name,
        continent: data.continent,
        currency: data.currency
      });

      // get translation data
      const transTemp: ICountryTranslation[] = [];
      data.countries_translation.forEach((element: any) => {
        transTemp.push({
          country_code: element.country_code,
          locale: element.locale,
          country_name: element.country_name,
        });
      });
      setTranslation(transTemp);

      // get cities data
      const citiesTemp: ICity[] = [];
      data.cities.forEach((element: any) => {
        citiesTemp.push({
          alt: element.alt,
          code: element.code,
          country_code: element.country_code,
          latitude: element.latitude,
          longitude: element.longitude,
          name: element.name,
          timezone: element.timezone,
          un_locode: element.un_locode
        })
      });
      setCities(citiesTemp);

      // get airports data
      const airportsTemp: IAirport[] = [];
      data.airports.forEach((element: any) => {
        airportsTemp.push({
          country_code: element.country_code,
          iata_code: element.iata_code,
          icao_code: element.icao_code,
          id: element.id,
          latitude: element.latitude,
          longitude: element.longitude,
          name: element.name
        })
      });
      setAirports(airportsTemp);

      // set loading equal false
      setLoading(false);
    }catch (e:any) {
      setMessage(e.message);
    }

  }

  const subPanel = () => {
    return (
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">Country translation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable loading={loading} columns={countryTransColumns} data={translation} onView={() => {
            }} onDelete={() => {
            }} onChangePage={() => {
            }}
            disableActions={true}/>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant='h6'>Cities</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable loading={loading} columns={citiesColumns} data={cities} onView={() => {
            }} onDelete={() => {
            }} onChangePage={() => {
            }}
            disableActions={true}/>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant='h6'>Airports</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable
              loading={loading}
              columns={airportColumns} data={airports} onView={() => {
              }} onDelete={() => {
              }} onChangePage={() => {
              }}
              disableActions={true}/>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }

  const cancelEditMode = () => {
    setEditMode(false);
    // reset draf data
    setDrafData(generalData);
  }

  const editModeButtons = () => {
    return (
      <Stack direction='row' spacing={1}>
        <Button variant='outlined' onClick={cancelEditMode}>Cancel</Button>
        <Button variant='contained' onClick={handleSubmitSave}>Save</Button>
      </Stack>
    )
  }

  const readModeButton = () => {
    return (
      <Button variant='contained' onClick={() => setEditMode(true)}>Edit</Button>
    )
  }

  const handleChangeData = (value: string, field: string) => {
    switch (field) {
    case 'name':
      setDrafData((prevState) => {
        return {
          ...prevState, name: value
        }
      })
      break;
    case 'code':
      setDrafData((prevState) => {
        return {
          ...prevState, code: value
        }
      })
      break;
    case 'code_3':
      setDrafData((prevState) => {
        return {
          ...prevState, code_3: value
        }
      })
      break;
    case 'continent':
      setDrafData((prevState) => {
        return {
          ...prevState, continent: value
        }
      })
      break;
    case 'currency':
      setDrafData((prevState) => {
        return {
          ...prevState, currency: value
        }
      })
      break;
    default:
    }
  }

  const handleSubmitSave = async () => {
    // check required fields
    if (!drafData.code || !drafData.code_3 || !drafData.name) {
      await Toast.fire({
        title: 'Required fields must be filled out.',
        icon: 'warning'
      });
      return;
    }

    setLoading(true);

    CountriesApi.updateCountry(drafData)
      .then(async (res) => {
        setEditMode(false);
        setUpdateCounter(old=> old + 1);
        await Toast.fire({
          title: 'Saved',
          icon: 'success'
        });
      })
      .catch( async (err) => {
        setLoading(false);
        await Toast.fire({
          title: err.message,
          icon: 'error'
        });
      })
  }

  return (
    <>
      {
        loading ? <span>{message}</span>
          :
          <Box sx={{ flex: 1 }}>
            <Stack direction='row' justifyContent='space-between'>
              <Stack justifyContent='center'>
                <ActiveLastBreadcrumbs items={breadcrumbs}/>
              </Stack>

              <Stack justifyContent='center'>
                {
                  editMode ? editModeButtons() : readModeButton()
                }
              </Stack>
            </Stack>

            <Paper sx={{ width: '100%', p: 2, mt: 3 }}>
              <Typography variant="h4" gutterBottom component="div">
                                General
              </Typography>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <b>Name: </b> {editMode ?
                    <div><TextField
                      sx={{ width: '100%' }}
                      value={drafData.name}
                      size="small"
                      variant="outlined"
                      placeholder='required'
                      onChange={(e) => handleChangeData(e.target.value, 'name')}
                    /></div> :
                    generalData.name}
                </Grid>
                <Grid item xs={6}>
                  <b>Code: </b> {editMode ?
                    <div><TextField
                      sx={{ width: '100%' }}
                      value={drafData.code}
                      size="small"
                      variant="outlined"
                      disabled={true}
                      placeholder='required'
                      onChange={(e) => handleChangeData(e.target.value, 'code')}
                    /> </div>:
                    generalData.code}
                </Grid>
                <Grid item xs={6}>
                  <b>Continent: </b> {editMode ?
                    <DropdownSearch
                      options={CONTINENTS}
                      placeholder={'Optional'}
                      value={drafData.continent}
                      onSelect={(option) => handleChangeData(option.value, 'continent') }  />
                    : generalData.continent}
                </Grid>
                <Grid item xs={6}>
                  <b>Code 3: </b> {editMode ?
                    <div><TextField
                      sx={{ width: '100%' }}
                      value={drafData.code_3}
                      size="small"
                      variant="outlined"
                      placeholder='required'
                      onChange={(e) => handleChangeData(e.target.value, 'code_3')}
                    /></div> : generalData.code_3}
                </Grid>
                <Grid item xs={6}>
                  <b>Currency: </b> {editMode ?
                    <div><TextField
                      sx={{ width: '100%' }}
                      value={drafData.currency}
                      size="small"
                      variant="outlined"
                      placeholder='optional'
                      onChange={(e) => handleChangeData(e.target.value, 'currency')}
                    /></div> : generalData.currency}
                </Grid>
              </Grid>
            </Paper>

            <Box sx={{ mt: 2 }}>
              {subPanel()}
            </Box>
          </Box>
      }
    </>
  );
}
