import React, { useContext, useEffect, useState } from 'react';
import { IColumn } from '../../constants';
import {
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { LangContext } from '../../context/language.context';
import { DynamicIcon } from '../DynamicIcon';
import { grey } from '@mui/material/colors';
import { UserContext } from '^/context/user.context';
import { ReservationApi } from '^/api/reservation.api';
import Swal from 'sweetalert2';
import { Toast } from '../Toast';
const uuid = require('uuid');

interface Props {
  loading: boolean;
  columns: IColumn[];
  data: any[];
  total?: number;
  disableActions?: boolean;
  onView?: (data: any) => void;
  onViewIcon?: string;
  onDelete?: (data: any) => void;
  onDeleteIcon?: string;
  onChangePage?: (page: number, rowsPerPage: number) => void;
  onResetPage?: number;
  //update new field
  isRole?: boolean;
}

export const DataTable = (props: Props): JSX.Element => {
  const {
    loading,
    columns,
    data,
    total,
    onView,
    onDelete,
    onChangePage,
    disableActions,
    onViewIcon,
    onDeleteIcon,
    onResetPage,
    isRole,
  } = props;
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { state: currentUser } = useContext(UserContext);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
  }, [onResetPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (onChangePage) {
      onChangePage(newPage, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (onChangePage) {
      onChangePage(page, rowsPerPage);
    }
  };

  const handleReservationCancel = async (
    event: React.MouseEvent<HTMLElement>,
    data: any,
  ) => {
    if (data?.res_status === 'booked') {
      if (data?.supplier == 'OT') {
        Swal.fire({
          title: '예약 취소 중입니다...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        try {
          const response = await ReservationApi.cancelOT(
            data?.res_id,
            currentUser?.username,
          );
          if (response.data?.res_id) {
            Swal.close();
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: '예약이 취소되었습니다.',
              showConfirmButton: false,
              timer: 1500,
            });
            window.location.reload();
          } else {
            Swal.close();
            await Swal.fire({
              position: 'center',
              icon: 'error',
              title: '예약 취소 중 오류가 발생했습니다.',
              text: response.data?.message,
            });
          }
        } catch (e) {
          Swal.close();
          await Swal.fire({
            position: 'center',
            icon: 'error',
            title: '예약 취소 중 오류가 발생했습니다.',
            //@ts-ignore
            text: e.message,
          });
        }
      }

      if (data?.last_name && data?.res_id && data?.supplier) {
        Swal.fire({
          title: '예약 취소 중입니다...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const response = await ReservationApi.cancel(
          data?.last_name,
          data?.res_id,
          data?.supplier,
          currentUser?.username,
        );
        console.log('Hertz cancel response::', response);
        if (response?.data['result'] == true) {
          // let guideMessage = `카톡 발송 여부: ${response?.data['kakaotalk_send_reseult']}`;

          // if (response?.data['payment_cancel_result']) {
          //   guideMessage += `결제 취소 여부: ${response.data['payment_cancel_result']}`;
          // }

          Swal.close();
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: '예약이 취소되었습니다.',
            // text: guideMessage,
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        } else {
          await Swal.fire({
            position: 'center',
            icon: 'error',
            title: '예약 취소 중 오류가 발생했습니다.',
            text: '',
          });
        }
      } else {
        alert('로우 데이터에 문제가 있습니다.');
      }
    } else {
      alert('이미 취소된 예약입니다.');
    }
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: '100vh' }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          // sx={{ maxWidth: 500 }}
          aria-labelledby="tableTitle"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                // check role and user to show/hide data
                if (
                  isRole &&
                  column.only_super_admin &&
                  currentUser.agency_id
                ) {
                  return;
                }
                return (
                  <TableCell
                    key={column.db_field}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      verticalAlign: 'bottom',
                      border: 1,
                      borderStyle: 'solid',
                      borderColor: grey[200],
                    }}
                  >
                    <b>{translate(column.label)}</b>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <CircularProgress color="inherit" />
            ) : (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        // check role and user to show/hide data
                        if (
                          isRole &&
                          column.only_super_admin &&
                          currentUser.agency_id
                        ) {
                          return;
                        }
                        const field = column.db_field;
                        // @ts-ignore
                        const value = row[field];
                        // console.log(field, value, column.format);

                        if (column.db_field === 'no') {
                          return (
                            <TableCell key={index} align={column.align}>
                              <b>{index + 1}</b>
                            </TableCell>
                          );
                        } else if (column.db_field === 'btn') {
                          return (
                            <TableCell key={uuid.v4()} align={column.align}>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={(e) => {
                                  if (
                                    !window.confirm(
                                      '정말 예약을 취소하시겠습니까?',
                                    )
                                  )
                                    return;
                                  return handleReservationCancel(e, row);
                                }}
                              >
                                예약취소
                              </Button>
                            </TableCell>
                          );
                        }
                        return column.db_field === 'actions' ? (
                          <TableCell key={uuid.v4()}>
                            <Stack spacing={0.5} direction="row">
                              <IconButton
                                disabled={disableActions}
                                onClick={() => (onView ? onView(row) : {})}
                              >
                                {onViewIcon ? (
                                  <DynamicIcon icon={onViewIcon} />
                                ) : (
                                  <DynamicIcon icon={'Visibility'} />
                                )}
                              </IconButton>
                              <IconButton
                                disabled={disableActions}
                                onClick={() => (onDelete ? onDelete(row) : {})}
                              >
                                {onDeleteIcon ? (
                                  <DynamicIcon icon={onDeleteIcon} />
                                ) : (
                                  <DynamicIcon icon={'DeleteOutline'} />
                                )}
                              </IconButton>
                            </Stack>
                          </TableCell>
                        ) : (
                          <TableCell key={uuid.v4()} align={column.align}>
                            {column.format
                              ? column.format(value)
                              : value
                                ? value
                                : '-'}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={total ? total : data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
