import { Box, Card, CardContent, FormControl, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useContext, useState } from 'react';
import { LangContext } from '../../../../context/language.context';
import { LoadingButton } from '@mui/lab';
import { AdminUserApi } from '../../../../api';
import { Toast } from '../../../../components/Toast';
const errors = [
  'confirmPWDoesNotMatch',
  'newPWIsTheSame',
  'invalidPWLength'
]
const MIN_LENGTH = 8;
export const ChangePassword = () => {
  const { dispatch: { translate } } = useContext(LangContext);
  const [oldPw, setOldPw] = useState('');
  const [newPw, setNewPw] = useState('');
  const [confirm, setConfirm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const handleChange = () => {
    setError('');
    if (newPw !== confirm){
      setError(errors[0]);
      return;
    }
    if (newPw.length < MIN_LENGTH){
      setError(errors[2]);
      return;
    }
    setLoading(true);
    AdminUserApi.changePassword(oldPw, newPw)
      .then( async (res)=> {
        setLoading(false);
        await Toast.fire({
          icon: 'success',
          title: translate('success')
        })
        console.log(res);
      })
      .catch(async (err)=> {
        setLoading(false);
        await Toast.fire({
          icon: 'error',
          title: err.message
        })
      })
  }
  return(
    <Box sx={{ flex: 1 }}>
      <Card sx={{ padding: '1rem' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom component="div">
            {translate('changePassword')}
          </Typography>
          <Stack direction='row' justifyContent='space-between' spacing={3}>
            <FormControl fullWidth>
              <Typography variant='body1'>{translate('oldPassword')}</Typography>
              <TextField
                margin="none"
                size="small"
                fullWidth
                name="password"
                type="password"
                value={oldPw}
                onChange={(e) => {
                  setOldPw(e.target.value)
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography variant='body1'>{translate('newPassword')}</Typography>
              <TextField
                margin="none"
                size="small"
                fullWidth
                name="password"
                type="password"
                value={newPw}
                onChange={(e) => {
                  setNewPw(e.target.value)
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography variant='body1'>{translate('confirmPassword')}</Typography>
              <TextField
                margin="none"
                size="small"
                fullWidth
                name="password"
                type="password"
                value={confirm}
                onChange={(e) => {
                  setConfirm(e.target.value)
                }}
              />
            </FormControl>
            <Stack direction='column' justifyContent='end'>
              <LoadingButton  onClick={handleChange} variant='outlined' size='large' loading={loading} disabled={!oldPw || !newPw || !confirm} style={{ minWidth: '100px' }}>
                {translate('change')}
              </LoadingButton>
            </Stack>
          </Stack>
          {
            error ?
              <Typography marginTop={2} variant="body1" color='red' component="div">
                {translate(error)}
              </Typography> :
              <></>
          }
        </CardContent>
      </Card>
    </Box>
  )
}
