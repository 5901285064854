import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LangContext } from '../../../../context/language.context';
import { LoadingButton } from '@mui/lab';
import { IErrorMessage } from '../../../../constants';

interface Props {
    open: boolean;
    editMode: boolean;
    data: IErrorMessage;
    loading?: boolean;
    onClose: () => void;
    onChange: (value: IErrorMessage) => void;
    onSubmit: () => void;
}

export const EditErrorDialog = (props: Props): JSX.Element => {
  const { open, data, editMode, loading, onClose, onChange, onSubmit } = props;
  const { dispatch: { translate } } = useContext(LangContext);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`${translate('add')} ${translate('errMessage')}`}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="normal"
          required={true}
          label={translate('errorCode')}
          type="text"
          fullWidth
          size='small'
          value={data.error_code}
          onChange={($event) => {
            onChange({ ...data, error_code: $event.target.value })
          }}
        />
        <TextField
          margin="normal"
          required={true}
          label={translate('en')}
          type="text"
          fullWidth
          size='small'
          value={data.english_message}
          onChange={($event) => {
            onChange({ ...data, english_message: $event.target.value })
          }}
        />
        <TextField
          margin="normal"
          required={true}
          label={translate('ko')}
          type="text"
          fullWidth
          size='small'
          value={data.korean_message}
          onChange={($event) => {
            onChange({ ...data, korean_message: $event.target.value })
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={onClose}
        >
          {translate('cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          disabled={!data.error_code || !data.english_message || !data.korean_message}
          onClick={onSubmit}>
          {translate('submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
