import { AxiosClient } from './axios-client';

const path = 'payments';
export const PaymentAPI = {
  list: async (offset: number, limit: number): Promise<{payment_num: number; res: any[]}> => {
    return AxiosClient.get(`${path}?offset=${offset}&limit=${limit}`);
  },
  update: (orderId: string, data: any) => {
    return AxiosClient.patch(`${path}/${orderId}`,data);
  },
  filter: async (
    supplier: string,
    dateRange: { startDate: string; endDate: string },
    keyword: string,
    offset: number,
    limit: number,
  ) => {
    const queryString = {};
    if (supplier) {
      Object.assign(queryString, { supplier: supplier });
    }
    if (dateRange.startDate && dateRange.endDate) {
      Object.assign(queryString, {
        begin_date: dateRange.startDate,
        end_date: dateRange.endDate,
      });
    }
    if (keyword) {
      Object.assign(queryString, { keyword: keyword });
    }
    Object.assign(queryString, { offset, limit });

    const response: {payment_num: number; res: any[]} = await AxiosClient.get(`${path}/filter`, {
      params: queryString,
    });
    return response;
  },
  download: async (
    supplier: string,
    dateRange: { startDate: string; endDate: string },
    keyword: string,
    agencyId: string,
  ) => {
    const queryString: Record<string, string> = {};
    if (supplier) queryString.supplier = supplier;
    if (dateRange.startDate && dateRange.endDate) {
      queryString.begin_date = dateRange.startDate;
      queryString.end_date = dateRange.endDate;
    }
    if (keyword) queryString.keyword = keyword;
    if (agencyId) queryString.agency_id = agencyId;

    const response: {payment_num: number; res: any[]} = await AxiosClient.get(`${path}/download`, {
      params: queryString, responseType: 'arraybuffer'
    });
    return response;
  },
  downloadPPDAccountingExcel: async (
    month: string
  ) => {
    const queryString: Record<string, string> = {};
    queryString.month = month;
    const response = await AxiosClient.get(`${path}/prepaid`, {
      params: queryString, responseType: 'arraybuffer'
    });
    return response;
  },
};
