export enum ActionType{
    SIGNED_IN,
    SIGNED_OUT,
    SET_LANGUAGE,
    SET_RELOAD,
  SET_USER,
}

export interface IAction{
    type: ActionType;
    payload?: any;
}
