import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ICity, IOptionItem, TIMEZONE } from '../../../../constants';
import { CitiesApi, CountriesApi } from '../../../../api';
import { FormControl } from '@mui/material';
import { Toast } from '../../../../components/Toast';
import { DropdownSearch } from '../../../../components/DropdownSearch';
import Typography from '@mui/material/Typography';
import { LangContext } from '../../../../context/language.context';

interface Props {
    open: boolean;
    onClose: () => void;
}

export const AddCityDialog = (props: Props): JSX.Element => {
  const { open, onClose } = props;
  const { dispatch: { translate } } = useContext(LangContext);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [unLocode, setUnLocode] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [alt, setAlt] = useState('');
  const [timezone, setTimezone] = useState('');
  const [country, setCountry] = useState('');
  const [countriesOptions, setCountriesOptions] = useState<IOptionItem[]>([]);
  const [loading, setLoading] = useState(false);
  // const [showError, setShowError] = useState(false);
  const timezoneOptions = TIMEZONE;

  useEffect(() => {
    fetchCountriesOptions();
  }, [])

  const fetchCountriesOptions = async () => {
    const result = await CountriesApi.getCountries();
    const options: IOptionItem[] = [];
    result.forEach((value) => {
      options.push({
        value: value.code,
        label: value.name
      })
    });
    setCountriesOptions(options);
  }

  const isNumber = (str: string) => {
    if (str.trim() === '') {
      return false;
    }
    return !Number.isNaN(Number(str));
  }

  const resetData = () => {
    //reset data
    setName('');
    setCode('');
    setUnLocode('');
    setCountry('');
    setLatitude('');
    setLongitude('');
    setAlt('');
    setTimezone('');
  }

  const handleCancel = () => {
    resetData();
    onClose();
  }

  const handleSubmit = async () => {
    if (!code || !name || !country || !longitude || !latitude || !alt) {
      // setShowError(true);
      await Toast.fire({
        title: 'Required fields must be filled out.',
        icon: 'warning'
      });
      return;
    }
    if ((latitude && !isNumber(latitude)) || (longitude && !isNumber(longitude)) || (alt && !isNumber(alt))) {
      await Toast.fire({
        title: 'Please make sure that input is valid format',
        icon: 'warning'
      });
      return;
    }

    setLoading(true);
    const data: ICity = {
      alt: parseInt(alt),
      code: code,
      country_code: country,
      latitude: parseInt(latitude),
      longitude: parseInt(longitude),
      name: name,
      timezone: timezone,
      un_locode: unLocode
    }
    CitiesApi.addCity(data)
      .then(async (res) => {
        await Toast.fire({
          title: 'Added',
          icon: 'success'
        });

        resetData();

        setLoading(false);

        onClose();
      })
      .catch(async (err) => {
        await Toast.fire({
          title: err.message,
          icon: 'error'
        });
        setLoading(false);
      })
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`${translate('add')} ${translate('city')}`}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          size="small"
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          // error={showError}
          value={name}
          onChange={($event) => {
            setName($event.target.value)
          }}
        />
        <TextField
          size="small"
          margin="dense"
          label="City code"
          type="text"
          fullWidth
          // error={showError}
          value={code}
          onChange={($event) => {
            setCode($event.target.value)
          }}
        />
        <FormControl fullWidth sx={{ mt: 1 }}>
          <DropdownSearch value={country} options={countriesOptions} placeholder={'Country'} onSelect={(option) => {
            console.log(option);
            setCountry(option.value)}
          }/>
        </FormControl>
        <TextField
          size="small"
          margin="dense"
          label="Latitude"
          type="number"
          fullWidth
          // error={showError}
          value={latitude}
          onChange={($event) => {
            setLatitude($event.target.value)
          }}
        />
        <TextField
          size="small"
          margin="dense"
          label="Longitude"
          type="number"
          fullWidth
          // error={showError}
          value={longitude}
          onChange={($event) => {
            setLongitude($event.target.value)
          }}
        />
        <TextField
          size="small"
          margin="dense"
          label="Alt"
          type="number"
          fullWidth
          // error={showError}
          value={alt}
          onChange={($event) => {
            setAlt($event.target.value)
          }}
        />
        <Typography variant='subtitle2'>Optional fields: </Typography>
        <TextField
          size="small"
          margin="dense"
          label="Un Locode"
          type="text"
          fullWidth
          value={unLocode}
          onChange={($event) => {
            setUnLocode($event.target.value)
          }}
        />
        <FormControl fullWidth sx={{ mt: 1 }}>
          <DropdownSearch placeholder={'Timezone'} options={timezoneOptions} onSelect={(option)=> {setTimezone(option.value)} } />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleCancel}>Cancel</Button>
        <Button disabled={loading} onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}
