import React, { useEffect, useState } from 'react';
import { Box, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IColumn, ILocale, LocaleDBFields } from '../../../../constants';
import Checkbox from '@mui/material/Checkbox';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import { EditLocaleDialog } from '../../components/EditLocaleDialog';
import { TranslationApi } from '../../../../api';
import { BackdropLoading } from '../../../../components/BackdropLoading';
import ResponsiveDialog from '../../../../components/ResponsiveDialog';
import { Toast } from '../../../../components/Toast';
import { PageHeader } from '../../../../components/PageHeader';

const uuid = require('uuid');

const renderCheckBox = (value: boolean) => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (
    <>
      {value ? <Checkbox {...label} disabled checked/> : <Checkbox {...label} disabled/>}
    </>
  )
}

const columns: readonly IColumn[] = [
  { db_field: 'no', label: 'No.', minWidth: 70 },
  { db_field: LocaleDBFields.name, label: 'Name', minWidth: 170 },
  {
    db_field: LocaleDBFields.code,
    label: 'Locale code',
    minWidth: 170,
    align: 'left',
  },
  {
    db_field: LocaleDBFields.default,
    label: 'Default',
    minWidth: 170,
    align: 'left',
    format: (value) => renderCheckBox(value)
  },
  {
    db_field: 'actions',
    label: '',
    minWidth: 50,
    align: 'right'
  }
];

export const LocalesList = (): JSX.Element => {
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<ILocale>({ id: 0, name: '', code: '', default: false });
  const [isNew, setIsNew] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);

  useEffect(() => {
    fetchLocales();
  }, [])

  /**
     * FETCH DATA
     */
  const fetchLocales = async () => {
    const result = await TranslationApi.getLocales();

    const locales: { id: number; name: string; code: string; default: boolean }[] = [];

    result.forEach((value) => {
      locales.push({ id: value.id, name: value.name, code: value.code, default: value.default });
    });

    setRows(locales);

    setIsLoaded(true);
  }

  /**
     * TABLE PAGINATION
     */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /**
     * NEW & UPDATE FEATURE
     */

  const handleOpenNewLocale = () => {
    setSelectedRow({ code: '', name: '', id: 0, default: false });
    setIsNew(true);
    setOpenDialog(true);
  }

  const handleOpenEditLocale = (row: any) => {
    setIsNew(false);
    setSelectedRow({ id: row.id, code: row.code, name: row.name, default: row.default });
    setOpenDialog(true);
  }

  // change from new/edit dialog
  const handleChangeSelectedRow = (value: ILocale) => {
    setSelectedRow(value);
  }

  const handleSubmitLocale = () => {
    if (isNew) {
      // Add locale
      setIsLoaded(false);
      setOpenDialog(false);
      TranslationApi.addLocale(selectedRow.code, selectedRow.name)
        .then( async (res) => {
          // add new locale to rows
          const newRows = rows;
          newRows.push(res);
          setRows(newRows);
          // alert
          await Toast.fire({
            title: 'Added.',
            icon: 'success'
          });

          //reset
          setIsNew(false);
          setSelectedRow({ id: -1, name: '', code: '', default: false });
          setIsLoaded(true);
        })
        .catch(async (err) => {
          setIsLoaded(true);
          await Toast.fire({
            title: err.message,
            icon: 'error'
          });
        })
    } else {
      // Update locale
      setIsLoaded(false);
      setOpenDialog(false);
      TranslationApi.updateLocale(selectedRow.code, selectedRow.name, selectedRow.default)
        .then((res) => {
          // update UI locale
          const updatedIndex = rows.findIndex((value) => value.id === selectedRow.id);
          const newRows = rows;
          newRows[updatedIndex] = res;
          setRows(newRows);

          //reset
          setIsNew(false);
          setSelectedRow({ id: -1, name: '', code: '', default: false });
          setIsLoaded(true);
        })
        .catch(async (err) => {
          setIsLoaded(true);
          await Toast.fire({
            title: err.message,
            icon: 'error'
          });
        })
    }
  }

  /**
     * REMOVE FEATURE
     */

  const handleOpenRemove = (row: any)=>{
    setSelectedRow({ id: row.id, code: row.code, name: row.name, default: row.default });
    setOpenRemove(true);
  }

  const handleRemoveLocale = () => {
    setIsLoaded(false);
    setOpenRemove(false);
    TranslationApi.removeLocale(selectedRow.code)
      .then((res) => {
        // update UI locale
        const removedIndex = rows.findIndex((value) => value.id === selectedRow.id);
        const newRows = rows
        newRows.splice(removedIndex,1);
        setRows(newRows);

        //reset
        setSelectedRow({ id: -1, name: '', code: '', default: false });
        setIsLoaded(true);
      })
      .catch(async (err) => {
        await Toast.fire({
          title: err.message,
          icon: 'error'
        });
        setIsLoaded(true);
      })
  }


  /**
     * SET DEFAULT LOCALE
     */
  const handleDefaultLocale= (row: any) =>{
    setIsLoaded(false);
    TranslationApi.updateLocale(row.code, row.name, true)
      .then((res)=> {
        // update UI locale
        const oldDefaultIndex = rows.findIndex((value) => value.default === true);
        const newDefaultIndex = rows.findIndex((value) => value.id === row.id);
        const newRows = rows;
        newRows[oldDefaultIndex].default = false;
        newRows[newDefaultIndex].default = true;
        setRows(newRows);

        //reset
        setSelectedRow({ id: -1, name: '', code: '', default: false });
        setIsLoaded(true);
      })
      .catch(async (err)=>{
        await Toast.fire({
          title: err.message,
          icon: 'error'
        });
        setIsLoaded(true);
      })
  }

  return (
    <>
      {
        !isLoaded ? <BackdropLoading/> :
          <Box sx={{ flex: 1 }}>

            <PageHeader pageTitle='Locales' actionTitle='Add' onAction={handleOpenNewLocale}/>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.db_field}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <b>{column.label}</b>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            {columns.map((column) => {
                              const field = column.db_field;
                              // @ts-ignore
                              const value = row[field];
                              if (column.db_field === 'no') {
                                return (
                                  <TableCell key={index} align={column.align}>
                                    <b>{index + 1}</b>
                                  </TableCell>
                                );
                              }
                              return (
                                column.db_field === 'actions' ?
                                  <TableCell key={uuid.v4()}>
                                    <Stack spacing={0.5} direction="row">
                                      <IconButton onClick={() => handleOpenEditLocale(row)}><EditIcon/></IconButton>
                                      <IconButton onClick={() => handleOpenRemove(row)}><DeleteIcon/></IconButton>
                                      {row['default'] ? <IconButton disabled><StarOutlineRoundedIcon/></IconButton> : <IconButton onClick={()=> handleDefaultLocale(row)}><StarRoundedIcon/></IconButton>}
                                    </Stack>
                                  </TableCell>
                                  :
                                  <TableCell key={uuid.v4()} align={column.align}>
                                    {column.format
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>

            <EditLocaleDialog
              open={openDialog}
              onClose={() => {
                setOpenDialog(false)
              }}
              isNew={isNew}
              data={selectedRow}
              onSubmit={handleSubmitLocale}
              onChange={handleChangeSelectedRow}/>

            <ResponsiveDialog open={openRemove}
              title={'Remove?'}
              content={'Are you sure to remove this locale?'}
              agreeBtnText={'Remove'}
              disagreeBtnText={'Back'}
              handleAgree={handleRemoveLocale}
              handleDisagree={() => {
                setOpenRemove(false)
              }}/>
          </Box>
      }
    </>
  );
}
