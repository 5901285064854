import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import React, { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import { AdminRoleApi } from '../../../../api';
import { Toast } from '../../../../components/Toast';
import { PermissionGroup } from '../PermissionGroup';
import { LangContext } from '../../../../context/language.context';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const CreateRoleDialog = (props: Props) => {
  const { open, onClose, onSuccess } = props;
  const { dispatch: { translate } } = useContext(LangContext);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [nameMsg, setNameMsg] = useState('');
  const [permissions, setPermissions] = useState<number[]>([]);

  const handleClose = () => {
    onClose();
    setName('');
    setDescription('');
    setPermissions([]);
  }
  const onCheck = (event: any) => {
    let updatedList = [...permissions];
    if (event.target.checked) {
      updatedList = [...permissions, parseInt(event.target.value)];
    } else {
      updatedList.splice(permissions.indexOf(parseInt(event.target.value)), 1);
    }
    setPermissions(updatedList);
  }
  const handleSubmit = () => {
    if (!name) {
      setNameMsg('Name is required');
      return;
    }
    setLoading(true);
    AdminRoleApi.create(name, description, permissions)
      .then(async (res)=> {
        setLoading(false);
        handleClose();
        onSuccess();
        await Toast.fire({
          icon: 'success',
          title: 'Created',
        });
      })
      .catch( async (err)=> {
        setLoading(false);
        await Toast.fire({
          icon: 'error',
          title: err.message,
        })
      })
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{translate('newRole')}</DialogTitle>
      <DialogContent>
        <Stack direction="row" justifyContent="space-between" >
          <TextField
            margin="dense"
            label="Name"
            required
            type="text"
            fullWidth
            size="small"
            sx={ { marginRight: '0.5rem' }}
            error={nameMsg !== ''}
            helperText={nameMsg}
            value={name}
            onChange={($event) => {
              setName($event.target.value);
              setNameMsg('');
            }}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            sx={ { marginLeft: '0.5rem' }}
            size="small"
            value={description}
            onChange={($event) => {
              setDescription($event.target.value);
            }}
          />
        </Stack>
        <Typography marginY={2} variant='body1'>Select Role Permission</Typography>
        <PermissionGroup checked={permissions} onChange={onCheck} disabled={false} />
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={handleClose}>Cancel</LoadingButton>
        <LoadingButton loading={loading} variant="outlined" onClick={handleSubmit}>Submit</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
