import { BackdropLoading } from '../../../../components/BackdropLoading';
import Box from '@mui/material/Box';
import { Grid, Paper, Stack, TextField } from '@mui/material';
import ActiveLastBreadcrumbs from '../../../../components/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { IBreadcrumb, RoutePath } from '../../../../constants';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Toast } from '../../../../components/Toast';
import { AdminRoleApi } from '../../../../api';
import { LoadingButton } from '@mui/lab';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { PermissionGroup } from '../../components/PermissionGroup';

const breadcrumbs: IBreadcrumb[] = [
  { name: 'Roles', href: RoutePath.USER_ROLES },
  { name: 'Role', href: '#' }
]
export const AdminRoleDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<any>();
  const [draftData, setDraftData] = useState<any>();
  const [checked, setChecked] = useState<any[]>([]);
  const [errMsg, setErrMsg] = useState('');
  if(!id || !parseInt(id)){
    return (<>No data</>)
  }
  useEffect(()=> {
    fetchData();
  },[])
  const fetchData = async () => {
    const res = await AdminRoleApi.getOne(parseInt(id));
    setData(res);
    setDraftData(res);
    // permision
    setChecked(res.permissions);
    setLoading(false);
  }
  const cancelEditMode = () => {
    setEditMode(false);
    setDraftData(data);
  }

  const readModeButton = () => {
    return (
      <Button variant='contained' onClick={() => setEditMode(true)}>Edit</Button>
    )
  }
  const editModeButtons = () => {
    return (
      <Stack direction='row' spacing={1}>
        <LoadingButton loading={saving} variant='outlined' onClick={cancelEditMode}>Cancel</LoadingButton>
        <LoadingButton loading={saving} variant='contained' onClick={handleSave}>Save</LoadingButton>
      </Stack>
    )
  }

  const handleSave = () => {
    if (!draftData.name) {
      setErrMsg('Name is required');
      return;
    }
    setSaving(true);
    AdminRoleApi.update(parseInt(id), draftData.name, draftData.description, checked)
      .then(async (res: any)=> {
        setSaving(false);
        setEditMode(false);
        const permissions = res[0].admin_roles_permissions.map((per: any) => per.permission_id);
        delete res[0].admin_roles_permissions;
        setData({ ...res[0], permissions })
        await Toast.fire({
          icon: 'success',
          title: 'Updated',
        });
      })
      .catch(async (err) => {
        setSaving(false);
        await Toast.fire({
          icon: 'error',
          title: err.message,
        })
      })
  }
  const onCheck = (event: any) => {
    let updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, parseInt(event.target.value)];
    } else {
      updatedList.splice(checked.indexOf(parseInt(event.target.value)), 1);
    }
    setChecked(updatedList);
  }

  return (
    <>
      {
        loading ? <BackdropLoading/>
          :
          <Box sx={{ flex: 1 }}>
            <Stack direction='row' justifyContent='space-between'>
              <Stack justifyContent='center'>
                <ActiveLastBreadcrumbs items={breadcrumbs}/>
              </Stack>

              <Stack justifyContent='center'>
                {
                  editMode ? editModeButtons() : readModeButton()
                }
              </Stack>
            </Stack>

            <Paper sx={{ width: '100%', p: 2, mt: 3 }}>
              <Typography variant="h4" gutterBottom component="div">
                General
              </Typography>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <b>Name: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={draftData.name}
                        required
                        size="small"
                        type='text'
                        variant="outlined"
                        placeholder='Required'
                        error={errMsg !== ''}
                        helperText={errMsg}
                        onChange={(e) => {
                          setDraftData((prev: any) => {
                            return {
                              ...prev, name: e.target.value
                            }
                          });
                          setErrMsg('')
                        }}
                      />
                    </div> : data.name}
                </Grid>
                <Grid item xs={6}>
                  <b>Description: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={draftData.description}
                        size="small"
                        variant="outlined"
                        placeholder='Optional'
                        onChange={(e) => {
                          setDraftData((prev: any) => {
                            return {
                              ...prev, description: e.target.value
                            }
                          })
                        }}
                      />
                    </div> : data.description}
                </Grid>
                <Grid item xs={6}>
                  <b>Created at: </b>  {format(new Date(data.created_date), 'PPpp')}
                </Grid>
                <Grid item xs={6}>
                  <b>Modified at: </b> {format(new Date(data.modified_date), 'PPpp')}
                </Grid>
              </Grid>
            </Paper>

            <Paper sx={{ width: '100%', p: 2, mt: 3 }}>
              <Typography variant="h4" gutterBottom component="div">
                Role Permission
              </Typography>
              <PermissionGroup checked={checked} onChange={onCheck} disabled={!editMode} />
            </Paper>
          </Box>
      }
    </>
  )
}

