import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ILocale } from '../../../../constants';

interface Props {
    isNew: boolean;
    open: boolean;
    data: ILocale;
    onChange: (value: ILocale) => void;
    onClose: () => void;
    onSubmit: () => void;
}

export const EditLocaleDialog = (props: Props): JSX.Element => {
  const { isNew, open, data, onChange, onSubmit, onClose } = props;

  const handleChange = (value: string, key: string) => {
    switch (key) {
    case 'name':
      onChange({
        ...data, ...{ name: value }
      });
      break;
    case 'code':
      onChange({
        ...data, ...{ code: value }
      });
      break;
    default:
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{isNew ? 'Add New Locale' : 'Edit'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Locale"
          type="text"
          fullWidth
          value={data.name}
          onChange={($event) => {
            handleChange($event.target.value, 'name')
          }}
        />
        <TextField
          margin="dense"
          id="code"
          label="Locale code"
          type="text"
          fullWidth
          value={data.code}
          onChange={($event) => {
            handleChange($event.target.value, 'code')
          }}
          disabled={!isNew}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}
