import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CategoriesTreeView } from '../../components/CategorisTreeView';
import { ICategory } from '../../../../constants';
import { NewCategoryDialog } from '../../components/NewCategoryDialog';
import { CategoriesApi } from '../../../../api/categories.api';
import { BackdropLoading } from '../../../../components/BackdropLoading';
import { DataTable } from '../../../../components/DataTable';
import { locationCategoryColumns } from '../../constants';
import { LocationsApi } from '../../../../api';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ResponsiveDialog from '../../../../components/ResponsiveDialog';
import { Toast } from '../../../../components/Toast';
import { PageHeader } from '../../../../components/PageHeader';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SelectLocationDialog } from '../../components/SelectLocationDialog';

export const Categories = () => {
  const [openNew, setOpenNew] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [itemAdded, setItemAdded] = useState(0);
  const [locationAdded, setLocationAdded] = useState(0);
  const [loading, setLoading] = useState(true);
  const [clickedItem, setClickedItem] = useState<ICategory>({ name: '', id: '' });
  const [locations, setLocations] = useState<any[]>([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);

  useEffect(() => {
    fetchData();
  }, [itemAdded])

  useEffect(()=> {
    fetchLocations(clickedItem.id);
  },[locationAdded])

  const fetchData = async () => {
    const result = await CategoriesApi.getCategories();
    const categoriesTemp: ICategory[] = [];
    result.forEach((item: any) => {
      categoriesTemp.push({
        children: item.other_categories,
        id: item.id.toString(),
        location: item.location,
        name: item.name,
        parent_id: item.parent_id
      })
    })
    setCategories(categoriesTemp);
    setLoading(false);
  }

  const handleClickItem = (item: ICategory) => {
    setClickedItem(item);
    fetchLocations(item.id);
  }

  const fetchLocations = async (category: any) => {
    const loc = await LocationsApi.getLocationsByCategory(parseInt(category));
    const temp: any[] = [];
    loc.forEach(item => {
      temp.push({
        id: item.id,
        description: item.location_description[0].description,
        city_name: item.cities.name,
        country_name: item.countries.name,
        vendor_name: item.vendors.name,
        category_name: item.categories.name,
      });
    });
    setLocations(temp);
  }

  const handleDeleteCategory = () => {
    setLoading(true);
    if (clickedItem?.id) {
      CategoriesApi.deleteCategory(parseInt(clickedItem.id))
        .then(async (res) => {
          await Toast.fire({
            title: 'Deleted.',
            icon: 'success'
          });
          setOpenDelete(false);
          // reset UI
          setClickedItem({ name: '', id: '' });
          setLocations([]);
          setItemAdded(old => old + 1);
          setLoading(false);
        })
        .catch(async (err) => {
          await Toast.fire({
            title: err.message,
            icon: 'error'
          });
          setLoading(false);
        })
    }

  }

  const handleDeleteLocation = (data: any) => {
    // @ts-ignore
    CategoriesApi.removeLocation(data.id, clickedItem.id)
      .then(async (res) => {
        setLocations((prevState) => {
          return prevState.filter(loc => loc.id !== data.id);
        })

        await Toast.fire({
          title: 'Removed.',
          icon: 'success'
        });
      })
      .catch(async (err) => {
        await Toast.fire({
          title: err.message,
          icon: 'error'
        });
      })
  }

  return (
    <Box sx={{ flex: 1 }}>
      <PageHeader pageTitle='Categories' actionTitle='New' onAction={() => setOpenNew(true)}/>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CategoriesTreeView onClick={handleClickItem} items={categories}/>
          </Grid>
          <Grid item xs={8}>
            <div>
              <Typography
                sx={{ flex: '1 1 100%', mb: 1 }}
                variant="h4"
                id="tableTitle"
                component="div"
              >
                {clickedItem?.name ? <>
                  <Stack direction='row'>
                                                    Category: {clickedItem?.name}
                    <IconButton sx={{ marginX: 1 }} onClick={() => setOpenDelete(true)}>
                      <DeleteIcon></DeleteIcon>
                    </IconButton>
                    {
                      clickedItem?.parent_id ?
                        <IconButton onClick={() => setOpenSelect(true)}>
                          <AddCircleIcon></AddCircleIcon>
                        </IconButton>
                        :
                        ''
                    }
                  </Stack>
                </> : 'None'}
              </Typography>
            </div>

            <DataTable
              loading={loading}
              columns={locationCategoryColumns}
              data={locations}
              disableActions={false}
              onView={() => {
              }}
              onDelete={handleDeleteLocation}
              onChangePage={() => {
              }}/>
          </Grid>
        </Grid>
      </Box>

      <NewCategoryDialog
        open={openNew}
        categories={categories}
        onAddSuccess={() => {
          setItemAdded(old => old + 1)
        }}
        onClose={() => setOpenNew(false)}/>

      <SelectLocationDialog
        open={openSelect}
        onClose={() => setOpenSelect(false)}
        //@ts-ignore
        category={{ name: clickedItem.name, id: clickedItem.id }}
        onSuccess={()=> setLocationAdded(old=> old+1)}
      />

      <ResponsiveDialog
        open={openDelete}
        title={'Delete?'}
        content={'Are you sure to detele this category and its categories?'}
        agreeBtnText={'Sure'}
        disagreeBtnText={'Back'}
        handleAgree={handleDeleteCategory}
        handleDisagree={() => setOpenDelete(false)}/>
    </Box>
  )
}
