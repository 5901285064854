import { Box, styled } from '@mui/material';
import { PageHeader } from '../../../../components/PageHeader';
import React, { useContext, useEffect, useState } from 'react';
import { LangContext } from '../../../../context/language.context';
import { useQuery } from 'react-query';
import { ErrorMessageApi } from '../../../../api';
import { IColumn, IErrorMessage } from '../../../../constants';
import { DataTable } from '../../../../components/DataTable';
import { EditErrorDialog } from '../../components/EditErrorDialog';
import { Toast } from '../../../../components/Toast';
import { FilterBox } from '../../../../components/FilterBox';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
const Input = styled('input')({
  display: 'none',
});
const columns: IColumn[] = [
  {
    db_field: 'no',
    label: 'No.',
    minWidth: 70
  },
  {
    db_field: 'error_code',
    label: 'errorCode',
  },
  {
    db_field: 'english_message',
    label: 'english',
  },
  {
    db_field: 'korean_message',
    label: 'korean',
  },
  {
    db_field: 'actions',
    label: '',
    minWidth: 50,
    align: 'right'
  }
]
export const ErrorMessage = () => {
  const { dispatch: { translate } } = useContext(LangContext);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(0);
  const [errData, setErrData] = useState<IErrorMessage>({ error_code: '', english_message: '', korean_message:'' });
  const [errors, setErrors] = useState<IErrorMessage[]>([]);
  const { status, error, data } = useQuery<any, Error>(
    ['listErrorMessages', updated],
    () => ErrorMessageApi.list(0, 50),
  );
  const [keyword, setKeyword]= useState('');
  useEffect(() => {
    if (status === 'success') {
      setErrors(data.tags);
    }
  }, [data]);
  const handleOpenAdd = () => {
    setEditMode(false);
    setErrData({ error_code: '', english_message: '', korean_message: '' });
    setOpen(true);
  }
  const handleOpenEdit = (data: IErrorMessage) => {
    setErrData(data);
    setEditMode(true);
    setOpen(true);
  }
  const handleSave = () => {
    setLoading(true);
    if (editMode){
      ErrorMessageApi.update(errData.error_code, errData.english_message, errData.korean_message)
        .then(async (res)=>{
          setLoading(false);
          setErrData({ error_code: '', english_message: '', korean_message: '' });
          setUpdated(updated+1);
          setOpen(false);
          await Toast.fire({
            title: translate('update'),
            icon: 'success'
          });
        })
        .catch(async (err)=>{
          setLoading(false);
          await Toast.fire({
            title: err.message,
            icon: 'error'
          });
        })
    }else{
      ErrorMessageApi.create(errData.error_code, errData.english_message, errData.korean_message)
        .then(async (res)=> {
          setLoading(false);
          setErrData({ error_code: '', english_message: '', korean_message: '' });
          setUpdated(updated+1);
          setOpen(false);
          await Toast.fire({
            title: translate('success'),
            icon: 'success'
          });
        })
        .catch(async (err)=>{
          setLoading(false);
          await Toast.fire({
            title: err.message,
            icon: 'error'
          });
        })
    }
  }
  const handleFilter = () => {
    if (keyword === ''){
      // @ts-ignore
      setErrors(data);
      return;
    }
    // @ts-ignore
    const filterData = data.filter((err) => err.error_code.toLowerCase() === keyword.toLowerCase());
    setErrors(filterData);
  }
  const handleDelete = (data: IErrorMessage) => {
    Swal.fire({
      title: translate('delete') + '?',
      text: translate('confirmDelete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1976d2',
      cancelButtonColor: '#d33',
      confirmButtonText: translate('yes'),
      cancelButtonText: translate('cancel')
    }).then(async (result) => {
      if (result.isConfirmed) {
        await ErrorMessageApi.del(data.error_code);
        setUpdated(updated + 1);
        await Toast.fire({
          icon: 'success',
          title: translate('deleted')
        })
      }
    })
  }
  if (status === 'error') {
    return <div>{error?.message}</div>;
  }
  if (status === 'loading' || status === 'idle'){
    return <div>{translate('loading')}...</div>
  }
  ///******************* Import **********************
  // const [items, setItems] = useState([]);
  // const [uploading, setUploading] = useState(false);
  // const readExcel = (file: any) => {
  //   const promise = new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsArrayBuffer(file);
  //
  //     fileReader.onload = (e: any) => {
  //       const bufferArray = e.target.result;
  //       const wb = XLSX.read(bufferArray, { type: 'buffer' });
  //       const wsname = wb.SheetNames[0];
  //       const ws = wb.Sheets[wsname];
  //       const data = XLSX.utils.sheet_to_json(ws);
  //       resolve(data);
  //     };
  //
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  //
  //   promise.then((d: any) => {
  //     setUploading(true);
  //     return setItems(d);
  //   });
  // };
  // const sleep = (ms: number) => {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }
  // const importAction = async ()=>{
  //   let records = items;
  //   let count = 0;
  //   while (records.length > 0 ){
  //     const data = records.slice(0, 50);
  //     count += data.length;
  //     records = records.slice(50)
  //     console.log('data', data);
  //     console.log('count', count);
  //     const res : any = await ErrorMessageApi.importXlsx(data);
  //     console.log('res', res);
  //     await sleep(1000);
  //   }
  //   setUploading(false);
  // }
  // useEffect(() => {
  //   if (uploading){
  //     importAction();
  //   }
  // }, [uploading])
  // const onChangeFile = (e: any) => {
  //   readExcel(e.target.files[0]);
  // }
  ///**************************** \Import

  return (
    <Box sx={{ flex: 1 }}>
      <PageHeader pageTitle={'errMessage'} actionTitle={'add'} onAction={handleOpenAdd} />
      {/*HIDE IMPORT BUTTON*/}
      {/*<Box>*/}
      {/*  <label htmlFor="contained-button-file">*/}
      {/*    <Input disabled={uploading} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"*/}
      {/*      id="contained-button-file" type="file" onChange={onChangeFile}/>*/}
      {/*    <Button sx={{ m: 0 }} variant="outlined" component="span" size='small'>*/}
      {/*      Import*/}
      {/*    </Button>*/}
      {/*  </label>*/}
      {/*</Box>*/}
      <FilterBox
        keyword={keyword}
        placeholer={`${translate('search')} ${translate('errorCode')}`}
        onChange={setKeyword}
        onFilter={handleFilter}
      />
      <DataTable
        loading={false}
        columns={columns}
        data={errors}
        onView={handleOpenEdit}
        onViewIcon={'ModeEditOutlined'}
        onDelete={handleDelete}
      />
      <EditErrorDialog
        open={open}
        editMode={editMode}
        loading={loading}
        onClose={() => setOpen(false)}
        data={errData}
        onChange={(value)=> setErrData(value)}
        onSubmit={handleSave}
      />
    </Box>
  )
}
