import { Card, CardContent, FormControl, InputAdornment, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useContext } from 'react';
import { LangContext } from '../../context/language.context';
interface Props {
  keyword: string;
  placeholer: string;
  onChange: (value: string) => void;
  onFilter: () => void;
}
export const FilterBox = (props: Props) => {
  const { keyword, placeholer, onChange, onFilter } = props;
  const { dispatch: { translate } } = useContext(LangContext);
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      onFilter();
    }
  }
  return(
    <Card sx={{ marginBottom: '2rem' }}>
      <CardContent>
        <Stack direction='row' justifyContent='space-between' spacing={3}>
          <FormControl fullWidth>
            <Typography variant='body1'>{translate('search')}</Typography>
            <TextField value={keyword}
              size='small'
              id="keyword"
              placeholder={placeholer}
              onChange={(e) => {onChange(e.target.value)} }
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle clean"
                      onClick={() => {onChange('')}  }
                      edge="end"
                    >
                      <BackspaceOutlinedIcon  />
                    </IconButton>
                  </InputAdornment>,
              }}
            />
          </FormControl>
          <Stack direction='column' justifyContent='end' sx={{ minWidth: '100px' }}>
            <Button variant='outlined' size='large' onClick={onFilter}>
              {translate('search')}
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
