import { IExchangeRateHistory } from '../features/exchange-rate/constants/columns';
import { AxiosClient } from './axios-client';

const path = 'exchange-rate-history';

export const ExchangeRateHistoryAPI = {
  getAll: async () => {
    const response: IExchangeRateHistory[] = await AxiosClient.get(`${path}`);
    return response;
  },
}