import { AxiosClient } from './axios-client';

const path = 'translation';

export const TranslationApi = {
  getLocales: async () => {
    const response: any[] = await AxiosClient.get(`${path}/locales`);
    return response;
  },
  addLocale: async (code: string, name: string) => {
    return AxiosClient.post(`${path}/locales`, { name: name, code: code, default: false });
  },
  updateLocale: (code: string, name: string, _default: boolean)=>{
    return AxiosClient.patch(`${path}/locales`,{ code: code, name: name, default: _default });
  },
  removeLocale: (code: string)=>{
    return AxiosClient.delete(`${path}/locales?locale=${code}`);
  },
  getAllTags: async () => {
    const response: {tag: string}[] = await AxiosClient.get(`${path}/tags`);
    return response;
  },
  getTagTranslation: async (tag: string, locale: string) => {
    const response: {message: string} = await AxiosClient.get(`${path}/filter?locale=${locale}&tag=${tag}`);
    return response.message;
  },
  addTagTranslation: (tag: string, locale: string, message: string) => {
    return AxiosClient.post(`${path}`, { locale: locale, tag: tag, message: message });
  },
  updateTranslation: (tag: string, locale: string, message: string)=>{
    return AxiosClient.patch(`${path}`, { locale: locale, tag: tag, message: message });
  },
  deleteTag: (tag: string) => {
    return AxiosClient.delete(`${path}/tags?tag=${tag}`);
  },
  getTranslationMessage: async () => {
    const res : {count: number, tags: any[]} = await AxiosClient.get(`${path}/messages`);
    return res;
  },
  filterTranslationMessage: async (skip: number, take: number, keyword: string) => {
    const res : {count: number, tags: any[]} = await AxiosClient.get(`${path}/messages?skip=${skip}&take=${take}&keyword=${keyword}`);
    return res;
  },
  updateMultipleTranslation: (data: {locale: string, tag: string, message: string}[]) => {
    return AxiosClient.patch(`${path}/multiple`, data );
  },
  getCount: async () => {
    const res: {_all: number} = await AxiosClient.get(`${path}/messages/count`);
    return res._all;
  },
  apply: async () => {
    return AxiosClient.post(`${path}/apply`);
  }
}
