import React, { useContext } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { IBreadcrumb } from '../../constants';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { LangContext } from '../../context/language.context';

interface IBreadcrumbsProps{
    items: IBreadcrumb[];
}

export default function ActiveLastBreadcrumbs(props: IBreadcrumbsProps): JSX.Element{
  const { items } = props;
  const { dispatch: { translate } } = useContext(LangContext);
  const navigate = useNavigate();

  const handleClick = (e: any, item: IBreadcrumb) => {
    e.preventDefault();
    navigate(`/${item.href}`);
  }

  return(
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {
          items.map((value, index)=>{
            return(
              <Link
                onClick={(e) => handleClick(e, value)}
                underline="hover"
                color={index === items.length - 1 ? 'text.primary' : 'inherit'}
                href={value.href}
                aria-current="page"
                key={index}
              >
                <Typography variant="body1" gutterBottom component="div">
                  {translate(value.name)}
                </Typography>
              </Link>
            )
          })
        }
      </Breadcrumbs>
    </div>
  );
}
