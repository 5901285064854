import React, { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { Toast } from '../../../../components/Toast';
import { ClientApi } from '../../../../api';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const CreateClientDialog = (props: Props): JSX.Element => {
  const { open, onClose, onSuccess } = props;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  const [errMsg, setErrMsg] = useState({
    name: '',
  })

  const validateFields = () => {
    let validForm = true;
    if (!name) {
      setErrMsg(prevState => {
        return { ...prevState, name: 'Required' }
      })
      validForm = false;
    }
    return validForm;
  }

  const handleSubmit = async () => {
    const valid = validateFields();
    if (!valid) {
      return;
    }
    setLoading(true);
    try{
      await ClientApi.create(name);
      setLoading(false);
      handleClose();
      onSuccess();
      await Toast.fire({
        icon: 'success',
        title: 'Create successful'
      })
    }catch (e: any) {
      setLoading(false);
      handleClose();
      await Toast.fire({
        icon: 'error',
        title: e.message
      })
    }
  }

  const handleClose = () => {
    onClose();
    setErrMsg({
      name: '',
    });
    setName('');
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>New Client</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Client's Name"
          required
          type="text"
          fullWidth
          sx={{ minWidth: '500px' }}
          size="small"
          error={errMsg.name !== ''}
          helperText={errMsg.name}
          value={name}
          onChange={($event) => {
            setName($event.target.value);
            setErrMsg(prevState => {
              return { ...prevState, name: '' }
            })
          }}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={handleClose}>Cancel</LoadingButton>
        <LoadingButton loading={loading} onClick={handleSubmit} variant="outlined">Submit</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
