import React, { useReducer, FC, ReactNode, createContext } from 'react';
const ko = require('../translation/ko.json');
const en = require('../translation/en.json');
import { ActionType, LANGUAGE_NAME, Languages } from '../constants';
import { LanguageReducer } from '../state/reducers/language.reducer';
export interface LangState {
  language: string;
}
interface ContextProps {
  state: LangState;
  dispatch: {
    setLanguage: (lang: string) => void;
    translate: (key: string) => string;
  }
}
export const LangContext = createContext({} as ContextProps);
const localStorageLang = localStorage.getItem(LANGUAGE_NAME);
const initialState = {
  // language: localStorageLang ? localStorageLang : Languages.KO,
  language: localStorageLang ? localStorageLang : Languages.EN,
}
interface LangStateProps {
  children: ReactNode;
}
export const LanguageState: FC<LangStateProps> = ({ children }) => {
  const [state, dispatch] = useReducer(LanguageReducer, initialState);

  const setLanguage = (lang: string) => {
    localStorage.setItem(LANGUAGE_NAME, lang);
    dispatch({
      type: ActionType.SET_LANGUAGE,
      payload: lang
    });
  }

  const translate = (key: string): string => {
    const { language } = state;
    let langData: { [key: string]: string } = {};

    if(language === Languages.EN) {
      langData = en;
    }else if(language === Languages.KO) {
      langData = ko;
    }

    return langData[key] ? langData[key] : key;
  }

  return(
    <LangContext.Provider value={{ state, dispatch: { setLanguage, translate } }}>
      {children}
    </LangContext.Provider>
  );
}
