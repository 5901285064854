import { ActionType, IAction } from '../../constants';

export const ReloadReducer = (state = 0, action: IAction) => {
  switch (action.type) {
  case ActionType.SET_RELOAD:
    return action.payload;
  default:
    return 0;
  }
}
