import { Box } from '@mui/material';
import { DataTable } from '../../../../components/DataTable';
import React, { useEffect, useState } from 'react';
import { locationColumns } from '../../constants';
import { LocationsApi } from '../../../../api';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../../constants';
import { Toast } from '../../../../components/Toast';
import { PageHeader } from '../../../../components/PageHeader';
import { FilterBox } from '../../../../components/FilterBox';
import { ImportLocationDialog } from '../../components/ImportLocationDialog';
import Swal from 'sweetalert2';

export const Locations = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [keyword, setKeyWord] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [openImport, setOpenImport] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  if (errorMsg){
    return <span>{errorMsg}</span>
  }

  const fetchData = async () => {
    try{
      setLoading(true);
      const result = await LocationsApi.getLocations();
      const temp = result.map((item)=> ({ ...item, description: item.location_description[0].description }))
      setData(temp);
      setOriginalData(temp);
      setLoading(false);
    }catch (e:any){
      setErrorMsg(e.message);
    }
  }

  const handleView = (data: any) =>{
    navigate(`/${RoutePath.LOCATIONS}/${data.id}`);
  }

  const handleDelete = (selectedRow: any) =>{
    Swal.fire({
      title: 'Delete?',
      text: 'Are you sure to delete this location?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1976d2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try{
          await LocationsApi.deleteLocation(selectedRow.id);
          await Toast.fire({
            title: 'Deleted.',
            icon: 'success'
          });
          setData((prevState) => {
            return prevState.filter((value:any) => value.id !== selectedRow.id );
          });
          setOriginalData((prevState)=> {
            return prevState.filter((value: any) => value.id !== selectedRow.id);
          });
        }catch (e: any) {
          await Toast.fire({
            title: e.message,
            icon: 'error'
          });
        }
      }
    })
  }

  const handleFilter = () => {
    if (keyword === ''){
      setData(originalData);
      return;
    }
    const lowerCaseKey = keyword.toLowerCase();
    const filterData = originalData.filter((loc) =>
      loc.oag_code.toLowerCase().includes(lowerCaseKey)
      || loc.description.toLowerCase().includes(lowerCaseKey)
      || loc.city_code.toLowerCase() == lowerCaseKey
      || loc.city_name.toLowerCase().includes(lowerCaseKey)
    );
    setData(filterData);
  }

  return (
    <Box sx={{ flex: 1, maxWidth: '100vw' }}>
      <PageHeader pageTitle="Locations" actionTitle={'import'} onAction={()=> setOpenImport(true)}/>
      <FilterBox keyword={keyword} placeholer={'Search OAG code, name, city'} onChange={setKeyWord} onFilter={handleFilter}/>
      <DataTable
        loading={loading}
        columns={locationColumns}
        data={data}
        onView={handleView}
        onDelete={handleDelete}
        onChangePage={() => {
        }}/>
      {/*<AddLocationDialog open={openNew} onClose={()=> setOpenNew(false) }/>*/}
      <ImportLocationDialog open={openImport} onClose={() => setOpenImport(false)} ></ImportLocationDialog>
    </Box>
  );
}
