import React, { useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';
import { CreateUserDialog } from '../../components/CreateUserDialog';
import { useQuery } from 'react-query';
import { AdminStatusLabel, IAdminUser, IColumn, RoutePath } from '../../../../constants';
import { AdminUserApi } from '../../../../api';
import { PageHeader } from '../../../../components/PageHeader';
import { DataTable } from '../../../../components/DataTable';
import { format } from 'date-fns';
import { Toast } from '../../../../components/Toast';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const renderStatus = (value: string) => {
  if (value === 'IN_ACTIVE' ){
    return (<Chip label={AdminStatusLabel[value]} color="secondary" />)
  }
  if (value === 'ACTIVE' ){
    return (<Chip label={AdminStatusLabel[value]} color="success" />)
  }
  if (value === 'UNDER_REVIEW' ){
    return (<Chip label={AdminStatusLabel[value]} color="secondary" variant='outlined' />)
  }
}

const renderDatetime = (value: any)=> {
  return <span>{format(new Date(value), 'yy-MM-dd')} {format(new Date(value), 'p')}</span>
}

const columns : IColumn[] = [
  {
    db_field: 'no',
    label: 'No.',
    minWidth: 70
  },
  {
    db_field: 'username',
    label: 'Username',
  },
  {
    db_field: 'email',
    label: 'Email',
    align: 'left'
  },
  {
    db_field: 'agency_name',
    label: 'Client Name',
    align: 'left'
  },
  {
    db_field: 'status',
    label: 'Status',
    align: 'left',
    format: (value)=> renderStatus(value),
  },
  {
    db_field: 'created_date',
    label: 'Created at',
    align: 'left',
    format: (value)=> renderDatetime(value),
  },
  {
    db_field: 'actions',
    label: '',
    minWidth: 50,
    align: 'right'
  }
];
export const AdminUsers = (): JSX.Element => {
  const navigate = useNavigate();
  const [openCreate, setOpenCreate] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [users, setUsers] = useState<IAdminUser[]>([]);
  const { status, error, data } = useQuery<IAdminUser[], Error>(
    ['getAll', updated],
    AdminUserApi.getAll,
  );
  if (status === 'error') {
    return <div>{error.message}</div>;
  }
  useEffect(()=> {
    if (data){
      setUsers((prevState) => {
        prevState = data.map((item)=> ({ ...item, agency_name: item?.agencies ? item.agencies.name : null }));
        return prevState;
      })
    }
  },[data])

  const handleDelete = async (data: any) => {
    Swal.fire({
      title: 'Delete this user?',
      text: 'Are you sure to delete this user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1976d2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await AdminUserApi.del(data.id);
        setUpdated(!updated);
        await Toast.fire({
          icon: 'success',
          title: 'Deleted this user'
        })
      }
    })
  }

  const handleView = (data:any) => {
    navigate(`/${RoutePath.ADMIN_USERS}/${data.id}`);
  }

  return (
    <>
      <Box sx={{ flex: 1 }}>
        <PageHeader pageTitle='Admin Users' actionTitle='New' onAction={() => setOpenCreate(true)}/>
        <Box sx={{ mt: 2 }}>
          {
            data ? <DataTable
              loading={status !== 'success'}
              columns={columns}
              data={users}
              onView={handleView}
              onDelete={handleDelete}/>
              :
              <>Loading...</>
          }
        </Box>
        <CreateUserDialog open={openCreate} onClose={()=> setOpenCreate(false)}/>
      </Box>
    </>
  );
}
