import { IconButton, Stack, TextField } from '@mui/material';
import { Fragment, useContext } from 'react';
import { ErrorMessage, IColumn } from '../../../constants';
import { format } from 'date-fns';
import { OptionalServices, SupplierOptions } from '../constant';
import { ClickTooltip, CustomTooltip } from '../../../components/CustomTooltip';
import { ReloadContext } from '^/context/reload.context';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import Swal from 'sweetalert2';
import { ReservationApi } from '^/api/reservation.api';
import { useState } from 'react';

const renderDatetime = (value: any) => {
  return (
    <span>
      {format(new Date(value), 'yy-MM-dd')} {format(new Date(value), 'p')}
    </span>
  );
};

const renderOptionalService = (value: []) => {
  if (value) {
    return (
      <ul style={{ paddingLeft: '5px' }}>
        {value.map((info) => {
          return (
            <div>
              <b>*</b> <span>{info}</span>
            </div>
          );
        })}
      </ul>
    );
  } else {
    return <span>-</span>;
  }
};

const RenderResID = (props: { res_id: string; customer_name: string }) => {
  const [customerName] = useState(
    props.customer_name ? props.customer_name : '',
  );
  const [resId, setResId] = useState(props.res_id ? props.res_id : '');
  const [changeResId, setChangeResId] = useState('');
  const [open, setOpen] = useState(false);
  const {
    dispatch: { setReload },
  } = useContext(ReloadContext);

  const handleChangeResId = async () => {
    Swal.fire({
      text: `예약자 ${customerName} 님의 ${resId} 예약건을 ${changeResId}로 예약 확정하시겠습니까?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1976d2',
      cancelButtonColor: '#d33',
      confirmButtonText: '변경',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire({
            title: '예약번호를 변경 중입니다...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          const response = await ReservationApi.changeResId(resId, changeResId);
          Swal.close();
          if (!response.success) {
            throw new Error(ErrorMessage[`ERROR_CODE_${response.errorCode}`]);
          }
          setOpen(false);
          setResId(changeResId);
          setReload();
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: '예약번호 변경이 완료되었습니다.',
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (e: any) {
          await Swal.fire({
            position: 'center',
            icon: 'error',
            title: '예약번호 변경에 실패했습니다.',
            text: e.message,
          });
        }
      }
    });
  };
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {resId}
      </Stack>
      <ClickTooltip
        open={open}
        setOpen={setOpen}
        content={
          <Fragment>
            <Stack sx={{ padding: '5px' }} direction={'column'} spacing={2}>
              <TextField
                type="email"
                variant="outlined"
                fullWidth
                size="small"
                value={changeResId}
                onChange={(e) => setChangeResId(e.target.value)}
              />
              <Stack direction="row" spacing={2} justifyContent="center">
                <IconButton onClick={() => setOpen(false)}>
                  <ClearIcon fontSize="small" />
                </IconButton>
                <IconButton disabled={!changeResId} onClick={handleChangeResId}>
                  <CheckIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          </Fragment>
        }
      />
    </Stack>
  );
};

const renderName = (value: { last_name: string; first_name: string }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {value.first_name} {value.last_name}
      </Stack>
    </Stack>
  );
};

const renderCurrency = (value: { currency: string; exchange: number }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {value.currency}
      </Stack>
      <CustomTooltip
        content={
          <Fragment>
            <Stack sx={{ padding: '5px' }} direction={'column'}>
              <div>
                <b>Exchange Rate: </b>{' '}
                <span>{value.exchange ? value.exchange : 1}</span>
              </div>
            </Stack>
          </Fragment>
        }
      />
    </Stack>
  );
};

const renderLocation = (value: {
  name: string;
  name_en: string;
  address: string;
  phone: string;
}) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {value.name}
      </Stack>
      <CustomTooltip
        content={
          <Fragment>
            <Stack sx={{ padding: '5px' }} direction={'column'}>
              <div>
                <b>name (EN): </b> <span>{value.name_en}</span>
              </div>
              <div>
                <b>Address: </b> <span>{value.address}</span>
              </div>
              <div>
                <b>Phone: </b> <span>{value.phone}</span>
              </div>
            </Stack>
          </Fragment>
        }
      />
    </Stack>
  );
};
//add new component for rendering contact info
const renderContact = (value: {
  email: string;
  phoneCode: string;
  phoneNumber: string;
}) => {
  return (
    <div style={{ paddingLeft: '5px', textAlign: 'left' }}>
      <p>
        <span>
          <b>Email: </b>
        </span>
        <span>{value.email}</span>
      </p>
      <p>
        <span>
          <b>Phone: </b>
        </span>
        <span>
          {value.phoneCode} {value.phoneNumber}
        </span>
      </p>
    </div>
  );
};

const renderInsuranceInfo = (value: {
  insurance_name: string;
  insurances_detail: string[];
}) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {value.insurance_name}
      </Stack>
      <CustomTooltip
        content={
          <Fragment>
            <Stack sx={{ padding: '5px' }} direction={'column'}>
              {value.insurances_detail?.map((info) => {
                return (
                  <div>
                    <b>*</b> <span>{info}</span>
                  </div>
                );
              })}
            </Stack>
          </Fragment>
        }
      />
    </Stack>
  );
};

const renderFlightName = (value: {
  flight_brand: string;
  flight_number: string;
}) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {value.flight_brand} {value.flight_number || '-'}
      </Stack>
    </Stack>
  );
};

//add condition to show/hide
export const columns: IColumn[] = [
  { db_field: 'no', label: 'No.', minWidth: 70 },
  {
    db_field: 'date_request',
    label: '예약 날짜',
    align: 'center',
    minWidth: 150,
    format: (value) => renderDatetime(value),
  },
  {
    db_field: 'supplier',
    label: '공급사',
    align: 'center',
    minWidth: 120,
    format: (value) => SupplierOptions[value],
  },
  {
    db_field: 'res_id',
    label: '예약 번호',
    align: 'center',
    minWidth: 150,
    format: (value) => (
      <RenderResID res_id={value.res_id} customer_name={value.customer_name} />
    ),
  },
  {
    db_field: 'customer_name',
    label: '고객명',
    align: 'center',
    format: (value) => renderName(value),
    minWidth: 120,
  },
  {
    db_field: 'age',
    label: '나이(만)',
    align: 'center',
    minWidth: 120,
  },
  {
    db_field: 'contact_info',
    label: '고객 정보',
    align: 'center',
    format: (value) => renderContact(value),
    minWidth: 270,
  },
  {
    db_field: 'hertz_membership',
    label: '멤버십',
    minWidth: 150,
    align: 'center',
  },
  {
    db_field: 'pickup_datetime',
    label: '픽업 시간',
    align: 'center',
    format: (value) => renderDatetime(value),
    minWidth: 160,
  },
  {
    db_field: 'pickup_office',
    label: '픽업 영업소',
    align: 'center',
    format: (value) => renderLocation(value),
    minWidth: 170,
  },
  {
    db_field: 'pickup_loc',
    label: '픽업 영업소 코드',
    align: 'center',
    minWidth: 150,
  },
  {
    db_field: 'return_datetime',
    label: '반납 시간',
    align: 'center',
    format: (value) => renderDatetime(value),
    minWidth: 160,
  },
  {
    db_field: 'return_office',
    label: '반납 영업소',
    align: 'center',
    format: (value) => renderLocation(value),
    minWidth: 170,
  },
  {
    db_field: 'return_loc',
    label: '반납 영업소 코드',
    align: 'center',
    minWidth: 150,
  },
  {
    db_field: 'car_model',
    label: '차량 이름',
    minWidth: 150,
    align: 'center',
  },
  {
    db_field: 'insurance_name',
    label: '보험',
    align: 'center',
    format: (value) => renderInsuranceInfo(value),
    minWidth: 180,
  },
  {
    db_field: 'options',
    label: '옵션',
    align: 'left',
    format: (value) => renderOptionalService(value),
    minWidth: 200,
  },
  {
    db_field: 'flight_name',
    label: '항공편명',
    align: 'center',
    format: (value) => renderFlightName(value),
    minWidth: 150,
  },
  {
    db_field: 'currency',
    label: '통화',
    align: 'center',
    minWidth: 90,
    format: (value) => renderCurrency(value),
  },
  {
    db_field: 'rate_foreigner_amount',
    label: '결제 금액 (현지화)',
    align: 'center',
    minWidth: 150,
  },
  {
    db_field: 'rate_amount',
    label: '결제 금액 (원화)',
    align: 'center',
    minWidth: 150,
    format: (value) => value.toLocaleString(),
  },
  {
    db_field: 'total_foreigner_amount',
    label: '총 금액 (현지화)',
    align: 'center',
    minWidth: 150,
    format: (value) => value.toLocaleString(),
  },
  {
    db_field: 'total_amount',
    label: '총 금액 (원화)',
    align: 'center',
    format: (value) => value.toLocaleString(),
    minWidth: 150,
  },
  {
    db_field: 'etc_amount',
    label: '선불, 현지 결제 예정 금액 (현지화)',
    align: 'center',
    minWidth: 150,
  },
  {
    db_field: 'net',
    label: 'Net 금액',
    align: 'center',
    minWidth: 150,
  },
];
