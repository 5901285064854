import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { SIDEBAR_WIDTH } from '../../constants';
import { styled, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import GTranslateRoundedIcon from '@mui/icons-material/GTranslateRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
const drawerWidth = SIDEBAR_WIDTH;

const activeStyle = {
  backgroundColor: '#ddd',
  borderRightColor: 'var(--primary-color)',
  borderRightStyle: 'solid',
  borderRightWidth: '5px'
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));
interface Props {
    window?: () => Window;
    mobileOpen?: boolean;
    onClose?: () => void;
}

export const Sidebar = (props: Props): JSX.Element => {
  const { window, mobileOpen, onClose } = props;
  const theme = useTheme();
  const container = window !== undefined ? () => window().document.body : undefined;
  const navigate = useNavigate();
  const [selected, setSelected] = useState('General');

  const handleRedirect = (text: string) => {
    setSelected(text);
    navigate(`/${text}`);
  }

  const isActive = (menu: string) => {
    return menu === selected;
  }

  return (
    <Drawer
      container={container}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      variant="persistent"
      anchor="left"
      open={mobileOpen}
    >
      <DrawerHeader>
        <Typography
          variant="overline"
          align="center"
          sx={{ flex: 1, fontSize: '20px', color: 'var(--primary-color)' }}
        >
                    Admin
        </Typography>
        <IconButton onClick={onClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem>
          <ListItemIcon>
            <SettingsIcon/>
          </ListItemIcon>
          <ListItemText primary="Settings"/>
        </ListItem>
        {['General'].map((text, index) => (
          <ListItem button key={text} onClick={() => handleRedirect(text)} sx={isActive(text) ? activeStyle : {}}>
            <ListItemIcon/>
            <ListItemText primary={text}/>
          </ListItem>
        ))}
      </List>
      <Divider/>
      <List>
        <ListItem>
          <ListItemIcon>
            <FmdGoodRoundedIcon/>
          </ListItemIcon>
          <ListItemText primary="Location Management"/>
        </ListItem>
        {['Countries', 'Cities', 'Locations', 'Categories'].map((text, index) => (
          <ListItem button key={text} onClick={() => handleRedirect(text)} sx={isActive(text) ? activeStyle : {}}>
            <ListItemIcon/>
            <ListItemText primary={text}/>
          </ListItem>
        ))}
      </List>
      <Divider/>
      <List>
        <ListItem>
          <ListItemIcon>
            <GTranslateRoundedIcon/>
          </ListItemIcon>
          <ListItemText primary="Translation Management"/>
        </ListItem>
        {['Locales', 'Translation'].map((text, index) => (
          <ListItem button key={text} onClick={() => handleRedirect(text)} sx={isActive(text) ? activeStyle : {}}>
            <ListItemIcon/>
            <ListItemText primary={text}/>
          </ListItem>
        ))}
      </List>
      <Divider/>
      <List>
        <ListItem>
          <ListItemIcon>
            <GroupRoundedIcon/>
          </ListItemIcon>
          <ListItemText primary="Users & Roles Management"/>
        </ListItem>
        {['Users', 'Roles', 'Permission'].map((text, index) => (
          <ListItem button key={text} onClick={() => handleRedirect(text)} sx={isActive(text) ? activeStyle : {}}>
            <ListItemIcon/>
            <ListItemText primary={text}/>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
