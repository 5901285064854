import { AdminPermissionLabel, AdminPermissionsGroup } from '../../constant/permissions.constant';
import { Box, FormControlLabel, Grid, Paper } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';

interface Props {
  checked: number[],
  onChange: (event: any) => void;
  disabled: boolean;
}
export const PermissionGroup = (props: Props) => {
  const { checked, onChange , disabled } = props;

  const children = (children: number[]) =>
  {
    return(
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        {
          children.map((child) => (
            <FormControlLabel
              key={child}
              disableTypography={true}
              disabled={disabled}
              label={AdminPermissionLabel[child]}
              control={<Checkbox
                value={child}
                checked={checked.includes(child)}
                onChange={(event) => onCheck(event)}
              />}
            />
          ))
        }
      </Box>
    )
  }
  const onCheck = ($event: any) => {
    onChange($event);
  }
  return(
    <Grid container spacing={2}>
      {
        AdminPermissionsGroup.map((group, index) => (
          <Grid key={index} item xs={4}>
            <Paper variant='outlined'>
              <Box padding={1}>
                <FormControlLabel
                  disableTypography={true}
                  disabled={disabled}
                  label={AdminPermissionLabel[group[0]]}
                  control={
                    <Checkbox
                      value={group[0]}
                      checked={checked.includes(group[0])}
                      onChange={(event) => onCheck(event)}
                    />
                  }
                />
                { group.length > 1 ? children(group.slice(1)) : <></>}
              </Box>
            </Paper>
          </Grid>
        ))
      }
    </Grid>
  )
}
