import { Box, Button, Chip, Stack } from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { PageHeader } from '../../../../components/PageHeader';
import { DataTable } from '../../../../components/DataTable';
import { ReservationApi } from '../../../../api/reservation.api';
import { FilterBox } from '../../components/FilterBox';
import { ErrorMessage, IColumn } from '../../../../constants';
import { addDays, format } from 'date-fns';
import { OptionalServices, SupplierOptions } from '../../constant';
import { CustomTooltip } from '../../../../components/CustomTooltip';
import { Toast } from '../../../../components/Toast';
import Swal from 'sweetalert2';
import { UserContext } from '^/context/user.context';

const renderStatus = (status: any) => {
  if (status === 'booked') {
    return <Chip label={status} color="success" />;
  }
  if (status === 'canceled') {
    return <Chip label={status} color="error" />;
  }
  if (status === 'refunded') {
    return <Chip label={status} color="primary" />;
  }
  if (status === 'noShow') {
    return <Chip label={status} color="warning" />;
  }
};

const renderDatetime = (value: any) => {
  return (
    <span>
      {format(new Date(value), 'yy-MM-dd')} {format(new Date(value), 'p')}
    </span>
  );
};

const renderOptionalService = (value: any) => {
  //catch error with data ưrong format
  let services = null;
  try {
    services = { ...JSON.parse(value) };
  } catch (error) {
    return <span>- Data error format</span>;
  }
  if (services?.info) {
    return (
      <ul style={{ paddingLeft: '5px' }}>
        {services.info?.map((value: any, index: number) => (
          <li key={index}>
            {OptionalServices[value.code]} ({value.quantity})
          </li>
        ))}
      </ul>
    );
  } else {
    return <span>-</span>;
  }
};

const renderName = (value: {
  korean_name: string;
  last_name: string;
  first_name: string;
}) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {value.korean_name}
      </Stack>
      <CustomTooltip
        content={
          <Fragment>
            <Stack sx={{ padding: '5px' }} direction={'column'}>
              <div>
                <b>First Name (EN): </b> <span>{value.first_name}</span>
              </div>
              <div>
                <b>Last Name (EN): </b> <span>{value.last_name}</span>
              </div>
            </Stack>
          </Fragment>
        }
      />
    </Stack>
  );
};

const renderPayType = (type: string) => {
  if (type == 'PPD') return <Chip label={type} color={'info'} />;
  else if (type === 'POD') return <Chip label={type} color={'default'} />;
};

//add component for rendering PG amount
const renderPGAmount = (value: { amount: number; type: string }) => {
  if (value.type == 'PPD') return <span>{value.amount.toLocaleString()}</span>;
  else if (value.type === 'POD') return <span>-</span>;
};

const renderCurrency = (value: { currency: string; exchange: number }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {value.currency}
      </Stack>
      <CustomTooltip
        content={
          <Fragment>
            <Stack sx={{ padding: '5px' }} direction={'column'}>
              <div>
                <b>Exchange Rate: </b>{' '}
                <span>{value.exchange ? value.exchange : 1}</span>
              </div>
            </Stack>
          </Fragment>
        }
      />
    </Stack>
  );
};
//add new component for rendering location info
const renderLocation = (value: {
  name: string;
  address: string;
  phone: string;
}) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {value.name}
      </Stack>
      <CustomTooltip
        content={
          <Fragment>
            <Stack sx={{ padding: '5px' }} direction={'column'}>
              <div>
                <b>Address (EN): </b> <span>{value.address}</span>
              </div>
              <div>
                <b>Phone: </b> <span>{value.phone}</span>
              </div>
            </Stack>
          </Fragment>
        }
      />
    </Stack>
  );
};
//add new component for rendering contact info
const renderContact = (value: {
  email: string;
  phoneCode: string;
  phoneNumber: string;
}) => {
  return (
    <div style={{ paddingLeft: '5px', textAlign: 'left' }}>
      <p>
        <span>
          <b>Email: </b>
        </span>
        <span>{value.email}</span>
      </p>
      <p>
        <span>
          <b>Phone: </b>
        </span>
        <span>
          {value.phoneCode} {value.phoneNumber}
        </span>
      </p>
    </div>
  );
};

const renderFlightInfo = (value: {
  airline: string;
  flightNumber: string;
  arrival: string;
}) => {
  return (
    <div style={{ paddingLeft: '5px', textAlign: 'left' }}>
      <p>
        <span>
          <b>Airline: </b>
        </span>
        <span>{value.airline || '-'}</span>
      </p>
      <p>
        <span>
          <b>Flight number: </b>
        </span>
        <span>
          {value.flightNumber ||'-'}
        </span>
      </p>
      <p>
        <span>
          <b>Arrival: </b>
        </span>
        <span>
          {value.arrival ||'-'}
        </span>
      </p>
    </div>
  );
};

const renderInsuranceInfo = (value: {
  insurance_name: string;
  insurances_detail: string[];
}) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {value.insurance_name}
      </Stack>
      <CustomTooltip
        content={
          <Fragment>
            <Stack sx={{ padding: '5px' }} direction={'column'}>
              {value.insurances_detail.map((info) => {
                return (
                  <div>
                    <b>*</b> <span>{info}</span>
                  </div>
                );
              })}
            </Stack>
          </Fragment>
        }
      />
    </Stack>
  );
};

const renderResendEmail = (value: { res_id: string; res_status: string }) => {
  if (value.res_status === 'canceled') return '-';
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => handleResendEmail(value.res_id)}
    >
      재전송
    </Button>
  );
};
const handleResendEmail = async (resId: string) => {
  Swal.fire({
    title: '확약 메일 재전송',
    text: `예약번호 ${resId}의 확약 메일을 재전송하시겠습니까?`,
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#1976d2',
    cancelButtonColor: '#d33',
    confirmButtonText: '전송',
    cancelButtonText: '취소',
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        Swal.fire({
          title: '메일을 재전송 중입니다...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const response = await ReservationApi.resendEmail(resId);
        Swal.close();
        if (!response.success) {
          throw new Error(ErrorMessage[`ERROR_CODE_${response.errorCode}`]);
        }
        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: '확약 메일이 재전송되었습니다.',
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (e: any) {
        await Swal.fire({
          position: 'center',
          icon: 'error',
          title: '메일 전송에 실패했습니다.',
          text: e.message,
        });
      }
    }
  });
  return;
};

const renderKakaoResend = (value: { res_id: string; res_status: string }) => {
  if (value.res_status !== 'booked') return '-';
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => handleResendKakao(value.res_id)}
    >
      재발송
    </Button>
  );
};

const handleResendKakao = async (resId: string) => {
  if (!resId) {
    await Swal.fire({
      position: 'center',
      icon: 'error',
      title: '예약번호를 찾을 수 없습니다.',
    });
    return;
  }
  Swal.fire({
    title: '카카오톡 알림 재발송',
    text: `예약번호 ${resId}의 카카오톡 알림을 재발송하시겠습니까?`,
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#1976d2',
    cancelButtonColor: '#d33',
    confirmButtonText: '전송',
    cancelButtonText: '취소',
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        Swal.fire({
          title: '카카오톡 알림을 발송 중입니다...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const response = await ReservationApi.kakaoResend(resId);
        Swal.close();
        if (!response.result) {
          throw new Error('카카오톡 알림을 발송하지 못했습니다.');
        }
        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: '카카오톡 알림이 재발송 되었습니다.',
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (e: any) {
        await Swal.fire({
          position: 'center',
          icon: 'error',
          title: '카카오톡 알림 발송에 실패했습니다.',
          text: e.message,
        });
      }
    }
  });
  return;
};

//add condition to show/hide
const columns: IColumn[] = [
  { db_field: 'no', label: 'No.', minWidth: 70 },
  {
    db_field: 'agent_name',
    label: 'Client',
    align: 'center',
    only_super_admin: true,
  },
  {
    db_field: 'agent',
    label: 'Client number',
    align: 'center',
    only_super_admin: true,
  },
  {
    db_field: 'btn',
    label: ' ',
    align: 'center',
    minWidth: 120,
  },
  {
    db_field: 'date_request',
    label: 'Date of Booking',
    align: 'center',
    minWidth: 150,
    format: (value) => renderDatetime(value),
  },
  {
    db_field: 'res_status',
    label: 'Res status',
    align: 'center',
    format: (value) => renderStatus(value),
  },
  {
    db_field: 'supplier',
    label: 'Supplier',
    align: 'center',
    minWidth: 120,
    format: (value) => SupplierOptions[value],
  },
  {
    db_field: 'order_id',
    label: 'Order Number',
    align: 'center',
    minWidth: 150,
  },
  {
    db_field: 'res_id',
    label: 'Res ID',
    align: 'center',
  },
  {
    db_field: 'korean_name',
    label: 'Korean name',
    align: 'center',
    format: (value) => renderName(value),
    minWidth: 120,
  },
  //add contactinfo field
  {
    db_field: 'contact_info',
    label: 'Contact Info',
    align: 'center',
    format: (value) => renderContact(value),
    minWidth: 270,
  },
  {
    db_field: 'flight_info',
    label: 'Flight Info',
    align: 'center',
    format: (value) => renderFlightInfo(value),
    minWidth: 270,
  },
  {
    db_field: 'pay_type',
    label: 'PP/POD',
    align: 'center',
    format: (value) => renderPayType(value),
  },
  //update pickup_office and return_office field
  {
    db_field: 'pickup_country',
    label: 'P/U Country',
    align: 'center',
    minWidth: 150,
  },
  {
    db_field: 'pickup_office',
    label: 'P/U Location',
    align: 'center',
    format: (value) => renderLocation(value),
    minWidth: 150,
  },
  {
    db_field: 'pickup_datetime',
    label: 'P/U Date&Time',
    align: 'center',
    format: (value) => renderDatetime(value),
    minWidth: 150,
  },
  {
    db_field: 'return_country',
    label: 'R/T Country',
    align: 'center',
    minWidth: 150,
  },
  {
    db_field: 'return_office',
    label: 'R/T Location',
    align: 'center',
    format: (value) => renderLocation(value),
    minWidth: 150,
  },
  {
    db_field: 'return_datetime',
    label: 'R/T Date&Time',
    align: 'center',
    format: (value) => renderDatetime(value),
    minWidth: 150,
  },
  {
    db_field: 'size_car',
    label: 'Car Size',
    align: 'center',
  },
  {
    db_field: 'insurance_name',
    label: 'Insurance Pkg.',
    align: 'center',
    format: (value) => renderInsuranceInfo(value),
    minWidth: 180,
  },
  {
    db_field: 'extra_equipmens',
    label: 'Optional Service',
    align: 'left',
    format: (value) => renderOptionalService(value),
    minWidth: 200,
  },
  {
    db_field: 'currency',
    label: 'Currency',
    align: 'center',
    format: (value) => renderCurrency(value),
  },
  //add column for PG amount
  {
    db_field: 'pg_amount',
    label: 'PG Amount',
    align: 'center',
    format: (value) => renderPGAmount(value),
    minWidth: 150,
  },
  {
    db_field: 'total_amount',
    label: 'Total Amount',
    align: 'center',
    format: (value) => value.toLocaleString(),
    minWidth: 150,
  },
  {
    db_field: 'resend_email',
    label: 'Resend email',
    align: 'center',
    format: (value) => renderResendEmail(value),
    minWidth: 120,
  },
  {
    db_field: 'cancel_reason',
    label: 'Reason for Cancellation',
    align: 'center',
    format: (value) => String(value || '-'),
    minWidth: 150,
    only_super_admin: true,
  },
  {
    db_field: 'resend_email',
    label: 'Resend kakaotalk',
    align: 'center',
    format: (value) => renderKakaoResend(value),
    minWidth: 120,
  },
];

export const Reservation = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterCounter, setFilterCounter] = useState(0);
  const [offset, setOffset] = useState(0);
  const LIMIT_ROWS = 50;

  const [condition, setCondition] = useState({
    company: '',
    keyword: '',
    displayDate: '',
    ISOStartDate: '',
    ISOEndDate: '',
    date: [
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        key: 'selection',
      },
    ],
  });
  useEffect(() => {
    fetchData(offset);
  }, []);

  const formatData = (data: any[]) => {
    data.forEach((item) => {
      item['korean_name'] = {
        korean_name: item.korean_name,
        first_name: item.first_name,
        last_name: item.last_name,
      };
      item['currency'] = {
        currency: item.currency,
        exchange: item.exchange_rate,
      };
      //map value for pg_amount
      item['pg_amount'] = {
        amount: item.rate_amount,
        type: item.pay_type,
      };
      //add pickup_office, return_office and contact_info field
      item['pickup_office'] = {
        name: item.pickup_loc_name,
        address: item.office_address,
        phone: item.office_phone,
      };
      item['return_office'] = {
        name: item.return_loc_name,
        address: item.office_second_address,
        phone: item.office_second_phone,
      };
      item['contact_info'] = {
        email: item.email,
        phoneCode: item.phone_code,
        phoneNumber: item.phone_number,
      };
      item['flight_info'] = {
        airline: item.flight_brand,
        flightNumber: item.flight_number,
        arrival: item.departure_date,
      };
      item['insurance_name'] = {
        insurance_name: item.insurance_name,
        insurances_detail: item.insurances_detail,
      };
      item['resend_email'] = {
        res_id: item.res_id,
        res_status: item.res_status,
        setLoading,
      };
    });
    return data;
  };

  const fetchData = async (offset: number) => {
    const res = await ReservationApi.getAll(offset, LIMIT_ROWS);
    if (res) {
      setData((prevState) => formatData(res.res));
      setTotalCount(res.res_num);
    }
    setLoading(false);
  };

  const onChange = (data: any) => {
    setCondition(data);
  };

  const handleFilter = async () => {
    setOffset(0);
    if (!condition?.company && !condition?.keyword && !condition?.ISOEndDate) {
      setLoading(true);
      setFilterCounter(filterCounter + 1);
      await fetchData(0);
      setLoading(false);
      return;
    }
    setFilterCounter(filterCounter + 1);
    setLoading(true);
    const result = await ReservationApi.filter(
      condition.company,
      { startDate: condition.ISOStartDate, endDate: condition.ISOEndDate },
      condition.keyword,
      0,
      LIMIT_ROWS,
    );
    setData((prevState) => formatData(result.res));
    setTotalCount(result.res_num);
    setLoading(false);
  };

  const handleChangePage = async (page: number, rowsPerPage: number) => {
    if ((page + 1) * rowsPerPage > data.length && offset < totalCount) {
      setLoading(true);
      await fetchMore(data.length);
      setLoading(false);
    }
  };

  const handleRefresh = async (start_date: string, end_date: string) => {
    if (!condition?.ISOStartDate || !condition?.ISOEndDate) {
      await Toast.fire({
        title: '날짜를 선택해주세요.',
        icon: 'error',
      });
      return;
    }
    if (
      new Date(condition?.ISOEndDate).getDate() -
        new Date(condition?.ISOStartDate).getDate() +
        1 >
      7
    ) {
      await Toast.fire({
        title: '7일 이내의 기간만 선택 가능합니다.',
        icon: 'error',
      });
      return;
    }
    try {
      Swal.fire({
        title: '예약 현황을 업데이트 중입니다...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const response = await ReservationApi.refresh(start_date, end_date);
      if (response.statusCode !== 200) {
        throw new Error(
          `statusCode: ${response.statusCode}\nmessage: ${
            response.error || ''
          } ${response.message}`,
        );
      }
      Swal.close();
      Toast.fire({
        title: '예약 현황이 업데이트 되었습니다.',
        icon: 'info',
      });
    } catch (e: any) {
      Swal.fire({
        title: '예약 현황을 업데이트 하지 못했습니다.',
        text: e.message,
        icon: 'error',
      });
    } finally {
      setLoading(true);
      await fetchData(0);
    }
  };

  const fetchMore = async (offset: number) => {
    let res: { res_num: number; res: any[] } = { res_num: 0, res: [] };
    if (condition?.company || condition?.keyword || condition?.ISOEndDate) {
      res = await ReservationApi.filter(
        condition.company,
        { startDate: condition.ISOStartDate, endDate: condition.ISOEndDate },
        condition.keyword,
        offset,
        LIMIT_ROWS,
      );
    } else {
      res = await ReservationApi.getAll(offset, LIMIT_ROWS);
    }
    if (res) {
      setData(data.concat(formatData(res.res)));
      setTotalCount(res.res_num);
      setOffset(data.length + res.res.length);
    }
  };

  const {
    state: { agency_id },
  } = useContext(UserContext);

  const excelExport = async () => {
    Toast.fire({
      title: 'The data is extracting...',
      icon: 'info',
    });

    const response = await ReservationApi.download(
      condition.company,
      { startDate: condition.ISOStartDate, endDate: condition.ISOEndDate },
      condition.keyword,
      agency_id,
    );

    // @ts-ignore
    const blob = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const objectUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = objectUrl;
    a.download = `Reservation-${format(new Date(), 'yy-MM-dd')}.xlsx`;
    a.type = 'text/css';
    a.click();
    a.remove();
  };

  return (
    <Box sx={{ flex: 1, maxWidth: '100vw' }}>
      <PageHeader
        pageTitle="reservation"
        actionTitle="Export XLSX"
        onAction={excelExport}
      />
      <FilterBox
        condition={condition}
        onChange={onChange}
        onFilter={handleFilter}
        onRefresh={handleRefresh}
      />
      <DataTable
        loading={loading}
        columns={columns}
        data={data}
        total={totalCount}
        onResetPage={filterCounter}
        onChangePage={handleChangePage}
        isRole={true}
      />
    </Box>
  );
};
