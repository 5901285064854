export interface ISetting{
    category: string;
    name: string;
    value?: string;
    type: 'text' | 'bool' | 'image' | 'password';
}

export interface IErrorMessage{
  error_code: string;
  english_message: string;
  korean_message: string;
}

export enum EPermission {
  RESERVATION = 1,
  CLIENT = 2,
  PAYMENT = 3,
  SYSTEM = 4,
  USER = 5,
}
