import { ActionType, IAction } from '../../constants';

export const UserReducer = (state = null, action: IAction) => {
  switch (action.type) {
  case ActionType.SET_USER:
    return action.payload;
  default:
    return null;
  }
}
