import { ActionType, IAction, REFRESH_TOKEN_NAME, TOKEN_NAME } from '../../constants';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const SignedInReducer = (state = false, action: IAction) => {
  switch (action.type) {
  case ActionType.SIGNED_IN:
    // set access token in cookies
    cookies.set(TOKEN_NAME, action.payload.accessToken, { path: '/' });
    // set refresh token
    cookies.set(REFRESH_TOKEN_NAME, action.payload.refreshToken);
    return true;
  case ActionType.SIGNED_OUT:
    // remove access token in cookies
    cookies.remove(TOKEN_NAME);
    // remove refresh token local storage
    cookies.remove(REFRESH_TOKEN_NAME);
    return false;
  default:
    return false;
  }
}
