import { Chip, Stack } from '@mui/material';
import { IColumn } from '../../../constants';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ClickTooltip, CustomTooltip } from '../../../components/CustomTooltip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import DatePicker from '../../../../src/components/DatePicker';
import { format } from 'date-fns';
import { PaymentAPI } from '../../../api';
import { Toast } from '../../../components/Toast';
import { ReloadContext } from '../../../context/reload.context';
import { SupplierOptions } from '^/features/reservation/constant';
export interface IHeaderCell {
  // db_field: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: any) => any;
  // This part is add by Ha
  colspan?: number;
  rowspan?: number;
  only_super_admin?: boolean;
  // horizontalAlign?: boolean;
}

export interface IHeaderRow {
  cells: IHeaderCell[];
}

export interface IHeaderRows {
  rows: IHeaderRow[];
}

export const FakeDataPayment: IHeaderRows = {
  rows: [
    {
      cells: [],
    },
  ],
};

const renderReservationStatus = (status: string) => {
  if (status.toLowerCase() == 'booked')
    return <Chip label={status} color={'success'} />;
  else if (status.toLowerCase() === 'canceled')
    return <Chip label={status} color={'error'} />;
  else if (status.toLowerCase() === 'refunded')
    return <Chip label={status} color={'default'} />;
  else return <></>;
};

const renderPayType = (type: string) => {
  if (type == 'PPD') return <Chip label={type} color={'info'} />;
  else if (type === 'POD') return <Chip label={type} color={'default'} />;
};

const renderKoreanName = (value: {
  korean_name: string;
  last_name: string;
  first_name: string;
}) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {value.korean_name}
      </Stack>
      <CustomTooltip
        content={
          <Fragment>
            <Stack sx={{ padding: '5px' }} direction={'column'}>
              <div>
                <b>First Name (EN): </b> <span>{value.first_name}</span>
              </div>
              <div>
                <b>Last Name (EN): </b> <span>{value.last_name}</span>
              </div>
            </Stack>
          </Fragment>
        }
      />
    </Stack>
  );
};

const renderCurrency = (value: { currency: string; exchange: number }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {value.currency}
      </Stack>
      <CustomTooltip
        content={
          <Fragment>
            <Stack sx={{ padding: '5px' }} direction={'column'}>
              <div>
                <b>Exchange Rate: </b>{' '}
                <span>{value.exchange ? value.exchange : 1}</span>
              </div>
            </Stack>
          </Fragment>
        }
      />
    </Stack>
  );
};

const renderServiceAmount = (value: {
  service_total_amount: number;
  service_rate: number;
  etc_rate: number;
}) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {(Math.round(value.service_total_amount * 100) / 100).toLocaleString()}
      </Stack>
      <CustomTooltip
        content={
          <Fragment>
            <Stack sx={{ padding: '5px' }} direction={'column'}>
              <div>
                <b>Service Amount: </b> <span>{value.service_rate.toLocaleString()}</span>
              </div>
              <div>
                <b>Taxes: </b> <span>{value.etc_rate.toLocaleString()}</span>
              </div>
            </Stack>
          </Fragment>
        }
      />
    </Stack>
  );
};

const PaidSupplierField = (props: {
  order_id: string;
  originalValue: string;
}) => {
  const {
    dispatch: { setReload },
  } = useContext(ReloadContext);
  const [value, setValue] = useState(
    props.originalValue ? props.originalValue : '',
  );
  const [open, setOpen] = useState(false);
  const handleUpdate = async (event: any) => {
    event.preventDefault();
    try {
      await PaymentAPI.update(props.order_id, {
        paid_supplier: Number.parseFloat(value),
      });
      setOpen(false);
      setReload();
      await Toast.fire({
        icon: 'success',
        title: 'Update successful',
      });
    } catch (e: any) {
      setOpen(false);
      await Toast.fire({
        icon: 'error',
        title: e.message,
      });
    }
  };
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {props.originalValue}
      </Stack>
      <ClickTooltip
        open={open}
        setOpen={setOpen}
        content={
          <Fragment>
            <Stack sx={{ padding: '5px' }} direction={'column'} spacing={2}>
              <TextField
                type="number"
                variant="outlined"
                fullWidth
                size="small"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <Stack direction="row" spacing={2} justifyContent="center">
                <IconButton onClick={() => setOpen(false)}>
                  <ClearIcon fontSize="small" />
                </IconButton>
                <IconButton disabled={!value} onClick={handleUpdate}>
                  <CheckIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          </Fragment>
        }
      />
    </Stack>
  );
};
//add component for rendering pg amount
const renderPGAmount = (value: { amount: number; type: string }) => {
  if (value.type == 'PPD') return <span>{value.amount.toLocaleString()}</span>;
  else if (value.type === 'POD') return <span>-</span>;
};

const DateField = (props: {
  order_id: string;
  originalValue: string;
  field_name: string;
}) => {
  const {
    dispatch: { setReload },
  } = useContext(ReloadContext);
  const [value, setValue] = useState(() =>
    props.originalValue ? new Date(props.originalValue) : new Date(),
  );
  const [open, setOpen] = useState(false);
  const handleUpdate = async () => {
    try {
      await PaymentAPI.update(props.order_id, { [props.field_name]: value });
      setOpen(false);
      setReload();
      await Toast.fire({
        icon: 'success',
        title: 'Update successful',
      });
    } catch (e: any) {
      setOpen(false);
      await Toast.fire({
        icon: 'error',
        title: e.message,
      });
    }
  };
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" justifyContent="center">
        {props.originalValue
          ? format(new Date(props.originalValue), 'yy-MM-dd')
          : ''}
      </Stack>
      <ClickTooltip
        open={open}
        setOpen={setOpen}
        content={
          <Fragment>
            <Stack
              sx={{ padding: '5px', width: '200px' }}
              direction={'column'}
              spacing={2}
            >
              <DatePicker value={value} setValue={setValue} />
              <Stack direction="row" spacing={2} justifyContent="center">
                <IconButton onClick={() => setOpen(false)}>
                  <ClearIcon fontSize="small" />
                </IconButton>
                <IconButton disabled={!value} onClick={handleUpdate}>
                  <CheckIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          </Fragment>
        }
      />
    </Stack>
  );
};

export const headerColumnRows: IHeaderCell[][] = [
  [
    { label: 'No', rowspan: 1 },
    { label: 'Client Name', rowspan: 1, minWidth: 100, align: 'center' },
    { label: 'Client Number', rowspan: 1, minWidth: 70, align: 'center' },
    { label: 'Res Status', rowspan: 1, minWidth: 70, align: 'center' },
    { label: 'Supplier', rowspan: 1, minWidth: 70, align: 'center' },
    { label: 'Order Number', rowspan: 1, minWidth: 70, align: 'center' },
    { label: 'Res ID', rowspan: 1, minWidth: 70, align: 'center' },
    { label: 'Korean Name', rowspan: 1, minWidth: 100, align: 'center' },
    { label: 'PP/POD', rowspan: 1, minWidth: 100, align: 'center' },
    { label: 'Total Amount', colspan: 1, minWidth: 70, align: 'center' },
    //add header title for PG amount
    { label: 'PG Amount', colspan: 1, minWidth: 70, align: 'center' },
    { label: 'Currency', colspan: 1, minWidth: 70, align: 'center' },
    { label: 'Basic Rate', rowspan: 1, minWidth: 70, align: 'center' },
    {
      label: 'Net',
      rowspan: 1,
      minWidth: 70,
      align: 'center',
      only_super_admin: true,
    },
    {
      label: 'Comm',
      rowspan: 1,
      minWidth: 70,
      align: 'center',
      only_super_admin: true,
    },
    { label: 'OP Total Amount', rowspan: 1, minWidth: 120, align: 'center' },
    {
      label: 'Paid to/from Supplier',
      rowspan: 1,
      minWidth: 150,
      align: 'center',
      only_super_admin: true,
    },
    {
      label: 'Date',
      rowspan: 1,
      minWidth: 150,
      align: 'center',
      only_super_admin: true,
    },
    { label: 'Paid to Client', rowspan: 1, minWidth: 150, align: 'center' },
    { label: 'Date', rowspan: 1, minWidth: 150, align: 'center' },
    {
      label: 'IMS Commission',
      rowspan: 1,
      minWidth: 70,
      align: 'center',
      only_super_admin: true,
    },
    {
      label: '정식 선불 여부',
      rowspan: 1,
      minWidth: 100,
      align: 'center',
      only_super_admin: true,
    },
  ],
];

export const paymentColumns: IColumn[] = [
  { db_field: 'no', label: 'No.', minWidth: 70 },
  {
    db_field: 'agency_name',
    label: 'Agency Name',
    align: 'center',
  },
  {
    db_field: 'agency_id',
    label: 'Agency#',
    align: 'center',
  },
  {
    db_field: 'reservation_status',
    label: 'Res Status',
    format: renderReservationStatus,
    align: 'center',
  },
  {
    db_field: 'supplier',
    label: 'Supplier',
    align: 'center',
    format: (value) => SupplierOptions[value],
    minWidth: 120,
  },
  {
    db_field: 'order_id',
    label: 'Order#',
    align: 'center',
  },
  {
    db_field: 'reservation_id',
    label: 'Res ID',
    align: 'center',
  },
  {
    db_field: 'korean_name',
    label: 'Korean Name',
    align: 'center',
    format: (value) => renderKoreanName(value),
  },
  {
    db_field: 'pay_type',
    label: 'PP/POD',
    format: (value) => renderPayType(value),
    align: 'center',
  },
  {
    db_field: 'total_amount',
    label: 'Total Amount',
    align: 'center',
  },
  //add comlumn PG amount
  {
    db_field: 'payment_gateway_amount',
    label: 'PG Amount',
    align: 'center',
    format: (value) => renderPGAmount(value),
  },
  {
    db_field: 'currency',
    label: 'Currency',
    align: 'center',
    format: (value) => renderCurrency(value),
  },
  {
    db_field: 'basic_rate',
    label: 'Basic rate',
    align: 'center',
  },
  {
    db_field: 'net',
    label: 'Net',
    align: 'center',
    only_super_admin: true,
  },
  {
    db_field: 'commission',
    label: 'Comm',
    align: 'center',
    only_super_admin: true,
  },
  {
    db_field: 'service_total_amount',
    label: 'OP Total Amount',
    align: 'center',
    format: (value) => renderServiceAmount(value),
  },
  {
    db_field: 'paid_supplier',
    label: 'Paid to/from supplier',
    align: 'center',
    minWidth: 200,
    format: (value) => (
      <PaidSupplierField
        order_id={value.id}
        originalValue={value.paid_supplier}
      />
    ),
    only_super_admin: true,
  },
  {
    db_field: 'paid_supplier_date',
    label: 'Date',
    align: 'center',
    minWidth: 250,
    only_super_admin: true,
    format: (value) => (
      <DateField
        order_id={value.id}
        originalValue={value.paid_supplier_date}
        field_name={'paid_supplier_date'}
      />
    ),
  },
  {
    db_field: 'paid_agency',
    label: 'Paid to Client',
    align: 'right',
  },
  {
    db_field: 'paid_agency_date',
    label: 'Date',
    align: 'center',
    minWidth: 200,
    format: (value) => (
      <DateField
        order_id={value.id}
        originalValue={value.paid_agency_date}
        field_name={'paid_agency_date'}
      />
    ),
  },
  {
    db_field: 'IMS_commission',
    label: 'IMS comm',
    align: 'right',
    only_super_admin: true,
    format: (value: number | null) => (value?.toLocaleString()) || '-'
  },
  {
    db_field: 'is_official_prepaid',
    label: '정식 선불 여부',
    align: 'center',
    only_super_admin: true,
    format: (value: number | null) => {
      if(value == 0 || value == 1) {
        return value
      }
      return '-'
    }
  },
];
