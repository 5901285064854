import React, { useContext, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { AdminStatusLabel, AdminUserStatus, IOptionItem } from '../../../../constants';
import { LoadingButton } from '@mui/lab';
import { AdminRoleApi, AdminUserApi, ClientApi } from '../../../../api';
import { Toast } from '../../../../components/Toast';
import { LangContext } from '../../../../context/language.context';
import { DropdownSearch } from '../../../../components/DropdownSearch';
import { useQuery } from 'react-query';

interface Props {
    open: boolean;
    onClose: () => void;
}

export const CreateUserDialog = (props: Props): JSX.Element => {
  const { open, onClose } = props;
  const { dispatch: { translate } } = useContext(LangContext);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPW, setConfirmPW] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneMobile, setPhoneMobile] = useState('');
  const [userRoles, setUserRoles] = useState<IOptionItem[]>([]);
  const [status, setStatus] = useState('ACTIVE');
  const [client, setClient] = useState('');
  const [errMsg, setErrMsg] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [rolesOptions, setRolesOptions] = useState<IOptionItem[]>([]);
  const { data: roles } = useQuery<any[], Error>(
    ['getRoles'],
    AdminRoleApi.getAll,
  );

  useEffect(()=> {
    if (roles){
      const options: IOptionItem[] = [];
      roles.forEach((r)=> {
        options.push({
          value: r.id,
          label: r.name,
        })
      });
      setRolesOptions(options);
    }
  }, [roles])

  const [clientOptions, setClientOption] = useState<IOptionItem[]>([]);
  const { data: clients } = useQuery<any[], Error>(
    ['getClients'],
    ClientApi.list,
  );
  useEffect(()=> {
    if (clients){
      const options: IOptionItem[] = [];
      clients.forEach((r)=> {
        options.push({
          value: r.id,
          label: r.name,
        })
      });
      setClientOption(options);
    }
  }, [clients])

  const validateFields = () => {
    let validForm = true;
    if (!username) {
      setErrMsg(prevState => {
        return { ...prevState, username: 'Required' }
      })
      validForm = false;
    }
    if (!email) {
      setErrMsg(prevState => {
        return { ...prevState, email: 'Required' }
      })
      validForm = false;
    }
    if (!email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )){
      setErrMsg(prevState => {
        return { ...prevState, email: 'Please enter valid email' }
      })
      validForm = false;
    }
    if (!password) {
      setErrMsg(prevState => {
        return { ...prevState, password: 'Required' }
      })
      validForm = false;
    }
    if (password.length < 8) {
      setErrMsg(prevState => {
        return { ...prevState, password: 'Password must contain at least 8 characters' }
      })
      validForm = false;
    }
    if (!confirmPW) {
      setErrMsg(prevState => {
        return { ...prevState, confirmPassword: 'Passwor does not match' }
      })
      validForm = false;
    }
    if (password !== confirmPW){
      setErrMsg(prevState => {
        return { ...prevState, confirmPassword: 'Password does not match' }
      })
      validForm = false;
    }
    return validForm;
  }

  const handleSubmit = () => {
    const valid = validateFields();
    if (!valid) {
      return;
    }
    setLoading(true);
    const client_id = client ? client : null;
    const roles = userRoles.map((role)=> (Number.parseInt(role.value)));
    AdminUserApi.create(username, password, email, lastName, firstName, phoneMobile,address, status, client_id, roles)
      .then( async (res)=> {
        setLoading(false);
        handleClose();
        await Toast.fire({
          icon: 'success',
          title: 'Created'
        })
      })
      .catch(async (err)=> {
        setLoading(false);
        handleClose();
        await Toast.fire({
          icon: 'error',
          title: err.message
        })
      })
  }

  const handleClose = () => {
    onClose();
    setErrMsg({
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
    setUsername('');
    setEmail('');
    setPassword('');
    setConfirmPW('');
    setFirstName('');
    setLastName('');
    setAddress('');
    setPhoneMobile('');
    setStatus('ACTIVE');
    setClient('');
    setUserRoles([]);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{translate('newUser')}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Username"
          required
          type="text"
          fullWidth
          size="small"
          error={errMsg.username !== ''}
          helperText={errMsg.username}
          value={username}
          onChange={($event) => {
            setUsername($event.target.value);
            setErrMsg(prevState => {
              return { ...prevState, username: '' }
            })
          }}
        />
        <TextField
          margin="dense"
          label="Email"
          type="email"
          required
          fullWidth
          size="small"
          value={email}
          error={errMsg.email !== ''}
          helperText={errMsg.email}
          onChange={($event) => {
            setEmail($event.target.value);
            setErrMsg(prevState => {
              return { ...prevState, email: '' }
            })
          }}
        />
        <TextField
          margin="dense"
          label="Password"
          required
          type="password"
          fullWidth
          size="small"
          error={errMsg.password !== ''}
          helperText={errMsg.password}
          value={password}
          onChange={($event) => {
            setPassword($event.target.value);
            setErrMsg(prevState => {
              return { ...prevState, password: '' }
            })
          }}
        />
        <TextField
          margin="dense"
          label="Confirm password"
          required
          type="password"
          fullWidth
          size="small"
          value={confirmPW}
          error={errMsg.confirmPassword !== ''}
          helperText={errMsg.confirmPassword}
          onChange={($event) => {
            setConfirmPW($event.target.value);
            setErrMsg(prevState => {
              return { ...prevState, confirmPassword: '' }
            })
          }}
        />
        <TextField
          margin="dense"
          label="First name"
          type="text"
          fullWidth
          size="small"
          value={firstName}
          onChange={($event) => {
            setFirstName($event.target.value)
          }}
        />
        <TextField
          margin="dense"
          label="Last name"
          type="text"
          fullWidth
          size="small"
          value={lastName}
          onChange={($event) => {
            setLastName($event.target.value)
          }}
        />
        <TextField
          margin="dense"
          label="Phone"
          type="text"
          fullWidth
          size="small"
          value={phoneMobile}
          onChange={($event) => {
            setPhoneMobile($event.target.value)
          }}
        />
        <TextField
          margin="dense"
          label="Address"
          type="text"
          fullWidth
          size="small"
          value={address}
          onChange={($event) => {
            setAddress($event.target.value)
          }}
        />
        <FormControl size="small" fullWidth style={{ marginTop: '1rem' }}>
          <DropdownSearch
            placeholder='Select Client'
            value={client}
            options={clientOptions}
            isMulti={false}
            onSelect={(e) => {
              setClient(e.value);
            }}
          />
        </FormControl>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={1}>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <DropdownSearch
                placeholder='Select Role'
                value={userRoles}
                options={rolesOptions}
                onSelect={(e) => {
                  setUserRoles(e);
                }}
                isMulti
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel >Status</InputLabel>
              <Select
                label="Status"
                defaultValue={status}
                onChange={(event)=> {
                  setStatus(event.target.value);
                }}
              >
                {
                  AdminUserStatus.map((item) => (
                    <MenuItem key={item} value={item}>{AdminStatusLabel[item]}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={handleClose}>Cancel</LoadingButton>
        <LoadingButton loading={loading} onClick={handleSubmit} variant="outlined">Submit</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
