import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { DropdownSearch } from '../../../../components/DropdownSearch';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React, { useContext, useEffect, useState } from 'react';
import { LocationsApi } from '../../../../api';
import { IOptionItem } from '../../../../constants';
import { CategoriesApi } from '../../../../api/categories.api';
import { Toast } from '../../../../components/Toast';
import { LangContext } from '../../../../context/language.context';

interface Props {
    open: boolean;
    category: {name: string; id: number};
    onClose: () => void;
    onSuccess?: () => void;
}

export const SelectLocationDialog = (props: Props) => {
  const { open,  category, onClose, onSuccess } = props;
  const { dispatch: { translate } } = useContext(LangContext);
  const [locationsOption, setLocationsOption] = useState<IOptionItem[]>([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchOptionsData();
  }, [])

  const fetchOptionsData = async () => {
    setLoading(true);

    const locResult = await LocationsApi.getLocations();
    const options: IOptionItem[] = [];
    locResult.forEach((value) => {
      if (value.location_description.length > 0) {
        options.push({
          value: value.id,
          label: value.location_description[0].description
        })
      }
    });
    setLocationsOption(options);

    setLoading(false);
  }

  const handleAddLocation = () => {
    setLoading(true);
    const locationsId = locations.map((item: any)=> item.value);
    CategoriesApi.addLocations(category.id, locationsId)
      .then( async (res)=> {
        setLoading(false);
        if (onSuccess) {
          onSuccess();
        }
        onClose();
        setLocations([]);
        await Toast.fire({
          title: 'Success.',
          icon: 'success'
        });
      })
      .catch( async (err)=> {
        setLoading(false);
        await Toast.fire({
          title: err.message,
          icon: 'error'
        });
      })
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{translate('select')+' ' + translate('location')}</DialogTitle>
      <DialogContent sx={{ width: '35rem' }}>
        <Typography variant='subtitle1'>{translate('location')}</Typography>
        <DropdownSearch
          placeholder='Required'
          options={locationsOption}
          value={locations}
          isMulti={true}
          isLoading={loading}
          onSelect={(newValue) => {setLocations(newValue)}}/>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>{translate('cancel')}</Button>
        <Button variant='contained' disabled={loading} onClick={handleAddLocation}>{translate('submit')}</Button>
      </DialogActions>
    </Dialog>
  );
}
