import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CountriesApi } from '../../../../api';
import { CONTINENTS, ICountry } from '../../../../constants';
import { Toast } from '../../../../components/Toast';
import { FormControl } from '@mui/material';
import { DropdownSearch } from '../../../../components/DropdownSearch';
import Typography from '@mui/material/Typography';
import { LangContext } from '../../../../context/language.context';

interface Props {
    open: boolean;
    onClose: () => void;
}

export const AddCountryDialog = (props: Props): JSX.Element => {
  const { open, onClose } = props;
  const { dispatch: { translate } } = useContext(LangContext);
  const [name, setName] = useState('');
  const [code , setCode] = useState('');
  const [code3, setCode3] = useState('');
  const [continent, setContinent] = useState('');
  const [currency, setCurrency] = useState('');
  const [loading, setLoading] = useState(false);

  const resetData = () => {
    setName('');
    setCode('');
    setCode3('');
    setContinent('');
    setCurrency('');
  }

  const handleSubmit = async () => {
    if (!code || !name || !code3){
      await Toast.fire({
        title: 'Required fields must be filled out.',
        icon: 'warning'
      });
      return;
    }
    setLoading(true);
    const data: ICountry= {
      code: code,
      name: name,
      code_3: code3,
      currency: currency,
      continent: continent
    }
    CountriesApi.addCountry(data)
      .then( async (res)=> {
        await Toast.fire({
          title: 'Added.',
          icon: 'success'
        });
        setLoading(false);
        resetData();
        onClose();
      })
      .catch( async (err)=>{
        await Toast.fire({
          title: err.message,
          icon: 'error'
        });
        setLoading(false);
      })
  }

  const handleCancel =() => {
    resetData();
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`${translate('add')} ${translate('country')}`}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          size='small'
          value={name}
          onChange={($event) => {
            setName($event.target.value)
          }}
        />
        <TextField
          margin="dense"
          label="Country code"
          type="text"
          fullWidth
          size='small'
          value={code}
          onChange={($event) => {
            setCode($event.target.value)
          }}
        />
        <TextField
          margin="dense"
          label="Country code 3"
          type="text"
          fullWidth
          size='small'
          value={code3}
          onChange={($event) => {
            setCode3($event.target.value)
          }}
        />
        <Typography variant='subtitle2'>Optional fields: </Typography>

        <FormControl fullWidth sx={{ mt: 1 }}>
          <DropdownSearch
            value={continent}
            placeholder={'Continent'} options={CONTINENTS}
            onSelect={(option)=> {setContinent(option.value)} } />
        </FormControl>
        <TextField
          margin="dense"
          label="Currency"
          type="text"
          size='small'
          fullWidth
          value={currency}
          onChange={($event) => {
            setCurrency($event.target.value)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleCancel}>Cancel</Button>
        <Button disabled={loading} onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}
