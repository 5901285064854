import React, { useReducer, FC, ReactNode, createContext } from 'react';
import { ActionType } from '../constants';
import { ReloadReducer } from '../state/reducers/reload.reducer';

interface ContextProps {
  state: number;
  dispatch: {
    setReload: () => void;
  }
}
export const ReloadContext = createContext({} as ContextProps);

const initialState = 0;

interface ReloadStateProps {
  children: ReactNode;
}
export const ReloadState: FC<ReloadStateProps> = ({ children }) => {
  const [state, dispatch] = useReducer(ReloadReducer, initialState);

  const setReload = () => {
    dispatch({
      type: ActionType.SET_RELOAD,
      payload: Math.floor(100000 + Math.random() * 900000),
    });
  }

  return(
    <ReloadContext.Provider value={{ state, dispatch: { setReload } }}>
      {children}
    </ReloadContext.Provider>
  );
}
