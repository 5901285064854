import { Box, Stack, styled } from '@mui/material';
import { PageHeader } from '../../../../components/PageHeader';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgess from '../../../../components/LinearProgess';
import { Toast } from '../../../../components/Toast';
import { LocationsApi } from '../../../../api';
import * as XLSX from 'xlsx';
const MAX_ROWS = 50;

const Input = styled('input')({
  display: 'none',
});
export const ImportLocation = () => {
  const [uploading, setUploading] = useState(false);
  // const [connectSSE, setConnectSSE] = useState(false);
  const [finished, setFinished] = useState(false);
  const [fileName, setFileName] = useState('');
  const [imported, setImported] = useState(0);
  const [total, setTotal] = useState(0);
  // const [failedRecords, setFailedRecords] = useState([]);
  const [percent, setPercent] = useState(0);
  const [items, setItems] = useState<any[]>([]);

  // useEffect(()=>{
  //   const source = new EventSource(`${process.env.REACT_APP_API_URL}/locations/import_sse`);
  //
  //   source.addEventListener('open', () => {
  //     console.log('SSE opened!');
  //     setConnectSSE(true);
  //   });
  //
  //   source.addEventListener('message', (e) => {
  //     const message = JSON.parse(e.data);
  //     if (message.status === 'end'){
  //       // sse
  //       source.close();
  //       setConnectSSE(false);
  //       // ui
  //       setTotal(message.total);
  //       setImported(message.imported);
  //       setFailedRecords(message.failed);
  //       setUploading(false);
  //       setFinished(true);
  //     }
  //   });
  //
  //   source.addEventListener('error', async (e) => {
  //     console.error('Error: ',  e);
  //     setConnectSSE(false);
  //     await Toast.fire({
  //       icon: 'error',
  //       title: 'There was an error. Please try again later.'
  //     })
  //   });
  //
  //   return () => {
  //     source.close();
  //   };
  // },[]);
  const readExcel = (file: any) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e: any) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d: any) => {
      setUploading(true);
      return setItems(d);
    });
  };

  const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const importActionV2=async ()=>{
    let records = items;
    let count = 0;
    setTotal(items.length);
    while (records.length > 0 ){
      const data = records.slice(0, MAX_ROWS);
      count += data.length;
      records = records.slice(MAX_ROWS)
      console.log('data', data);
      console.log('count', count);
      setPercent(Math.round(count/items.length * 100));
      const res : any = await LocationsApi.importLocationV2(data);
      console.log('res', res);
      if (res.success === true) {
        setImported(prevState => {
          return prevState + res.imported;
        })
      }
      await sleep(1000);
    }
    setUploading(false);
    setFinished(true);
  }

  useEffect(() => {
    if (uploading){
      importActionV2();
    }
  }, [uploading])

  const onChangeFile = async (e: any) => {
    if (!e.target.files[0]){
      await Toast.fire({
        icon: 'error',
        title: 'Please select file again.'
      });
      return;
    }
    setFileName(e.target.files[0].name);
    readExcel(e.target.files[0]);
    // LocationsApi.importLocation(e.target.files[0])
    //   .then((res)=> {
    //     console.log(res);
    //     setUploading(true);
    //   })
    //   .catch( async  (err)=> {
    //     await Toast.fire({
    //       icon: 'error',
    //       title: err.message
    //     });
    //   })
  }
  return (
    <Box sx={{ flex: 1 }}>
      <PageHeader pageTitle='Import location'/>
      <Stack>
        <label htmlFor="contained-button-file">
          <Input disabled={uploading} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            id="contained-button-file" type="file" onChange={onChangeFile}/>
          <Button sx={{ m: 0 }} variant="outlined" component="span" size='small'>
            Upload File
          </Button>
        </label>
        <Typography variant="body2" display="block" gutterBottom>
          {fileName && !finished ? fileName + ' importing...' :  'Please only choose .xlsx or .csv file'}
        </Typography>
        {
          uploading ? <>
            <LinearProgess/>
            <Typography variant="body2" gutterBottom component="div">
              {percent} %
            </Typography>
            <Typography variant="body2" gutterBottom color="red">
              During data import, please do not leave this page.
            </Typography>
          </> : <></>
        }
        {
          finished ?
            <>
              <Typography variant="body2" gutterBottom component="div" color="green">
                Imported
              </Typography>
              <Typography variant="body2" gutterBottom component="div" color="green">
                Total: {total}
              </Typography>
              <Typography variant="body2" gutterBottom component="div" color="green">
                Imported: {imported}
              </Typography>
              {/*<Typography variant="body2" gutterBottom color="red">*/}
              {/*  Imported failed records:*/}
              {/*  {failedRecords.map((value)=> (*/}
              {/*    <Typography key={value}  variant="caption" display="block" gutterBottom>*/}
              {/*      {value}*/}
              {/*    </Typography>*/}
              {/*  ))}*/}
              {/*</Typography>*/}
            </>
            :
            <></>
        }
      </Stack>
    </Box>
  )
}
