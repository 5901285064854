import { IColumn } from '../../../constants';

export const citiesColumns: IColumn[] = [
  { db_field: 'no', label: 'No.', minWidth: 70 },
  { db_field: 'name', label: 'Name', minWidth: 170 },
  {
    db_field: 'code',
    label: 'code',
    align: 'left',
  },
  {
    db_field: 'latitude',
    label: 'Latitude',
    align: 'left'
  },
  {
    db_field: 'longitude',
    label: 'Longitude',
    align: 'left'
  },
  {
    db_field: 'alt',
    label: 'Alt',
    align: 'left'
  },
  {
    db_field: 'timezone',
    label: 'Timezone',
    align: 'left'
  },
  {
    db_field: 'actions',
    label: '',
    minWidth: 50,
    align: 'right'
  }
];

export const countryTransColumns: IColumn[] = [
  { db_field: 'no', label: 'No.', minWidth: 70 },
  { db_field: 'locale', label: 'Locale', minWidth: 170 },
  {
    db_field: 'country_name',
    label: 'Translation',
    minWidth: 170,
    align: 'left',
  },
  {
    db_field: 'actions',
    label: '',
    minWidth: 50,
    align: 'right'
  }
];

export const cityTransColumns: IColumn[] = [
  { db_field: 'no', label: 'No.', minWidth: 70 },
  { db_field: 'locale', label: 'Locale', minWidth: 170 },
  {
    db_field:'city_name',
    label: 'Translation',
    minWidth: 170,
    align: 'left',
  },
];

export const airportColumns: IColumn[] = [
  { db_field: 'no', label: 'No.', minWidth: 70 },
  { db_field: 'iata_code', label: 'IATA Code', minWidth: 170 },
  {
    db_field: 'name',
    label: 'Name',
    align: 'left',
  },
  {
    db_field: 'icao_code',
    label: 'ICAO Code',
    align: 'left',
  },
  {
    db_field: 'latitude',
    label: 'Latitude',
    align: 'left',
  },
  {
    db_field: 'longitude',
    label: 'Longitude',
    align: 'left',
  },
  {
    db_field: 'actions',
    label: '',
    align: 'right'
  }
];

export const countryColumns:  IColumn[] = [
  { db_field: 'no', label: 'No.', minWidth: 70 },
  { db_field: 'name', label: 'Name', minWidth: 170 },
  {
    db_field: 'code',
    label: 'Country code',
    align: 'left',
  },
  {
    db_field: 'code_3',
    label: 'Country code3',
    align: 'left'
  },
  {
    db_field: 'continent',
    label: 'Continent',
    align: 'left'
  },
  {
    db_field: 'currency',
    label: 'Currency',
    align: 'left'
  },
  {
    db_field: 'actions',
    label: '',
    minWidth: 50,
    align: 'right'
  }
];

export const locationColumns: IColumn[] = [
  { db_field: 'no', label: 'No.', minWidth: 70 },
  { db_field: 'oag_code', label: 'OAG code', minWidth: 70 },
  { db_field: 'description', label: 'Name', minWidth: 170 },
  {
    db_field: 'city_code',
    label: 'City',
    align: 'left',
  },
  {
    db_field: 'vendor_id',
    label: 'Vendor',
    align: 'left'
  },
  {
    db_field: 'state',
    label: 'State',
    align: 'left'
  },
  {
    db_field: 'actions',
    label: '',
    minWidth: 50,
    align: 'right'
  }
];

export const locationCategoryColumns: IColumn[] = [
  { db_field: 'no', label: 'No.', minWidth: 70 },
  { db_field: 'description', label: 'Name', minWidth: 170 },
  {
    db_field: 'category_name',
    label: 'Category',
    align: 'left'
  },
  {
    db_field: 'city_name',
    label: 'City',
    align: 'left',
  },
  {
    db_field: 'country_name',
    label: 'Country',
    align: 'left'
  },
  {
    db_field: 'vendor_name',
    label: 'Vendor',
    align: 'left'
  },
  {
    db_field: 'actions',
    label: '',
    minWidth: 50,
    align: 'right'
  }
];
