import React from 'react';
import { default as DP } from 'react-date-picker';
import './style.css';
const DatePicker = ({ value, setValue, ...rest }: {value: Date,  setValue: (value: Date) => void}) => {
  return (
    <div>
      <DP onChange={setValue} value={value} format={'y-MM-dd'} className='date-picker' {...rest}/>
    </div>
  );
}

export default DatePicker;