import { BackdropLoading } from '../../../../components/BackdropLoading';
import Box from '@mui/material/Box';
import { Grid, Paper, Stack, TextField } from '@mui/material';
import ActiveLastBreadcrumbs from '../../../../components/Breadcrumbs';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { IBreadcrumb, RoutePath } from '../../../../constants';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Toast } from '../../../../components/Toast';
import { ClientApi } from '../../../../api';
import { LoadingButton } from '@mui/lab';
import { format } from 'date-fns';

const breadcrumbs: IBreadcrumb[] = [
  { name: 'Clients', href: RoutePath.CLIENTS },
  { name: 'Client', href: '#' }
]
export const ClientDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<any>();
  const [draftData, setDraftData] = useState<any>();
  useEffect(()=> {
    fetchData();
  },[])
  useEffect(()=> {
    setDraftData(data);
  },[data])
  const fetchData = async () => {
    const res = await ClientApi.getOne(id);
    setData(res);
    setDraftData(res);
    setLoading(false);
  }
  const cancelEditMode = () => {
    setEditMode(false);
    setDraftData(data);
  }

  const readModeButton = () => {
    return (
      <Button variant='contained' onClick={() => setEditMode(true)}>Edit</Button>
    )
  }
  const editModeButtons = () => {
    return (
      <Stack direction='row' spacing={1}>
        <LoadingButton loading={saving} variant='outlined' onClick={cancelEditMode}>Cancel</LoadingButton>
        <LoadingButton loading={saving} variant='contained' onClick={handleSave}>Save</LoadingButton>
      </Stack>
    )
  }

  const handleSave = async () => {
    setSaving(true);
    try{
      const res = await ClientApi.update(id, draftData.name);
      setSaving(false);
      setData(res);
      setEditMode(false);
      await Toast.fire({
        icon: 'success',
        title: 'Update successful'
      })
    }catch (e: any) {
      setSaving(false);
      await Toast.fire({
        icon: 'error',
        title: e.message
      })
    }
  }

  if (loading){
    return <span>Loading...</span>
  }
  return (
    <>
      <Box sx={{ flex: 1 }}>
        <Stack direction='row' justifyContent='space-between'>
          <Stack justifyContent='center'>
            <ActiveLastBreadcrumbs items={breadcrumbs}/>
          </Stack>

          <Stack justifyContent='center'>
            {
              editMode ? editModeButtons() : readModeButton()
            }
          </Stack>
        </Stack>

        <Paper sx={{ width: '100%', p: 2, mt: 3 }}>
          <Typography variant="h4" gutterBottom component="div">
                General
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={4}>
              <b>Client Number: </b> {data.id}
            </Grid>
            <Grid item xs={4}>
              <b>Client Name: </b> {editMode ?
                <div>
                  <TextField
                    sx={{ width: '100%' }}
                    value={draftData.name}
                    size="small"
                    type='text'
                    variant="outlined"
                    placeholder='Required'
                    onChange={(e) => {
                      setDraftData((prev: any) => {
                        return {
                          ...prev, name: e.target.value
                        }
                      })
                    }}
                  />
                </div> : data.name}
            </Grid>
            <Grid item xs={4}>
              <b>Created Date: </b> {format(new Date(data.created_date), 'PPpp')}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  )
}

