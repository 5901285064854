import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { IBreadcrumb, ILocation, IOptionItem, RoutePath } from '../../../../constants';
import { Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { BackdropLoading } from '../../../../components/BackdropLoading';
import Box from '@mui/material/Box';
import ActiveLastBreadcrumbs from '../../../../components/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { CitiesApi, CountriesApi, LocationsApi } from '../../../../api';
import { DropdownSearch } from '../../../../components/DropdownSearch';
import { Toast } from '../../../../components/Toast';
import { useQuery } from 'react-query';

const breadcrumbs: IBreadcrumb[] = [
  { name: 'Locations', href: RoutePath.LOCATIONS },
  { name: 'Location', href: '#' }
]

const vendors = [
  { value: 'ZE', label: 'Hertz' },
  { value: 'ZT', label: 'Thrifty' },
  { value: 'ZR', label: 'Dollar' },
  { value: 'FF', label: 'Firefly' },
];

const categories = [
  { value: 'LAM', label: 'Train Station' },
  { value: 'LAP', label: 'Airport' },
  { value: 'LDT', label: 'Downtown/City' },
  { value: 'LHT', label: 'Hotel' },
]

export const LocationDetail = () => {
  const { id } = useParams();
  const [updateCounter, setUpdateCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('Loading...');
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<ILocation>({
    alt_phone: '',
    category_id: '',
    city_code: '',
    close_1_fri: '',
    close_1_mon: '',
    close_1_sat: '',
    close_1_sun: '',
    close_1_thu: '',
    close_1_tue: '',
    close_1_wed: '',
    close_2_fri: '',
    close_2_mon: '',
    close_2_sat: '',
    close_2_sun: '',
    close_2_thu: '',
    close_2_tue: '',
    close_2_wed: '',
    close_3_fri: '',
    close_3_mon: '',
    close_3_sat: '',
    close_3_sun: '',
    close_3_thu: '',
    close_3_tue: '',
    close_3_wed: '',
    country_code: '',
    email: '',
    fax: '',
    id: '',
    latitude: '',
    longitude: '',
    oag_code: '',
    open_1_fri: '',
    open_1_mon: '',
    open_1_sat: '',
    open_1_sun: '',
    open_1_thu: '',
    open_1_tue: '',
    open_1_wed: '',
    open_2_fri: '',
    open_2_mon: '',
    open_2_sat: '',
    open_2_sun: '',
    open_2_thu: '',
    open_2_tue: '',
    open_2_wed: '',
    open_3_fri: '',
    open_3_mon: '',
    open_3_sat: '',
    open_3_sun: '',
    open_3_thu: '',
    open_3_tue: '',
    open_3_wed: '',
    phone: '',
    state: '',
    telex: '',
    vendor_id: '',
    web_url: '',
    zip_code: '',
    cities_secondary: []
  });
  const [drafData, setDrafData] = useState<ILocation>({
    alt_phone: '',
    category_id: '',
    city_code: '',
    close_1_fri: '',
    close_1_mon: '',
    close_1_sat: '',
    close_1_sun: '',
    close_1_thu: '',
    close_1_tue: '',
    close_1_wed: '',
    close_2_fri: '',
    close_2_mon: '',
    close_2_sat: '',
    close_2_sun: '',
    close_2_thu: '',
    close_2_tue: '',
    close_2_wed: '',
    close_3_fri: '',
    close_3_mon: '',
    close_3_sat: '',
    close_3_sun: '',
    close_3_thu: '',
    close_3_tue: '',
    close_3_wed: '',
    country_code: '',
    email: '',
    fax: '',
    id: '',
    latitude: '',
    longitude: '',
    oag_code: '',
    open_1_fri: '',
    open_1_mon: '',
    open_1_sat: '',
    open_1_sun: '',
    open_1_thu: '',
    open_1_tue: '',
    open_1_wed: '',
    open_2_fri: '',
    open_2_mon: '',
    open_2_sat: '',
    open_2_sun: '',
    open_2_thu: '',
    open_2_tue: '',
    open_2_wed: '',
    open_3_fri: '',
    open_3_mon: '',
    open_3_sat: '',
    open_3_sun: '',
    open_3_thu: '',
    open_3_tue: '',
    open_3_wed: '',
    phone: '',
    state: '',
    telex: '',
    vendor_id: '',
    web_url: '',
    zip_code: '',
    cities_secondary: []
  });
  const [countries, setCountries] = useState<IOptionItem[]>([]);
  const [cities, setCities] = useState<IOptionItem[]>([]);

  const { data: citiesRes } = useQuery<any[], Error>(['getCitiesOption'], () => CitiesApi.getCities());
  useEffect(()=>{
    if (citiesRes){
      const options: IOptionItem[] = [];
      citiesRes.forEach((value) => {
        options.push({
          value: value.code,
          label: `${value.name} (${value.code})`
        })
      });
      setCities(options);
    }
  },[citiesRes])

  const { data: countriesRes } = useQuery<any[], Error>(['getCountriesOption'], () => CountriesApi.getCountries());
  useEffect(()=>{
    if (countriesRes){
      const options: IOptionItem[] = [];
      countriesRes.forEach((value) => {
        options.push({
          value: value.code,
          label: value.name
        })
      });
      setCountries(options);
    }
  },[countriesRes])

  useEffect(() => {
    fetchData();
  }, [updateCounter])

  const fetchData = async () => {
    try{
      const data = await LocationsApi.getLocation(id);
      setData(data);
      setDrafData(data);
      setLoading(false);
    }catch (e:any) {
      setMessage(e.message);
    }

  }

  const cancelEditMode = () => {
    setEditMode(false);
    // reset draf data
    setDrafData(data);
  }

  const editModeButtons = () => {
    return (
      <Stack direction='row' spacing={1}>
        <Button variant='outlined' onClick={cancelEditMode}>Cancel</Button>
        <Button variant='contained' onClick={handleSave}>Save</Button>
      </Stack>
    )
  }

  const readModeButton = () => {
    return (
      <Button variant='contained' onClick={() => setEditMode(true)}>Edit</Button>
    )
  }

  const subPanel = () => {
    return (
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">Open/Close time</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Monday</TableCell>
                    <TableCell align="right">Tuesday</TableCell>
                    <TableCell align="right">Wednesday</TableCell>
                    <TableCell align="right">Thursday</TableCell>
                    <TableCell align="right">Friday</TableCell>
                    <TableCell align="right">Saturday</TableCell>
                    <TableCell align="right">Sunday</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={'open 1'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="right">{data.open_1_mon + ' ' + data.close_1_mon}</TableCell>
                    <TableCell align="right">{data.open_1_tue + ' ' + data.close_1_tue}</TableCell>
                    <TableCell align="right">{data.open_1_wed + ' ' + data.close_1_wed}</TableCell>
                    <TableCell align="right">{data.open_1_thu + ' ' + data.close_1_thu}</TableCell>
                    <TableCell align="right">{data.open_1_fri + ' ' + data.close_1_fri}</TableCell>
                    <TableCell align="right">{data.open_1_sat + ' ' + data.close_1_sat}</TableCell>
                    <TableCell align="right">{data.open_1_sun + ' ' + data.close_1_sun}</TableCell>
                  </TableRow>
                  <TableRow
                    key={'open 2'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="right">{data.open_2_mon + ' ' + data.close_2_mon}</TableCell>
                    <TableCell align="right">{data.open_2_tue + ' ' + data.close_2_tue}</TableCell>
                    <TableCell align="right">{data.open_2_wed + ' ' + data.close_2_wed}</TableCell>
                    <TableCell align="right">{data.open_2_thu + ' ' + data.close_2_thu}</TableCell>
                    <TableCell align="right">{data.open_2_fri + ' ' + data.close_2_fri}</TableCell>
                    <TableCell align="right">{data.open_2_sat + ' ' + data.close_2_sat}</TableCell>
                    <TableCell align="right">{data.open_2_sun + ' ' + data.close_2_sun}</TableCell>
                  </TableRow>
                  <TableRow
                    key={'open 3'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="right">{data.open_3_mon + ' ' + data.close_3_mon}</TableCell>
                    <TableCell align="right">{data.open_3_tue + ' ' + data.close_3_tue}</TableCell>
                    <TableCell align="right">{data.open_3_wed + ' ' + data.close_3_wed}</TableCell>
                    <TableCell align="right">{data.open_3_thu + ' ' + data.close_3_thu}</TableCell>
                    <TableCell align="right">{data.open_3_fri + ' ' + data.close_3_fri}</TableCell>
                    <TableCell align="right">{data.open_3_sat + ' ' + data.close_3_sat}</TableCell>
                    <TableCell align="right">{data.open_3_sun + ' ' + data.close_3_sun}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }

  const handleSave = async () => {
    if ( !drafData.city_code) {
      await Toast.fire({
        title: 'Required fields must be filled out',
        icon: 'warning'
      });
      return;
    }

    setLoading(true);

    LocationsApi.updateLocation(drafData)
      .then(async (res) => {
        setEditMode(false);
        setUpdateCounter(old => old + 1);
        await Toast.fire({
          title: 'Updated',
          icon: 'success'
        });
      })
      .catch(async (err) => {
        await Toast.fire({
          title: err.message,
          icon: 'error'
        });
        setLoading(false);
      })
  }

  return (
    <>
      {
        loading ? <span>{message}</span>
          :
          <Box sx={{ flex: 1 }}>
            <Stack direction='row' justifyContent='space-between'>
              <Stack justifyContent='center'>
                <ActiveLastBreadcrumbs items={breadcrumbs}/>
              </Stack>

              <Stack justifyContent='center'>
                {
                  editMode ? editModeButtons() : readModeButton()
                }
              </Stack>
            </Stack>

            <Paper sx={{ width: '100%', p: 2, mt: 3 }}>
              <Typography variant="h4" gutterBottom component="div">
                                General
              </Typography>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4}>
                  <b>Name: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.description}
                        size="small"
                        disabled
                        variant="outlined"
                        placeholder='Required'
                        onChange={(e) => {
                          setDrafData(prev => {
                            return {
                              ...prev, description: e.target.value
                            }
                          })
                        }}
                      />
                    </div> :
                    data.description}
                </Grid>
                <Grid item xs={4}>
                  <b>Latitude: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.latitude}
                        size="small"
                        disabled
                        variant="outlined"
                        placeholder='Required'
                        type='number'
                        onChange={(e) => {
                          setDrafData(prev => {
                            return {
                              ...prev, latitude: e.target.value
                            }
                          })
                        }}
                      />
                    </div> :
                    data.latitude}
                </Grid>
                <Grid item xs={4}>
                  <b>Longitude: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.longitude}
                        size="small"
                        disabled
                        type='number'
                        variant="outlined"
                        placeholder='Required'
                        onChange={(e) => {
                          setDrafData(prev => {
                            return {
                              ...prev, longitude: e.target.value
                            }
                          })
                        }}
                      />
                    </div> : data.longitude}
                </Grid>
                <Grid item xs={4}>
                  <Stack direction='column' spacing={1}>
                    <div>
                      <b>City (Primary): </b> {editMode ?
                        <DropdownSearch
                          placeholder='Required'
                          options={cities}
                          value={drafData.city_code}
                          onSelect={(option) => {
                            setDrafData((prev) => {
                              return {
                                ...prev, city_code: option.value
                              }
                            })
                          }}/>
                        : `${data.city_code} (${data.city_name})`}
                    </div>
                    <div>
                      <b>Cities (Secondary): </b> {editMode ?
                        <DropdownSearch
                          placeholder='Optional'
                          options={cities}
                          isMulti
                          value={drafData?.cities_secondary}
                          onSelect={(option) => {
                            setDrafData((prev) => {
                              return {
                                ...prev, cities_secondary: option
                              }
                            })
                          }}/>
                        : data.cities_secondary?.map(item=> item.label).join(', ')}
                    </div>
                  </Stack>

                </Grid>
                <Grid item xs={4}>
                  <b>State: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.state}
                        size="small"
                        disabled
                        variant="outlined"
                        placeholder='Optional'
                        onChange={(e) => {
                          setDrafData(prev => {
                            return {
                              ...prev, state: e.target.value
                            }
                          })
                        }}
                      />
                    </div> : data.state}
                </Grid>
                <Grid item xs={4}>
                  <b>Country: </b> {editMode ?
                    <DropdownSearch
                      isDisabled
                      placeholder='Required'
                      options={countries}
                      value={data.country_code}
                      onSelect={(option) => {
                        setDrafData((prev) => {
                          return {
                            ...prev, country_code: option.value
                          }
                        })
                      }}/> : `${data.country_code}`}
                </Grid>
                <Grid item xs={12}>
                  <b>Address: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.address}
                        size="small"
                        disabled
                        variant="outlined"
                        placeholder='Required'
                        onChange={(e) => {
                          setDrafData(prev => {
                            return {
                              ...prev, address: e.target.value
                            }
                          })
                        }}
                      />
                    </div>
                    : data.address}
                </Grid>
                <Grid item xs={4}>
                  <b>Phone: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.phone}
                        size="small"
                        disabled
                        variant="outlined"
                        placeholder='Optional'
                        onChange={(e) => {
                          setDrafData(prev => {
                            return {
                              ...prev, phone: e.target.value
                            }
                          })
                        }}
                      />
                    </div> : data.phone}
                </Grid>
                <Grid item xs={4}>
                  <b>Alt phone: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.alt_phone}
                        size="small"
                        disabled
                        variant="outlined"
                        placeholder='Optional'
                        onChange={(e) => {
                          setDrafData(prev => {
                            return {
                              ...prev, alt_phone: e.target.value
                            }
                          })
                        }}
                      />
                    </div> : data.alt_phone}
                </Grid>
                <Grid item xs={4}>
                  <b>Fax: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.fax}
                        size="small"
                        disabled
                        variant="outlined"
                        placeholder='Optional'
                        onChange={(e) => {
                          setDrafData(prev => {
                            return {
                              ...prev, fax: e.target.value
                            }
                          })
                        }}
                      />
                    </div> : data.fax}
                </Grid>
                <Grid item xs={4}>
                  <b>Email: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.email}
                        size="small"
                        disabled
                        variant="outlined"
                        placeholder='Optional'
                        onChange={(e) => {
                          setDrafData(prev => {
                            return {
                              ...prev, email: e.target.value
                            }
                          })
                        }}
                      />
                    </div> : data.email}
                </Grid>
                <Grid item xs={4}>
                  <b>Website: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.web_url}
                        size="small"
                        disabled
                        variant="outlined"
                        placeholder='Optional'
                        onChange={(e) => {
                          setDrafData(prev => {
                            return {
                              ...prev, web_url: e.target.value
                            }
                          })
                        }}
                      />
                    </div> : data.web_url}
                </Grid>
                <Grid item xs={4}>
                  <b>Telex: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.telex}
                        size="small"
                        disabled
                        variant="outlined"
                        placeholder='Optional'
                        onChange={(e) => {
                          setDrafData(prev => {
                            return {
                              ...prev, telex: e.target.value
                            }
                          })
                        }}
                      />
                    </div> : data.telex}
                </Grid>
                <Grid item xs={4}>
                  <b>Zip code: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.zip_code}
                        size="small"
                        disabled
                        variant="outlined"
                        placeholder='Optional'
                        onChange={(e) => {
                          setDrafData(prev => {
                            return {
                              ...prev, zip_code: e.target.value
                            }
                          })
                        }}
                      />
                    </div> : data.zip_code}
                </Grid>
                <Grid item xs={4}>
                  <b>OAG code: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.oag_code}
                        size="small"
                        disabled
                        variant="outlined"
                        placeholder='Optional'
                        onChange={(e) => {
                          setDrafData(prev => {
                            return {
                              ...prev, oag_code: e.target.value
                            }
                          })
                        }}
                      />
                    </div> : data.oag_code}
                </Grid>
                <Grid item xs={4}>
                  <b>Vendor: </b> {editMode ?
                    <DropdownSearch
                      placeholder='Required'
                      options={vendors}
                      isDisabled
                      value={drafData.vendor_id}
                      onSelect={(option) => {
                        setDrafData((prev) => {
                          return {
                            ...prev, vendor_id: option.value
                          }
                        })
                      }}/>
                    : `${data.vendor_id} (${vendors.filter((c)=> c.value === data.vendor_id)[0].label })`}
                </Grid>
                <Grid item xs={4}>
                  <b>Category: </b> {editMode ?
                    <DropdownSearch
                      isDisabled
                      placeholder='Optional'
                      options={categories}
                      value={drafData.category_id}
                      onSelect={(option) => {
                        setDrafData((prev) => {
                          return {
                            ...prev, category_id: option.value
                          }
                        })
                      }}/> : `${data.category_id} (${categories.filter((c)=> c.value === data.category_id)[0].label })`}
                </Grid>
              </Grid>
            </Paper>

            <Box sx={{ mt: 2 }}>
              {subPanel()}
            </Box>
          </Box>
      }
    </>
  );
}
