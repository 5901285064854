import { BackdropLoading } from '../../../../components/BackdropLoading';
import Box from '@mui/material/Box';
import { FormControl, Grid, MenuItem, Paper, Select, Stack, TextField } from '@mui/material';
import ActiveLastBreadcrumbs from '../../../../components/Breadcrumbs';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { AdminStatusLabel, AdminUserStatus, IAdminUser, IBreadcrumb, IOptionItem, RoutePath } from '../../../../constants';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Toast } from '../../../../components/Toast';
import { AdminRoleApi, AdminUserApi, ClientApi } from '../../../../api';
import { LoadingButton } from '@mui/lab';
import { DropdownSearch } from '../../../../components/DropdownSearch';
import { useQuery } from 'react-query';

const breadcrumbs: IBreadcrumb[] = [
  { name: 'Users', href: RoutePath.ADMIN_USERS },
  { name: 'User', href: '#' }
]
export const AdminUserDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<any>();
  const [draftData, setDraftData] = useState<any>();
  const [pwData, setPWData] = useState<{ newPW: string, confirmPW: string }>({
    newPW: '', confirmPW: ''
  });
  const [resetPWErr, setResetPWErr] = useState('');
  const [rolesOptions, setRolesOptions] = useState<IOptionItem[]>([]);
  const { data: roles } = useQuery<any[], Error>(
    ['getRoles'],
    AdminRoleApi.getAll,
  );

  useEffect(()=> {
    if (roles){
      const options: IOptionItem[] = [];
      roles.forEach((r)=> {
        options.push({
          value: r.id,
          label: r.name,
        })
      });
      setRolesOptions(options);
    }
  }, [roles]);

  const [clientOptions, setClientOption] = useState<IOptionItem[]>([]);
  const { data: clients } = useQuery<any[], Error>(
    ['getClients'],
    ClientApi.list,
  );
  useEffect(()=> {
    if (clients){
      const options: IOptionItem[] = [];
      clients.forEach((r)=> {
        options.push({
          value: r.id,
          label: r.name,
        })
      });
      setClientOption(options);
    }
  }, [clients])

  useEffect(()=> {
    fetchData();
  },[])

  useEffect(()=> {
    setDraftData(data);
  },[data])

  const fetchData = async () => {
    const res = await AdminUserApi.get(id);
    res.admin_users_roles = res.admin_users_roles?.map((role) => ({
      value: role.admin_roles.id,
      label: role.admin_roles.name,
    }));
    setData(res);
    setDraftData(res);
    setLoading(false);
  }
  const cancelEditMode = () => {
    setEditMode(false);
    setDraftData(data);
  }

  const readModeButton = () => {
    return (
      <Button variant='contained' onClick={() => setEditMode(true)}>Edit</Button>
    )
  }
  const editModeButtons = () => {
    return (
      <Stack direction='row' spacing={1}>
        <LoadingButton loading={saving} variant='outlined' onClick={cancelEditMode}>Cancel</LoadingButton>
        <LoadingButton loading={saving} variant='contained' onClick={handleSave}>Save</LoadingButton>
      </Stack>
    )
  }

  const handleSave = () => {
    setSaving(true);
    //convert admin users roles to post api
    draftData.admin_users_roles = draftData.admin_users_roles.map((role: IOptionItem)=> (Number.parseInt(role.value)));
    const client = draftData.agency_id ? draftData.agency_id : null;
    AdminUserApi.update(id, draftData.first_name, draftData.last_name, draftData.phone_mobile, draftData.address, draftData.status, client, draftData.admin_users_roles)
      .then(async (res: any)=> {
        setSaving(false);
        res.admin_users_roles = res.admin_users_roles?.map((role: any) => ({
          value: role.admin_roles.id,
          label: role.admin_roles.name,
        }));
        setData(res);
        setEditMode(false);
        await Toast.fire({
          icon: 'success',
          title: 'Updated'
        })
      })
      .catch(async (err)=> {
        setSaving(false);
        await Toast.fire({
          icon: 'error',
          title: err.message
        })
      })
  }
  const handleResetPW = () => {
    if (pwData.newPW !== pwData.confirmPW) {
      setResetPWErr('The password does not match.');
      return;
    }
    setResetting(true);
    AdminUserApi.resetPassword(id, pwData.newPW)
      .then(async (res) => {
        setResetting(false);
        await Toast.fire({
          icon:'success',
          title: 'Password has been updated.'
        })
      })
      .catch(async (err)=> {
        setResetting(false);
        await Toast.fire({
          icon: 'error',
          title: err.message
        })
      })
  }

  if (loading){
    return <span>Loading...</span>
  }

  return (
    <>
      <Box sx={{ flex: 1 }}>
        <Stack direction='row' justifyContent='space-between'>
          <Stack justifyContent='center'>
            <ActiveLastBreadcrumbs items={breadcrumbs}/>
          </Stack>

          <Stack justifyContent='center'>
            {
              editMode ? editModeButtons() : readModeButton()
            }
          </Stack>
        </Stack>

        <Paper sx={{ width: '100%', p: 2, mt: 3 }}>
          <Typography variant="h4" gutterBottom component="div">
                General
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={4}>
              <b>Username: </b> {data.username}
            </Grid>
            <Grid item xs={4}>
              <b>Email: </b> {data.email}
            </Grid>
            <Grid item xs={4}>
              <b>Phone: </b> {editMode ?
                <div>
                  <TextField
                    sx={{ width: '100%' }}
                    value={draftData.phone_mobile}
                    size="small"
                    type='text'
                    variant="outlined"
                    placeholder='Required'
                    onChange={(e) => {
                      setDraftData((prev: any) => {
                        return {
                          ...prev, phone_mobile: e.target.value
                        }
                      })
                    }}
                  />
                </div> : data.phone_mobile}
            </Grid>
            <Grid item xs={4}>
              <b>First name: </b> {editMode ?
                <div>
                  <TextField
                    sx={{ width: '100%' }}
                    value={draftData.first_name}
                    size="small"
                    variant="outlined"
                    placeholder='Optional'
                    onChange={(e) => {
                      setDraftData((prev: any) => {
                        return {
                          ...prev, first_name: e.target.value
                        }
                      })
                    }}
                  />
                </div> : data.first_name}
            </Grid>
            <Grid item xs={4}>
              <b>Last name: </b> {editMode ?
                <div>
                  <TextField
                    sx={{ width: '100%' }}
                    value={draftData.last_name}
                    size="small"
                    variant="outlined"
                    placeholder='Optional'
                    onChange={(e) => {
                      setDraftData((prev: any) => {
                        return {
                          ...prev, last_name: e.target.value
                        }
                      })
                    }}
                  />
                </div>
                : data.last_name}
            </Grid>
            <Grid item xs={4}>
              <b>Address: </b> {editMode ?
                <div>
                  <TextField
                    sx={{ width: '100%' }}
                    value={draftData.address}
                    size="small"
                    variant="outlined"
                    placeholder='Optional'
                    onChange={(e) => {
                      setDraftData((prev: any) => {
                        return {
                          ...prev, address: e.target.value
                        }
                      })
                    }}
                  />
                </div>: data.address}
            </Grid>
            <Grid item xs={4}>
              <b>Status: </b> {editMode ?
                <div>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <Select
                      value={draftData.status}
                      onChange={(e)=> {
                        setDraftData((prev: any) => {
                          return {
                            ...prev, status: e.target.value
                          }
                        })
                      }
                      }
                    >
                      {
                        AdminUserStatus.map((item) => (
                          <MenuItem key={item} value={item}>{AdminStatusLabel[item]}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </div>
                : AdminStatusLabel[data.status]}
            </Grid>
            <Grid item xs={4}>
              <b>Role(s): </b> {editMode ?
                <DropdownSearch
                  value={draftData.admin_users_roles}
                  options={rolesOptions}
                  onSelect={(e) => setDraftData((prev: any) => ({ ...prev, admin_users_roles: e }))}
                  isMulti
                />
                : <>
                  {
                    data.admin_users_roles.map((role: IOptionItem) => (<div key={role.value}>{role.label}</div>))
                  }
                </>}
            </Grid>
            <Grid item xs={4}>
              <b>Client: </b> {editMode ?
                <DropdownSearch
                  value={draftData.agency_id}
                  options={clientOptions}
                  onSelect={(e) => setDraftData((prev: any) => ({ ...prev, agency_id: e.value }))}
                />
                : <>
                  {
                    data?.agencies ? data.agencies.name : 'None'
                  }
                </>}
            </Grid>
          </Grid>
        </Paper>

        <Paper sx={{ width: '100%', p: 2, mt: 3 }}>
          <Typography variant="h4" gutterBottom component="div">
                Reset Password
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={4}>
              <TextField
                label='New password'
                sx={{ width: '100%' }}
                value={pwData.newPW}
                size="small"
                type='password'
                variant="outlined"
                onChange={(e) => {
                  setPWData((prev: any) => {
                    return {
                      ...prev, newPW: e.target.value
                    }
                  })
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Confirm password'
                sx={{ width: '100%' }}
                value={pwData.confirmPW}
                size="small"
                type='password'
                variant="outlined"
                error={resetPWErr !== ''}
                helperText={resetPWErr}
                onChange={(e) => {
                  setPWData((prev: any) => {
                    return {
                      ...prev, confirmPW: e.target.value
                    }
                  })
                  setResetPWErr('');
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <LoadingButton loading={resetting} variant='outlined' onClick={handleResetPW}>Reset</LoadingButton>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  )
}

