import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { LangContext } from '../../context/language.context';

interface Props {
    pageTitle: string;
    actionTitle?: string;
    onAction?: () => void;
}

export const PageHeader = (props: Props) => {
  const { pageTitle, actionTitle, onAction } = props;
  const { dispatch:{ translate } } = useContext(LangContext);

  return (
    <Stack direction='row' justifyContent='space-between'>
      <Typography variant="h2" gutterBottom component="div">
        {translate(pageTitle)}
      </Typography>
      {
        actionTitle ?
          <Stack justifyContent='center'>
            <Button variant='contained' onClick={onAction}>{translate(actionTitle)}</Button>
          </Stack>
          : ''
      }
    </Stack>
  )
}
