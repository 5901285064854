import { combineReducers } from 'redux';
import { SignedInReducer } from './signedin.reducer';
import { LanguageReducer } from './language.reducer';
import { ReloadReducer } from './reload.reducer';
import { UserReducer } from './user.reducer';

export const rootReducers = combineReducers({
  signedin: SignedInReducer,
  language: LanguageReducer,
  reload: ReloadReducer,
  user: UserReducer
});

export type RootState = ReturnType<typeof rootReducers>;
