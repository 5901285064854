import React, { useContext, useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';
import { useQuery } from 'react-query';
import { IColumn, RoutePath } from '../../../../constants';
import { UserApi } from '../../../../api';
import { PageHeader } from '../../../../components/PageHeader';
import { DataTable } from '../../../../components/DataTable';
import { format } from 'date-fns';
import { Toast } from '../../../../components/Toast';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { LangContext } from '../../../../context/language.context';
import { FilterBox } from '../../../../components/FilterBox';

const renderDatetime = (value: any)=> {
  return <span>{format(new Date(value), 'yy-MM-dd')} {format(new Date(value), 'p')}</span>
}
const renderStatus = (value: any) => {
  if (value === 0){
    return <Chip color={'success'} label={'Active'} />
  }else{
    return <Chip color={'default'} label={'Inactive'} />
  }
}
const columns : IColumn[] = [
  {
    db_field: 'no',
    label: 'No.',
    minWidth: 70
  },
  {
    db_field: 'id',
    label: 'Membership#',
    align: 'left'
  },
  {
    db_field: 'korean_name',
    label: 'koreanName',
    align: 'left',
  },
  {
    db_field: 'last_name',
    label: 'lastName',
    align: 'left',
  },

  {
    db_field: 'first_name',
    label: 'firstName',
    align: 'left',
  },
  {
    db_field: 'full_phone',
    label: 'phone',
    align: 'left',
  },
  {
    db_field: 'email',
    label: 'Email',
    align: 'left'
  },
  {
    db_field: 'booked_res',
    label: 'Numbers of Res.',
    align: 'right'
  },
  {
    db_field: 'canceled_res',
    label: 'Numbers of Can.',
    align: 'right'
  },
  {
    db_field: 'created_date',
    label: 'createdDate',
    align: 'left',
    format: (value)=> renderDatetime(value),
  },
  {
    db_field: 'deleted',
    label: 'Status',
    align: 'left',
    format: (value)=> renderStatus(value),
  },
  {
    db_field: 'actions',
    label: '',
    minWidth: 50,
    align: 'right'
  }
];
export const Users = (): JSX.Element => {
  const { dispatch: { translate } } = useContext(LangContext);
  const navigate = useNavigate();
  const [updated, setUpdated] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [users, setUsers] = useState<any[]>([]);
  const { status, error, data } = useQuery<any[], Error>(
    ['getAllUsers', updated],
    UserApi.list,
  );
  useEffect(()=> {
    if (status === 'success')
      setUsers(data);
  },[data]);
  if (status === 'error') {
    return <div>{error?.message}</div>;
  }
  if (status === 'loading' || status === 'idle'){
    return <div>{translate('loading')}...</div>
  }
  const handleDelete = async (data: any) => {
    Swal.fire({
      title: translate('delete') + '?',
      text: translate('confirmDelete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1976d2',
      cancelButtonColor: '#d33',
      confirmButtonText: translate('yes'),
      cancelButtonText: translate('cancel')
    }).then(async (result) => {
      if (result.isConfirmed) {
        UserApi.destroy(data.id)
          .then(async (res)=> {
            setUpdated(!updated);
            await Toast.fire({
              icon: 'success',
              title: translate('deleted')
            })
          })
          .catch(async (err)=> {
            await Toast.fire({
              icon: 'error',
              title: err.message
            })
          })
      }
    })
  }

  const handleView = (data:any) => {
    navigate(`/${RoutePath.USERS}/${data.id}`);
  }
  const handleFilter = () => {
    if (keyword === ''){
      setUsers(data);
      return;
    }
    const filterData = data.filter((user) =>
      user.id.toString() === keyword || user.email === keyword.toLowerCase() || (keyword.length >6 && user.full_phone.includes(keyword)));
    setUsers(filterData);
  }
  return (
    <>
      <Box sx={{ flex: 1 }}>
        <PageHeader pageTitle='users' />
        <FilterBox keyword={keyword} placeholer={'Search Membership# or Email or Phone'} onChange={setKeyword} onFilter={handleFilter} />
        <DataTable
          loading={false}
          columns={columns}
          data={users}
          onView={handleView}
          onDelete={handleDelete}/>
      </Box>
    </>
  );
}
