import React, { useContext, useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { LangContext } from '../../../../context/language.context';
import Typography from '@mui/material/Typography';
import LinearProgess from '../../../../components/LinearProgess';
import { Stack } from '@mui/material';
import { Toast } from '../../../../components/Toast';
import * as XLSX from 'xlsx';
import { LocationsApi } from '../../../../api';
const MAX_ROWS = 50;
interface Props {
  open: boolean;
  onClose: () => void;
}

export const ImportLocationDialog = (props: Props) => {
  const { open, onClose } = props;
  const { dispatch: { translate } } = useContext(LangContext);
  const [uploading, setUploading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [fileName, setFileName] = useState('');
  const [imported, setImported] = useState(0);
  const [total, setTotal] = useState(0);
  const [percent, setPercent] = useState(0);
  const [items, setItems] = useState<any[]>([]);
  useEffect(() => {
    if (uploading){
      handleImport();
    }
  }, [uploading])
  const handleImport=async ()=>{
    let records = items;
    let count = 0;
    setTotal(items.length);
    while (records.length > 0 ){
      const data = records.slice(0, MAX_ROWS);
      count += data.length;
      records = records.slice(MAX_ROWS)
      console.log('data', data);
      console.log('count', count);
      setPercent(Math.round(count/items.length * 100));
      const res : any = await LocationsApi.importLocationV2(data);
      console.log('res', res);
      if (res.success === true) {
        setImported(prevState => {
          return prevState + res.imported;
        })
      }
      await sleep(1000);
    }
    setUploading(false);
    setFinished(true);
  }
  const onChangeFile = async (e: any) => {
    if (!e.target.files[0]) {
      await Toast.fire({
        icon: 'error',
        title: 'Please select file again.'
      });
      return;
    }
    setFileName(e.target.files[0].name);
    readExcel(e.target.files[0]);
  }
  const readExcel = (file: any) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e: any) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d: any) => {
      setUploading(true);
      return setItems(d);
    });
  };

  const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{`${translate('import')} ${translate('location')}`}</DialogTitle>
      <DialogContent>
        <Stack>
          <label htmlFor="contained-button-file">
            <input style={{ display: 'none' }} disabled={uploading} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id="contained-button-file" type="file" onChange={onChangeFile}/>
            <Button sx={{ m: 0 }} variant="outlined" component="span" size='small'>
              Upload File
            </Button>
          </label>
          <Typography variant="body2" display="block" gutterBottom>
            {fileName && !finished ? fileName + ' importing...' :  'Please only choose .xlsx or .csv file'}
          </Typography>
          {
            uploading ? <>
              <LinearProgess/>
              <Typography variant="body2" gutterBottom component="div">
                {percent} %
              </Typography>
              <Typography variant="body2" gutterBottom color="red">
                During data import, please do not leave this page.
              </Typography>
            </> : <></>
          }
          {
            finished ?
              <>
                <Typography variant="body2" gutterBottom component="div" color="green">
                  Imported
                </Typography>
                <Typography variant="body2" gutterBottom component="div" color="green">
                  Total: {total}
                </Typography>
                <Typography variant="body2" gutterBottom component="div" color="green">
                  Imported: {imported}
                </Typography>
              </>
              :
              <></>
          }
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={uploading} onClick={onClose}>{translate('close')}</Button>
      </DialogActions>
    </Dialog>
  )
}
