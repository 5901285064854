import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { REFRESH_TOKEN_NAME, TOKEN_NAME } from '../constants';
import { signout, store } from '../state';
import { Toast } from '../components/Toast';

const cookies = new Cookies();

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : ''
  },

  paramsSerializer: (params: Record<string, unknown>) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token = cookies.get(TOKEN_NAME);
  if (token) {
    // @ts-ignore
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data;
  },
  async (error: AxiosError) => {
    const { response, config } = error;
    const originalReq = config;
    // @ts-ignore
    if (response && response.status === 401 && !originalReq._retry) {
      // @ts-ignore
      originalReq['_retry'] = true;
      const refreshToken = cookies.get(REFRESH_TOKEN_NAME);
      const headers = {
        'Authorization': `Bearer ${refreshToken}`,
        'content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : '',
      };
      try {
        const tokens = await axios.post(`${process.env.REACT_APP_API_URL}/admin/refresh`, {}, { headers: headers });
        cookies.set(TOKEN_NAME, tokens.data.access_token);
        cookies.set(REFRESH_TOKEN_NAME, tokens.data.refresh_token);
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${tokens.data.access_token}`;
        return axiosClient(originalReq);
      } catch (e) {
        cookies.remove(TOKEN_NAME);
        cookies.remove(REFRESH_TOKEN_NAME);
        await Toast.fire({
          icon: 'warning',
          title: 'Your session has expired, please sign-in again.'
        })
        store.dispatch(signout());
        throw e;
      }
    }
    if (error && error.response && error.response.data) {
      throw error.response.data;
    }
    throw error;
  },
);

export const AxiosClient = axiosClient;
