import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import BorderColorIcon from '@mui/icons-material/BorderColor';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}/>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export const CustomTooltip = (props: {content: any}) => {
  return (
    <HtmlTooltip
      title={props.content}
    >
      <InfoOutlinedIcon />
    </HtmlTooltip>
  )
}

export const ClickTooltip = (props: {content: any; open: boolean; setOpen: (open:boolean) => void}) => {
  return (
    <HtmlTooltip
      title={props.content}
      disableHoverListener={true}
      disableFocusListener={true}
      disableTouchListener={true}
      open={props.open}
    >
      <IconButton onClick={() => props.setOpen(!props.open) }>
        <BorderColorIcon sx={{ fontSize: 15 }} color='inherit' />
      </IconButton>
    </HtmlTooltip>
  )
}
