export enum RoutePath {
  SIGN_IN = 'sign-in',
  CHANGE_PW='change-password',
  LOCALES = 'locales',
  COUNTRIES = 'countries',
  CITIES = 'cities',
  TRANSLATION = 'translation',
  USERS = 'users',
  ADMIN_USERS = 'admin-users',
  USER_ROLES = 'user-role',
  LOCATIONS = 'locations',
  CATEGORIES = 'categories',
  SETTINGS = 'general',
  RESERVATION = 'reservation',
  PAYMENT = 'payment',
  IMPORT_LOCATION = 'locations/import',
  COUPON = 'coupon',
  ADD_UPDATE_COUPON = 'add-update-coupon',
  FAQ = 'faq',
  ERRORS='errors-messages',
  EXCHANGE_RATE_HISTORY = 'exchange-rate-history',
  CLIENTS='clients',
  PPD_RESERVATION = 'ppd-reservation'
}
