import { DateRangePicker as DRP } from 'react-date-range';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useState } from 'react';
import { StyledMenu } from '../StyledMenu';
import { InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
interface Props{
  text: string;
  state: any;
  onChange: (item: any) => void;
  onClean: () => void;
}
export const DateRangePicker = (props: Props) => {
  const { text, state, onChange, onClean } = props;
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [open, setOpen] = useState(Boolean(anchorEl));
  const handleOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
    setOpen(Boolean(e.currentTarget));
  }
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(Boolean(null));
  }
  const handleClean = (e: any) =>{
    e.preventDefault();
    e.stopPropagation();
    onClean();
  }
  return (
    <>
      <TextField
        size='small'
        placeholder={'Select date & time'}
        value={text}
        onClick={handleOpen}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle clean datetime"
                onClick={handleClean}
                edge="end"
              >
                <BackspaceOutlinedIcon  />
              </IconButton>
            </InputAdornment>,
        }}
      />
      <StyledMenu
        id={'date-picker-menu'}
        MenuListProps={{
          'aria-labelledby': 'date-picker-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <DRP
          onChange={onChange}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
        />
      </StyledMenu>
    </>
  )
}
