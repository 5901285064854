import { ICategory } from '../../../../constants';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import Label from '@mui/icons-material/Label';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SvgIconProps } from '@mui/material/SvgIcon';

interface Props {
    items: ICategory[];
    onClick: (item: ICategory) => void;
}

declare module 'react' {
    interface CSSProperties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props: StyledTreeItemProps) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }}/>
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

const itemColor = '#1a73e8';
const itemBgColor = '#e8f0fe';

export const CategoriesTreeView = (props: Props) => {
  const { items, onClick } = props;

  const handleToggle = (item: ICategory) => {
    onClick(item);
  }

  return (
    <TreeView
      aria-label="categories"
      defaultExpanded={[items[0].id]}
      defaultCollapseIcon={<ArrowDropDownIcon/>}
      defaultExpandIcon={<ArrowRightIcon/>}
      defaultEndIcon={<div style={{ width: 24 }}/>}
      sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
    >
      {
        items.map((value, index) => {
          if (value?.children && value.children.length > 0) {
            return (
              <StyledTreeItem
                onClick={()=> handleToggle(value)}
                color={itemColor}
                bgColor={itemBgColor}
                key={index}
                nodeId={value.id}
                labelText={value.name} labelIcon={Label}>
                {value.children.map((child, index_1) => {
                  return (
                    <StyledTreeItem
                      onClick={() => handleToggle(child)}
                      key={index_1}
                      nodeId={child.id.toString()}
                      labelText={child.name}
                      labelIcon={LocalOfferIcon}
                      color={itemColor}
                      bgColor={itemBgColor}
                    />
                  )
                })}
              </StyledTreeItem>
            );
          }
          return (
            <StyledTreeItem key={index}
              onClick={() => handleToggle(value)}
              color={itemColor}
              bgColor={itemBgColor}
              nodeId={value.id}
              labelText={value.name}
              labelIcon={LocalOfferIcon}/>
          )
        })
      }
    </TreeView>
  )
}
