import { Button, Container, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import FAQTabs from '../../components/FAQTabs';

export const FAQEdit = (): JSX.Element => {
  // const { id } = useParams();
  const location = useLocation();
  const [data, setData] = useState<any>(() => {
    const res = location.state;
    console.log('received', res);
    if (res) {
      return res;
    }
    return;
  });

  const navigate = useNavigate();

  useEffect(() => {}, []);

  const handleOnClickBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: grey[300],
          padding: 2,
          bgcolor: grey[100],
        }}
      >
        <Typography variant="subtitle2">
          Update service: {data?.question_kr} {console.log('render')}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: grey[500],
            //   padding: 2,
            //   bgcolor: grey[100],
            color: grey[500],
          }}
          onClick={handleOnClickBack}
        >
          <b>&lt; Back</b>
        </Button>
      </Stack>
      <Stack
        sx={{
          backgroundColor: '#fff',
          borderWidth: 1,
          borderColor: grey[300],
          borderStyle: 'solid',
          padding: 2,
        }}
        borderColor={grey[300]}
      >
        <FAQTabs data={data} />
      </Stack>
    </Container>
  );
};
