import * as React from 'react';
import './styles.css';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { AuthApi } from '../../../../api';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, signin } from '../../../../state';
import { REFRESH_TOKEN_NAME, TOKEN_NAME } from '../../../../constants';
import Cookies from 'universal-cookie';
import { Toast } from '../../../../components/Toast';
import { LoadingButton } from '@mui/lab';
import { Card, CardContent, Grid } from '@mui/material';
import { LangContext } from '../../../../context/language.context';
import { UserContext } from '../../../../context/user.context';

const cookies = new Cookies();

const theme = createTheme();

export const SignIn = (): JSX.Element => {
  const { dispatch: { translate } } = useContext(LangContext);
  const { dispatch: { setCurrentUser } } = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const signedIn = useSelector((state: RootState) => state.signedin);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = cookies.get(TOKEN_NAME);
    const refresh_token = cookies.get(REFRESH_TOKEN_NAME);
    if (signedIn || (token && refresh_token)){
      navigate('/');
    }
  },[]);

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  }

  const handleSubmit = async () => {
    setLoading(true);
    AuthApi.signIn(username, password).then((res :any) => {
      setLoading(false);
      //sign in success
      const { data : { access_token, refresh_token, user } } = res;
      // sign in
      dispatch(signin(access_token, refresh_token));
      setCurrentUser(user);
      // redirect to home
      navigate('/reservation');
    })
      .catch( async (err) => {
        const { response : { data: { statusCode, error, message } } } = err;
        setLoading(false);
        if (statusCode  === 401){
          await Toast.fire({
            icon: 'error',
            title: translate('credentialsIncorrect')
          })
        }
        else if (statusCode === 403){
          await Toast.fire({
            icon: 'error',
            title: 'Forbidden'
          })
        }else{
          await Toast.fire({
            icon: 'error',
            title: `${error}: ${message}`
          })
        }
      });
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', background: '#eee' }}
      >
        <Grid item xs={3}>
          <Card sx={{ padding: '1rem' }}>
            <CardContent>
              <Box className='signin-box'>
                <img
                  src={'/logo_trimo.png'}
                  alt="logo"
                  className='signin-logo'
                />
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="username"
                    label={translate('username')}
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value)
                    }}
                    onKeyDown={handleKeyDown}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    label={translate('password')}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                    onKeyDown={handleKeyDown}
                  />
                  <LoadingButton
                    type="button"
                    fullWidth
                    loading={loading}
                    disabled={!username || !password}
                    loadingPosition="end"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSubmit}
                  >
                    {translate('signIn')}
                  </LoadingButton>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

      </Grid>
    </ThemeProvider>
  );
}
