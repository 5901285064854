import Box from '@mui/material/Box';
import {  Grid,  Paper, Stack } from '@mui/material';
import ActiveLastBreadcrumbs from '../../../../components/Breadcrumbs';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { IBreadcrumb, IUser, RoutePath } from '../../../../constants';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { LangContext } from '../../../../context/language.context';
import Checkbox from '@mui/material/Checkbox';
import { UserApi } from '../../../../api';

const breadcrumbs: IBreadcrumb[] = [
  { name: 'users', href: RoutePath.USERS },
  { name: 'user', href: '#' }
]
export const UserDetail = () => {
  const { id } = useParams();
  if (!Number.parseInt(id as string) || id === undefined){
    return <>Has some errors</>
  }
  const { dispatch: { translate } } = useContext(LangContext);
  const { data, status, error } = useQuery<IUser, Error>(['getUser'], () =>  UserApi.getOne(parseInt(id)));
  if (status === 'error') {
    return <div>{error?.message}</div>;
  }
  if (status === 'loading' || status === 'idle'){
    return <div>{translate('loading')}...</div>
  }
  return (
    <>
      <Box sx={{ flex: 1 }}>
        <Stack direction='row' justifyContent='space-between'>
          <Stack justifyContent='center'>
            <ActiveLastBreadcrumbs items={breadcrumbs}/>
          </Stack>
          <Stack justifyContent='center'>

          </Stack>
        </Stack>

        <Paper sx={{ width: '100%', p: 2, mt: 3 }}>
          <Typography variant="h4" gutterBottom component="div">
            {translate('general')}
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={4}>
              <b>{translate('email')}: </b> {data.email}
            </Grid>
            <Grid item xs={4}>
              <b>{translate('phone')}: </b>  {data.phone}
            </Grid>
            <Grid item xs={4}>
              <b>{translate('loginBy')}: </b> {data.login_type}
            </Grid>
            <Grid item xs={4}>
              <b>{translate('firstName')}: </b> {data.first_name}
            </Grid>
            <Grid item xs={4}>
              <b>{translate('lastName')}: </b> {data.last_name}
            </Grid>
            <Grid item xs={4}>
              <b>{translate('koreanName')}: </b> {data.korean_name}
            </Grid>
            <Grid item xs={4}>
              <b>{translate('refundBank')}: </b> {data.refund_bank}
            </Grid>
            <Grid item xs={4}>
              <b>{translate('refundAccount')}: </b> {data.refund_account_number}
            </Grid>
            <Grid item xs={4}>
              <b>{translate('refundHolder')}: </b> {data.refund_account_holder}
            </Grid>
            <Grid item xs={4}>
              <b>{translate('airline')}: </b> {data.airline}
            </Grid>
            <Grid item xs={4}>
              <b>{translate('mileageNumber')}: </b> {data.mileage_number}
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={12}>
              <b>{translate('hertzMembership')}: </b> {data.hertz_membership}
            </Grid>
            <Grid item xs={12}>
              <b>{translate('address')}: </b> {data.address}
            </Grid>
            <Grid item xs={4}>
              <b>{translate('mailingEmail')}: </b>
              <Checkbox disabled checked={data.mailing_email === 1} />
            </Grid>
            <Grid item xs={4}>
              <b>{translate('mailingText')}: </b>
              <Checkbox disabled checked={data.mailing_text_msg === 1} />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  )
}

