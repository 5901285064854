import { AxiosClient } from './axios-client';

const path = 'categories';

export const CategoriesApi = {
  getCategories: async () => {
    const response: any[] = await AxiosClient.get(`${path}/all`);
    return response;
  },
  getCategoriesOptions: async () => {
    const response: any[] = await AxiosClient.get(`${path}/options`);
    return response;
  },
  addCategory: (name: string, parent_id: number | null) => {
    if (parent_id){
      return AxiosClient.post(`${path}`, { name: name, parent_id: parent_id });
    }else{
      return AxiosClient.post(`${path}`, { name: name });
    }
  },
  deleteCategory: (id: number)=>{
    return AxiosClient.delete(`${path}?id=${id}`);
  },
  removeLocation: (location: string, category: number) => {
    return AxiosClient.post(`${path}/removeLocation`, {
      category: category,
      location: location
    });
  },
  addLocations: (category: number, locations: string[])=>{
    return AxiosClient.post(`${path}/addLocations`, {
      category: category,
      locations: locations
    });
  }
}
