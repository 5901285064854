import { AxiosClient } from './axios-client';

const path = 'coupon';

export interface ICoupon {
  id?: number;
  couponType: string;
  couponName: string;
  description: string;
  corpDiscountNumber: string;
  promotionCode: string;
  issuedCount: number;
  minDays: number;
  listBrands: string[];
  listCountries: string[];
  carGroup: string[];
  orderStartTime: Date;
  orderEndTime: Date;
  pickupTime: Date;
  pickupEndTime: Date;
  status: string;
  remark: string;
}

export const CouponApi = {
  getAll: async () => {
    const response: any[] = await AxiosClient.get(`${path}`);
    return response;
  },
  addCoupon: async (data: ICoupon) => {
    const response: any = await AxiosClient.post(`${path}`, data);
    return response;
  },
  getFilteredCoupons: async (
    brand: string,
    cdpNumber: string,
    pcNumber: string,
  ) => {
    try {
      const response: any = await AxiosClient.get(
        `${path}/filter?brand=${brand}&cdpNumber=${cdpNumber}&pcNumber=${pcNumber}&`,
      );
      return response;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  updateCoupon: (id: number, data: any) => {
    return AxiosClient.patch(`${path}/${id}`, data);
  }
};
