export const SIDEBAR_WIDTH = 300;

// https://en.wikipedia.org/wiki/List_of_UTC_offsets#UTC+14:00,_M%E2%80%A0
export const TIMEZONE: IOptionItem[] = [
  {
    label: 'UTC-12:00', value: 'UTC-12:00'
  },
  {
    label: 'UTC-11:00', value: 'UTC-12:00'
  },
  {
    label: 'UTC-10:00',
    value: 'UTC-10:00'
  },
  {
    label: 'UTC-09:00',
    value: 'UTC-09:00'
  },
  {
    label: 'UTC-08:00',
    value: 'UTC-08:00',
  },
  {
    label: 'UTC-07:00',
    value: 'UTC-07:00'
  },
  {
    label: 'UTC-06:00',
    value: 'UTC-06:00',
  },
  {
    label: 'UTC-05:00',
    value: 'UTC-05:00'
  },
  {
    label: 'UTC-04:00',
    value: 'UTC-04:00',
  },
  {
    label: 'UTC-03:00',
    value: 'UTC-03:00',
  },
  {
    label: 'UTC-02:00',
    value: 'UTC-02:00',
  },
  {
    label: 'UTC-01:00',
    value: 'UTC-01:00',
  },
  {
    label: 'UTC+00:00',
    value: 'UTC+00:00',
  },
  {
    label: 'UTC+01:00',
    value: 'UTC+01:00',
  },
  {
    label: 'UTC+02:00',
    value: 'UTC+02:00',
  },
  {
    label: 'UTC+03:00',
    value: 'UTC+03:00',
  },
  {
    label: 'UTC+03:30',
    value: 'UTC+03:30',
  },
  {
    label: 'UTC+04:00',
    value: 'UTC+04:00',
  },
  {
    label: 'UTC+04:30',
    value: 'UTC+04:30',
  },
  {
    label: 'UTC+05:00',
    value: 'UTC+05:00',
  },
  {
    label: 'UTC+05:30',
    value: 'UTC+05:30',
  },
  {
    label: 'UTC+05:45',
    value: 'UTC+05:45',
  },
  {
    label: 'UTC+06:00',
    value: 'UTC+06:00',
  },
  {
    label: 'UTC+07:00',
    value: 'UTC+07:00',
  },
  {
    label: 'UTC+08:00',
    value: 'UTC+08:00',
  },
  {
    label: 'UTC+08:45',
    value: 'UTC+08:45',
  },
  {
    label: 'UTC+09:00',
    value: 'UTC+09:00',
  },
  {
    label: 'UTC+09:30',
    value: 'UTC+09:30',
  },
  {
    label: 'UTC+10:00',
    value: 'UTC+10:00',
  },
  {
    label: 'UTC+10:30',
    value: 'UTC+10:30',
  },
  {
    label: 'UTC+11:00',
    value: 'UTC+11:00',
  },
  {
    label: 'UTC+12:00',
    value: 'UTC+12:00',
  },
  {
    label: 'UTC+12:45',
    value: 'UTC+12:45',
  },
  {
    label: 'UTC+13:00',
    value: 'UTC+13:00',
  },
  {
    label: 'UTC+14:00',
    value: 'UTC+14:00',
  },
]

// https://www.worldometers.info/geography/7-continents/
export const CONTINENTS: IOptionItem[] = [
  { label: 'Asia', value: 'Asia' },
  { label: 'Africa', value: 'Africa' },
  { label: 'Europe', value: 'Europe' },
  { label: 'North America', value: 'North America' },
  { label: 'South America', value: 'South America' },
  { label: 'Australia/Oceania', value: 'Australia/Oceania' },
  { label: 'Antarctica', value: 'Antarctica' },
]

export interface IColumn {
    db_field: string;
    label: string;
    minWidth?: number;
    align?: 'right' | 'left' | 'center';
    format?: (value: any) => any;
    only_super_admin?:boolean;
}

export interface IBreadcrumb{
    name: string;
    href: string;
}

export interface ITreeItem{
    nodeId: string;
    label: string;
    children?: ITreeItem[];
}

export interface IOptionItem{
    value: string;
    label: string;
}
