import { AxiosClient } from './axios-client';
import { IUser } from '../constants';

const PATH = 'user';
export const UserApi = {
  list: async () => {
    const result: IUser[] = await AxiosClient.get(PATH);
    return result;
  },
  getOne: async (id: any) => {
    const result: IUser = await AxiosClient.get(`${PATH}/${id}`);
    return result;
  },
  destroy: (id: number) => {
    return AxiosClient.delete(`${PATH}/${id}`);
  }
}
