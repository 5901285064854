import { ActionType, IAction } from '../../constants';

export const signin = (token: string, refreshToken: string): IAction =>{
  return {
    type: ActionType.SIGNED_IN,
    payload: {
      accessToken: token,
      refreshToken: refreshToken,
    }
  }
}

export const signout = (): IAction => {
  return{
    type: ActionType.SIGNED_OUT
  }
}
