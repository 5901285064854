import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './state';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { LanguageState } from './context/language.context';
import { ReloadState } from './context/reload.context';
import { CurrentUserState } from './context/user.context';
// Create a client
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <LanguageState>
        <CurrentUserState>
          <ReloadState>
            <App/>
          </ReloadState>
        </CurrentUserState>
      </LanguageState>
    </QueryClientProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
