import { AxiosClient } from './axios-client';
import { IAdminUser } from '../constants';

const path = 'admin/users';

export const AdminUserApi = {
  getAll: async () => {
    const res: IAdminUser[] = await AxiosClient.get(path);
    assertIsAdminUsers(res);
    return res;
  },
  create: (username: string,
    password:string,
    email: string,
    last_name: string,
    first_name: string,
    phone_mobile: string,
    address: string,
    status: string,
    agency_id: string | null = null,
    admin_users_roles: number[] = []) => {
    return AxiosClient.post(path, {
      username,
      password,
      email,
      last_name,
      first_name,
      phone_mobile,
      address,
      status,
      agency_id,
      admin_users_roles
    })
  },
  get: async (id: string | undefined) => {
    const res: IAdminUser = await AxiosClient.get(`${path}/${id}`);
    return res;
  },
  update: (id: any,
    first_name: string,
    last_name: string,
    phone_mobile: string,
    address: string,
    status: string,
    agency_id: string | null,
    admin_users_roles: number[] = []) => {
    return AxiosClient.patch(`${path}/${id}`, {
      first_name,
      last_name,
      phone_mobile,
      address,
      status,
      agency_id,
      admin_users_roles
    })
  },
  resetPassword: (id: any, password: string) => {
    return AxiosClient.patch(`${path}/${id}`, { password });
  },
  changePassword: (old_password: string, new_password: string) => {
    return AxiosClient.patch(`${path}/change-pw`, { old_password, new_password });
  },
  del: (id: number) => {
    return AxiosClient.delete(`${path}/${id}`);
  },
}

function assertIsAdminUsers(users: any): asserts users is IAdminUser[] {
  if (users === undefined) {
    throw new Error('Not users data');
  }
}
