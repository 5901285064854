import React, { useContext } from 'react';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Logout, Settings } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { signout } from '../../state';
import { LangContext } from '../../context/language.context';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../constants';
import { UserContext } from '../../context/user.context';

interface Props {
    anchorEl: any;
    onClose: () => void;
}

export const ProfileModal = (props: Props): JSX.Element => {
  const { dispatch: { setCurrentUser } } = useContext(UserContext);
  const { dispatch: { translate } } = useContext(LangContext);
  const navigate = useNavigate();
  const { anchorEl, onClose } = props;
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(signout());
    setCurrentUser(null);
  }

  const handleChangePW = () => {
    navigate(RoutePath.CHANGE_PW);
  }

  return(
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={onClose}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={handleChangePW}>
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        {translate('changePassword')}
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        {translate('logout')}
      </MenuItem>
    </Menu>
  );
}
