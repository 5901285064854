import { Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { PageHeader } from '../../../components/PageHeader';
import { DataTable } from '../../../components/DataTable';
import { ReservationApi } from '../../../api/reservation.api';
import { FilterBox } from '../components/FilterBox';
import { Toast } from '../../../components/Toast';
import Swal from 'sweetalert2';
import { format } from 'date-fns/esm';
import { columns } from '../constant/colums';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker } from '@mui/lab';
import { PaymentAPI } from '^/api';
import { ko } from 'date-fns/locale';

export const PPDReservation = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterCounter, setFilterCounter] = useState(0);
  const [offset, setOffset] = useState(0);
  const LIMIT_ROWS = 50;

  const [condition, setCondition] = useState({
    company: '',
    keyword: '',
    displayDate: '',
    ISOStartDate: '',
    ISOEndDate: '',
    date: [
      {
        startDate: new Date('2022-01-01T03:24:00'),
        endDate: new Date(),
        key: 'selection',
      },
    ],
  });
  useEffect(() => {
    fetchData(new Date('2022-01-01T03:24:00'), new Date(), offset);
  }, []);

  const formatData = (data: any[]) => {
    data.forEach((item) => {
      item['res_id'] = {
        res_id: item.res_id,
        customer_name: item.korean_name,
      };
      item['customer_name'] = {
        first_name: item.first_name,
        last_name: item.last_name,
      };
      item['pickup_office'] = {
        name: item.KO_PICKUP_DESC,
        name_en: item.EN_PICKUP_DESC,
        address: item.office_address,
        phone: item.office_phone,
      };
      item['return_office'] = {
        name: item.KO_RETURN_DESC,
        name_en: item.EN_RETURN_DESC,
        address: item.office_second_address,
        phone: item.office_second_phone,
      };
      item['contact_info'] = {
        email: item.email,
        phoneCode: item.phone_code,
        phoneNumber: item.phone_number,
      };
      item['insurance_name'] = {
        insurance_name: item.insurance,
        insurances_detail: item.insurances_detail,
      };
      item['flight_name'] = {
        flight_brand: item.flight_brand,
        flight_number: item.flight_number,
      };
      item['currency'] = {
        currency: item.currency,
        exchange: item.exchange_rate,
      };
    });
    return data;
  };

  const fetchData = async (startDate: Date, endDate: Date, offset: number) => {
    const res = await ReservationApi.getPPDAll(
      getDateString(startDate),
      getDateString(endDate),
    );
    if (res) {
      setData((prevState) => formatData(res.res));
      setTotalCount(res.res_num);
    }
    setLoading(false);
  };

  const onChange = (data: any) => {
    setCondition(data);
  };

  const handleFilter = async () => {
    setOffset(0);
    if (!condition?.company && !condition?.keyword && !condition?.ISOEndDate) {
      setLoading(true);
      setFilterCounter(filterCounter + 1);
      await fetchData(
        condition.date[0].startDate,
        condition.date[0].endDate,
        0,
      );
      setLoading(false);
      return;
    }
    setFilterCounter(filterCounter + 1);
    setLoading(true);
    const result = await ReservationApi.getPPDAll(
      getDateString(condition.date[0].startDate),
      getDateString(condition.date[0].endDate),
    );
    setData((prevState) => formatData(result.res));
    setTotalCount(result.res_num);
    setLoading(false);
  };

  const handleChangePage = async (page: number, rowsPerPage: number) => {
    if ((page + 1) * rowsPerPage > data.length && offset < totalCount) {
      setLoading(true);
      await fetchMore(data.length);
      setLoading(false);
    }
  };

  const handleRefresh = async (start_date: string, end_date: string) => {
    if (!condition?.ISOStartDate || !condition?.ISOEndDate) {
      await Toast.fire({
        title: '날짜를 선택해주세요.',
        icon: 'error',
      });
      return;
    }
    if (
      new Date(condition?.ISOEndDate).getDate() -
        new Date(condition?.ISOStartDate).getDate() +
        1 >
      7
    ) {
      await Toast.fire({
        title: '7일 이내의 기간만 선택 가능합니다.',
        icon: 'error',
      });
      return;
    }
    try {
      Swal.fire({
        title: '예약 현황을 업데이트 중입니다...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const response = await ReservationApi.getPPDAll(
        getDateString(condition.date[0].startDate),
        getDateString(condition.date[0].endDate),
      );
      // if (response.statusCode !== 200) {
      //   throw new Error(
      //     `statusCode: ${response.statusCode}\nmessage: ${
      //       response.error || ''
      //     } ${response.message}`,
      //   );
      // }
      Swal.close();
      Toast.fire({
        title: '예약 현황이 업데이트 되었습니다.',
        icon: 'info',
      });
    } catch (e: any) {
      Swal.fire({
        title: '예약 현황을 업데이트 하지 못했습니다.',
        text: e.message,
        icon: 'error',
      });
    } finally {
      setLoading(true);
      await fetchData(
        condition.date[0].startDate,
        condition.date[0].endDate,
        0,
      );
    }
  };

  const fetchMore = async (offset: number) => {
    let res: { res_num: number; res: any[] } = { res_num: 0, res: [] };
    if (condition?.company || condition?.keyword || condition?.ISOEndDate) {
      res = await ReservationApi.filter(
        condition.company,
        { startDate: condition.ISOStartDate, endDate: condition.ISOEndDate },
        condition.keyword,
        offset,
        LIMIT_ROWS,
      );
    } else {
      res = await ReservationApi.getAll(offset, LIMIT_ROWS);
    }
    if (res) {
      setData(data.concat(formatData(res.res)));
      setTotalCount(res.res_num);
      setOffset(data.length + res.res.length);
    }
  };

  const downloadPPDAccountingExcel = async (month: string) => {
    if(!month) {
      await Toast.fire({
        title: '정산할 월을 선택해주세요.',
        icon: 'error',
      });
      return;
    }
    try {
      const response = await PaymentAPI.downloadPPDAccountingExcel(month);
      // @ts-ignore
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const  objectUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = objectUrl;
      a.download = `PPD_Payment_${month}.xlsx`;
      a.type = 'text/css';
      a.click();
      a.remove();
    } catch (e) {
      console.log(e);
    }

  }

  const AccountingExcelDownload = () => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [inputValue, setInputValue] = useState<string>('');
  
    const handleChange = (newDate: Date | null) => {
      setSelectedDate(newDate);
      if (newDate) {
        const formattedDate = format(newDate, 'yyyy-MM');
        setInputValue(formattedDate);
      } else {
        setInputValue('');
      }
    };

    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignContent: 'center', marginBottom: '24px' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
          <DatePicker
            label="선불정산 월 선택"
            views={['year', 'month']}
            value={selectedDate}
            onChange={handleChange}
            openTo='month'
            renderInput={(params) => <TextField {...params} size="small" value={inputValue} />}
            minDate={new Date('2020-01-01')}
            maxDate={new Date()}
            inputFormat='yyyy-MM'
          />
        </LocalizationProvider>
        <Button sx={{ marginLeft: '6px' }} variant='contained' onClick={() => downloadPPDAccountingExcel(inputValue)}>선불정산 Excel 다운로드</Button>
      </div>
    )
  }

  return (
    <Box sx={{ flex: 1, maxWidth: '100vw' }}>
      <PageHeader 
        pageTitle="PPD Reservation" 
      />
      <AccountingExcelDownload />
      <FilterBox
        condition={condition}
        onChange={onChange}
        onFilter={handleFilter}
        onRefresh={handleRefresh}
      />
      <DataTable
        loading={loading}
        columns={columns}
        data={data}
        total={totalCount}
        onResetPage={filterCounter}
        onChangePage={handleChangePage}
        isRole={true}
      />
    </Box>
  );

  function getDateString(date: Date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return `${year}${month}${day}`;
  }
};
