import { AxiosClient } from './axios-client';
import { ICountry } from '../constants';
const path = 'countries';
export const CountriesApi = {
  getCountries: async (offset?: number, limit?: number, order?: 'asc' | 'desc') => {
    const response: any[] = await AxiosClient.get(`${path}/all`);
    return response;
  },
  addCountry: (data: ICountry)=>{
    return AxiosClient.post(`${path}`, data);
  },
  deleteCountry: (code: string)=>{
    return AxiosClient.delete(`${path}?code=${code}`);
  },
  getCountry: async (code: string)=>{
    const response: any = await AxiosClient.get(`${path}?code=${code}`);
    return response;
  },
  updateCountry: (country: ICountry)=>{
    const body = {
      name: country.name,
      code: country.code,
      code_3: country.code_3,
      continent: country.continent,
      currency: country.currency
    }
    return AxiosClient.patch(`${path}`, body);
  }
}
