import { Box } from '@mui/material';
import { PageHeader } from '../../../../components/PageHeader';
import { DataTable } from '../../../../components/DataTable';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { IAdminRole, IColumn, RoutePath } from '../../../../constants';
import { AdminRoleApi } from '../../../../api';
import Swal from 'sweetalert2';
import { CreateRoleDialog } from '../../components/CreateRoleDialog';
import { Toast } from '../../../../components/Toast';

const columns : IColumn[] = [
  {
    db_field: 'no',
    label: 'No.',
    minWidth: 70
  },
  {
    db_field: 'name',
    label: 'Name',
  },
  {
    db_field: 'description',
    label: 'Description',
  },
  {
    db_field: 'actions',
    label: '',
    minWidth: 50,
    align: 'right'
  }
];
export const AdminRoles = () => {
  const navigate = useNavigate();
  const [openCreate, setOpenCreate] = useState(false);
  const [updated, setUpdated] = useState(false);
  const { status, error, data } = useQuery<IAdminRole[], Error>(
    ['getRoles', updated],
    AdminRoleApi.getAll,
  );
  if (status === 'error') {
    return <div>{error?.message}</div>;
  }
  const handleDelete = (data: any) => {
    Swal.fire({
      title: 'Delete this role?',
      text: 'Are you sure to delete this role?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1976d2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await AdminRoleApi.del(data.id);
        setUpdated(!updated);
        await Toast.fire({
          icon: 'success',
          title: 'Deleted this role'
        })
      }
    })
  }
  const handleView = (data: any) => {
    navigate(`/${RoutePath.USER_ROLES}/${data.id}`);
  }
  return (
    <>
      <Box sx={{ flex: 1 }}>
        <PageHeader pageTitle='User Roles' actionTitle='New' onAction={() => setOpenCreate(true)}/>
        <Box sx={{ mt: 2 }}>
          {
            data ? <DataTable
              loading={status !== 'success'}
              columns={columns}
              data={data}
              onView={handleView}
              onDelete={handleDelete}
            />
              :
              <>Loading....</>
          }
        </Box>
        <CreateRoleDialog onSuccess={() => setUpdated(!updated)} open={openCreate} onClose={()=> setOpenCreate(false)}/>
      </Box>
    </>
  )
}
