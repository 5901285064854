import { Box, Button, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DataTable } from '../../../components/DataTable';
import { IColumn } from '../../../constants';
import { useEffect, useState } from 'react';
import { FAQApi } from '../../../api/faq.api';
import { useNavigate } from 'react-router-dom';
// import * from '../../../../public/'

//tab
import * as React from 'react';
//tab

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export const faqColumns: IColumn[] = [
  { db_field: 'no', label: '#' },
  {
    db_field: 'title',
    label: 'Title',
  },
  {
    db_field: 'status',
    label: 'Status',
    //   format: renderStatus,
  },
  {
    db_field: 'actions',
    label: 'Action',
  },
];

const mapFAQs = (dataIn: any[]) => {
  const dataOut: any[] = [];
  dataIn.forEach((rec, index) => {
    const row = {
      no: index,
      title: (
        <>
          <img src="./korean_flag.png" width={15} style={{ marginRight: 10 }} />
          {rec.question_kr}

          <br />
          <img src="./us_flag.png" width={15} style={{ marginRight: 10 }} />
          {rec.question_us}
        </>
      ),
      status: rec.status,
      action: 'action',
    };
    dataOut.push(row);
  });
  return dataOut;
};

export const FAQ = (): JSX.Element => {
  const [data, setData] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [rawData, setRawData] = useState<any[]>([]);

  const navigate = useNavigate();

  const handleOnView = (value: any) => {
    console.log('Viewing...', value);
    navigate('/faq/add_update_faq', { state: rawData[value.no] });
  };

  const handleOnDelete = (value: any) => {
    console.log('Deleting...', value);
  };

  const fetchData = async () => {
    const res = await FAQApi.getAll();
    if (res) {
      setRawData(res);
      setData(mapFAQs(res));
    }
    setLoaded(true);
  };

  const handleOnClickCreate = () => {
    navigate('/faq/add_update_faq', { state: null });
  };

  useEffect(() => {
    fetchData();
  }, [loaded]);

  return (
    <Box sx={{ flex: 1 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: grey[300],
          padding: 2,
          bgcolor: grey[100],
        }}
      >
        <Typography variant="h5">FAQ</Typography>
        <Button
          variant="outlined"
          size="small"
          startIcon={<AddCircleIcon />}
          onClick={handleOnClickCreate}
        >
          Create
        </Button>
      </Stack>
      <Stack
        sx={{
          backgroundColor: '#fff',
          borderWidth: 1,
          borderColor: grey[300],
          borderStyle: 'solid',
          padding: 2,
        }}
        borderColor={grey[300]}
      >
        <DataTable
          loading={false}
          columns={faqColumns}
          data={data}
          onView={handleOnView}
          onViewIcon={'ModeEditOutlined'}
          onDelete={handleOnDelete}
        />
      </Stack>
    </Box>
  );
};
