import React, { useContext, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { ICategory } from '../../../../constants';
import { CategoriesApi } from '../../../../api/categories.api';
import { Toast } from '../../../../components/Toast';
import { LangContext } from '../../../../context/language.context';

interface Props {
    open: boolean;
    onClose: () => void;
    onAddSuccess: () => void;
    categories?: ICategory[];
}

export const NewCategoryDialog = (props: Props) => {
  const { open, onClose, categories, onAddSuccess } = props;
  const { dispatch: { translate } } = useContext(LangContext);
  const [name, setName] = useState('');
  const [parent, setParent] = useState('');
  const [loading, setLoading] = useState(false);
  // const [parentOptions, setParentOptions] = useState<ICategory[]>([]);

  const handleSubmit = async () => {
    if (!name) {
      await  Toast.fire({
        title: 'Name is required',
        icon: 'warning'
      });
      return;
    }
    setLoading(true);
    CategoriesApi.addCategory(name, parent ? parseInt(parent) : null)
      .then(async (res: any) => {
        await Toast.fire({
          title: 'Added.',
          icon: 'success'
        });
        setLoading(false);
        onAddSuccess();
        onClose();
        setName('');
        setParent('');
      })
      .catch( async (err) => {
        await Toast.fire({
          title: err.message,
          icon: 'error'
        });
        setLoading(false);
      })
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{`${translate('new')} ${translate('category')}`}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Category name"
          type="text"
          fullWidth
          value={name}
          onChange={($event) => {
            setName($event.target.value)
          }}
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>{translate('category')}</InputLabel>
          <Select
            value={parent}
            label="Category"
            onChange={(e) => {
              setParent(e.target.value)
            }}
          >
            <MenuItem value="">
              <em>{translate('none')}</em>
            </MenuItem>
            {
              categories?.map((value, index, array) => {
                return (
                  <MenuItem key={index} value={value.id}> {value.name}</MenuItem>
                )
              })
            }
          </Select>
          <FormHelperText>{translate('optional')}</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>Cancel</Button>
        <Button disabled={loading} onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}
