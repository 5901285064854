import * as MuiIcons from '@mui/icons-material';
import { createElement } from 'react';
import Icon from '@material-ui/core/Icon';

interface Props {
  icon: string;
}
export const DynamicIcon = (props: Props) => {
  const { icon } = props;
  // @ts-ignore
  if (!MuiIcons[icon]){
    return <>NoIcon</>
  }
  return (
    <Icon>
      {
        // @ts-ignore
        createElement(MuiIcons[icon])
      }
    </Icon>
  )
}
