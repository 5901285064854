import { ILocale } from '../../../../constants';
import { Box, Input, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  },
  selectTableCell: {
    width: 60
  },
  tableCell: {
    width: 280,
    height: 40
  },
  input: {
    width: 480,
    height: 40
  }
}));

interface CustomTableCellProps{
  row: any;
  name: string;
  onChange: (value: any, row: any) => void;
}
const CustomTableCell = (props: CustomTableCellProps) => {
  const { row, name, onChange } = props;
  const classes = useStyles();
  const { isEditMode } = row;
  return (
    <TableCell align="left" className={classes.tableCell}>
      {isEditMode ? (
        <Input
          value={row[name]}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

interface ReadOnlyTableCellProps {
  row: any;
  name: string;
}
const ReadOnlyTableCell = (props: ReadOnlyTableCellProps) => {
  const { row, name } = props;
  const classes = useStyles();
  return (
    <TableCell align="left" className={classes.tableCell}>
      {row[name]}
    </TableCell>
  );
}

interface Props {
  loading: boolean;
  locales: ILocale[];
  tagColumnName: string;
  rows: any[];
  onChange: (rows: any[], changedRow: any) => void;
  onSave?: (row: any) => void;
  onAdd?: () => void;
  onDelete?: (rowId: any) => void;
  disableAdd: boolean;
  disableDelete: boolean;
  order:'asc' | 'desc';
  onRequestSort: (e: any, property: any) => void;
}

export const TranslationTable = (props: Props) => {
  const { loading, locales, tagColumnName, rows, onChange: onUpdate, onSave, onAdd, onDelete, disableAdd, disableDelete, order, onRequestSort } = props;
  const classes = useStyles();
  // const [loaded, setLoaded] = useState(false);
  // const [previous, setPrevious] = useState<any[]>([]);
  // useEffect(()=>{
  //   setLoaded(Boolean(locales.length && rows.length));
  // },[rows])

  // ************ EDIT/VIEW MODE actions****************
  // const updatePreviousList = (id: string) => {
  //   const selectedRow = rows.find(r=> r.id === id);
  //   if (!selectedRow.isEditMode){
  //     setPrevious(prevState => {
  //       prevState.push(selectedRow);
  //       return prevState;
  //     })
  //   }else{
  //     setPrevious(prevState => {
  //       prevState = prevState.filter(item => item.id !== id);
  //       return prevState;
  //     })
  //   }
  // }
  // const onToggleEditMode = (id: string) => {
  //   updatePreviousList(id);
  //   onUpdate(rows.map(row => {
  //     if (row.id === id) {
  //       return { ...row, isEditMode: !row.isEditMode };
  //     }
  //     return row;
  //   }), null);
  // };
  // const onRevert = (id: string) => {
  //   const previousRow = previous.find(r => r.id === id);
  //   const preRows = rows.map(r => {
  //     if (r.id === id){
  //       return previousRow;
  //     }
  //     return r;
  //   })
  //   onToggleEditMode(id);
  //   onUpdate(preRows);
  // };
  // const onSaveRow = (id: string) => {
  //   const row = rows.find(r=> r.id===id);
  //   onToggleEditMode(id);
  //   onSave(row);
  // }
  // ************ EDIT/VIEW MODE actions****************

  const onChange = (e: any, row: any) => {
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const changed = { ...row, [name]: value }
    const newRows = rows.map(row => {
      if (row.id === id) {
        return changed;
      }
      return row;
    });
    onUpdate(newRows, changed);
  };

  if (loading){
    return (
      <CircularProgress color="inherit" />
    )
  }
  return(
    <Table className={classes.table} aria-label="caption table">
      <TableHead>
        <TableRow>
          <TableCell align="left" >
            <IconButton
              aria-label="add"
              disabled={disableAdd}
              onClick={() => onAdd ? onAdd() : console.log('add')}
            >
              <AddIcon />
            </IconButton>
          </TableCell>
          <TableCell align="left">
            <TableSortLabel
              active={true}
              direction={order}
              onClick={(e) => onRequestSort(e, 'tag') }
            >
              <Typography variant="overline" display="block" gutterBottom>
                {tagColumnName}
              </Typography>
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            </TableSortLabel>
          </TableCell>
          {
            locales.map((locale)=> (
              <TableCell key={locale.id} align="left">
                <Typography variant="overline" display="block" gutterBottom>
                  {`${locale.name} (${locale.code})`}
                </Typography>
              </TableCell>
            ))
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(row => (
          <TableRow key={row.id}>
            <TableCell className={classes.selectTableCell}>
              <IconButton
                aria-label="delete"
                disabled={disableDelete}
                onClick={() => onDelete ? onDelete(row.id) : console.log('delete')}
              >
                <DeleteIcon />
              </IconButton>
              {/*{row.isEditMode ? (*/}
              {/*  <>*/}
              {/*    <IconButton*/}
              {/*      aria-label="done"*/}
              {/*      onClick={() => onSaveRow(row.id) }*/}
              {/*    >*/}
              {/*      <DoneIcon />*/}
              {/*    </IconButton>*/}
              {/*    <IconButton*/}
              {/*      aria-label="revert"*/}
              {/*      onClick={() => onRevert(row.id)}*/}
              {/*    >*/}
              {/*      <DoNotDisturbIcon />*/}
              {/*    </IconButton>*/}
              {/*  </>*/}
              {/*) : (*/}
              {/*  <>*/}
              {/*    <IconButton*/}
              {/*      aria-label="edit"*/}
              {/*      onClick={() => onToggleEditMode(row.id) }*/}
              {/*    >*/}
              {/*      <EditIcon />*/}
              {/*    </IconButton>*/}
              {/*    <IconButton*/}
              {/*      aria-label="delete"*/}
              {/*      disabled={disableDelete}*/}
              {/*      onClick={() => onDelete(row.id) }*/}
              {/*    >*/}
              {/*      <DeleteIcon />*/}
              {/*    </IconButton>*/}
              {/*  </>*/}
              {/*)}*/}
            </TableCell>
            <ReadOnlyTableCell {...{ row, name: 'tag' }} />
            {
              locales.map((locale) => (
                <CustomTableCell key={locale.id} {...{ row, name: locale.code, onChange }} />
              ))
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
