import {
  Box,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { headerColumnRows, paymentColumns } from '../constants';
import { PaymentDataTable } from '../components/PaymentDataTable';
import { PaymentAPI } from '../../../api';
import { PageHeader } from '../../../components/PageHeader';
import { FilterBox } from '../components/FilterBox';
import { addDays, format } from 'date-fns';
import { Toast } from '../../../components/Toast';
import { ReloadContext } from '../../../context/reload.context';
import { UserContext } from '^/context/user.context';

export const PaymentTable = (): JSX.Element => {
  const { state } = useContext(ReloadContext);
  const [condition, setCondition] = useState({
    company: '',
    keyword: '',
    displayDate: '',
    ISOStartDate: '',
    ISOEndDate: '',
    date: [
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        key: 'selection',
      },
    ],
  });
  const [arr, setArr] = useState<any[]>([]);
  const [updateCounter, setUpdateCounter] = useState(0);
  const LIMIT_ROWS = 50;
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const formatData = (data: any[]) => {
    data.forEach((item)=> {
      if (!condition?.company && !condition?.keyword && !condition?.ISOEndDate){
        item['reservation_status'] = item?.res_status ? item.res_status : 'None';
      } else {
        item['reservation_status'] = item?.reservation ? item.reservation.res_status : 'None';
      }
      
      item['korean_name'] = {
        korean_name: item.korean_name,
        first_name: item.first_name,
        last_name: item.last_name,
      };
      item['currency'] = {
        currency: item.currency,
        exchange: item.exchange_rate,
      };
      //map value: pg amount and pay type
      item['payment_gateway_amount'] = {
        amount: item.pg_amount,
        type: item.pay_type,
      };
      item['service_total_amount'] = {
        service_total_amount: item.service_total_amount,
        service_rate: item.service_rate,
        etc_rate: item.etc_rate,
      };
      item['paid_supplier'] = {
        paid_supplier: item.paid_supplier,
        id: item.order_id,
      }
      item['paid_supplier_date'] = {
        paid_supplier_date: item.paid_supplier_date,
        id: item.order_id,
      }
      item['paid_agency_date'] = {
        paid_agency_date: item.paid_agency_date,
        id: item.order_id,
      }
    });
    return data;
  }
  const fetchData = async (offset: number) => {
    const res = await PaymentAPI.list(offset, LIMIT_ROWS);
    setTotal(res.payment_num);
    setArr( prevState => formatData(res.res));
  }

  useEffect(()=> {
    fetchData(0);
  },[state])

  const onChange = (data: any) => {
    setCondition(data);
  };

  const handleFilter = async () => {
    setOffset(0);
    if (!condition?.company && !condition?.keyword && !condition?.ISOEndDate){
      await fetchData(0);
      return;
    }
    const result = await PaymentAPI.filter(
      condition.company,
      { startDate: condition.ISOStartDate, endDate: condition.ISOEndDate },
      condition.keyword,
      0, LIMIT_ROWS,
    );
    setTotal(result.payment_num);
    setArr((prevState)=> {
      return formatData(result.res);
    });
  };
  const handleCheckPayment = async (orderId: string) => {
    try{
      const res = arr.filter((item)=> item.order_id === orderId);
      await PaymentAPI.update(orderId, {
        pay_to_supplier: res[0].pay_to_supplier === 1 ? 0 : 1,
      });
      setUpdateCounter(updateCounter + 1);
      await Toast.fire({
        title: 'Updated',
        icon: 'success',
      });
    }catch (e) {
      await Toast.fire({
        title: 'Something wrong happened, coupon could not be updated.',
        icon: 'warning',
      });
      return;
    }
  }
  const handleChangePage = async (page: number, rowsPerPage: number) => {
    if ((page+1) * rowsPerPage > arr.length && offset < total){
      setOffset(arr.length);
      fetchMore(arr.length);
    }
  }
  const fetchMore = async (offset: number) => {
    let res: {payment_num: number; res: any[]} = { payment_num: 0, res : [] };
    if (condition?.company || condition?.keyword || condition?.ISOEndDate){
      res = await PaymentAPI.filter(
        condition.company,
        { startDate: condition.ISOStartDate, endDate: condition.ISOEndDate },
        condition.keyword,
        offset, LIMIT_ROWS,
      );
    }else{
      res = await PaymentAPI.list(offset, LIMIT_ROWS);
    }
    if (res) {
      setArr(arr.concat(formatData(res.res)));
      setTotal(res.payment_num);
    }
  }

  const { state: { agency_id } } = useContext(UserContext);

  const excelExport = async () => {
    Toast.fire({
      title: 'The data is extracting...',
      icon: 'info'
    });

    const response = await PaymentAPI.download(
      condition.company,
      { startDate: condition.ISOStartDate, endDate: condition.ISOEndDate },
      condition.keyword,
      agency_id
    );

    // @ts-ignore
    const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const  objectUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = objectUrl;
    a.download = `Payment-${format(new Date(), 'yy-MM-dd')}.xlsx`;
    a.type = 'text/css';
    a.click();
    a.remove();
  };
  return (
    <Box sx={{ flex: 1, maxWidth: '100vw' }}>
      <PageHeader pageTitle={'paymentManagement'} actionTitle={'Export Excel'} onAction={excelExport} />
      <FilterBox
        condition={condition}
        onChange={onChange}
        onFilter={handleFilter}
      />
      <PaymentDataTable
        headerRows={headerColumnRows}
        columns={paymentColumns}
        data={arr}
        total={total}
        setCheckPayment={handleCheckPayment}
        onChangePage={handleChangePage}
      ></PaymentDataTable>
    </Box>
  );
};
