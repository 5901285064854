import React, { useReducer, FC, ReactNode, createContext } from 'react';
import { ActionType } from '../constants';
import { UserReducer } from '../state/reducers/user.reducer';

interface ContextProps {
  state: any;
  dispatch: {
    setCurrentUser: (data: any) => void;
  }
}
export const UserContext = createContext({} as ContextProps);

const initialState = null;

interface UserStateProps {
  children: ReactNode;
}
export const CurrentUserState: FC<UserStateProps> = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const setCurrentUser = (userData: any) => {
    dispatch({
      type: ActionType.SET_USER,
      payload: userData,
    });
  }

  return(
    <UserContext.Provider value={{ state, dispatch: { setCurrentUser } }}>
      {children}
    </UserContext.Provider>
  );
}
