import {
  Button,
  Stack,
  Typography,
  Box,
  TextField,
} from '@mui/material';
import { lightBlue } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DropdownSearch } from '../../../../components/DropdownSearch';
import { IOptionItem, KeyValue } from '../../../../constants';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import CheckboxList from '../../components/CheckboxList';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import '../../styles/coupon.css';
import { Toast } from '../../../../components/Toast';
import { ICoupon, CouponApi } from '../../../../api/coupon.api';
import { ICoupon as ICoupon2 } from '../../constants';

const couponTypeOptions: IOptionItem[] = [
  { value: 'Promotion Coupon', label: 'Promotion Code#' },
  { value: 'CDP', label: 'CDP#' },
];

const couponStatusOptions: IOptionItem[] = [
  { value: 'Inactive', label: 'Inactive' },
  { value: 'Active', label: 'Active' },
];

export const brandsList: KeyValue = {
  'FF': 'Firefly',
  'ZT': 'Thrifty',
  'ZR': 'Dollar',
  'ZE': 'Hertz',
};

export const countriesList: KeyValue = {
  'US': 'USA',
  'CA': 'Canada',
  'GB': 'United Kingdom',
  'DE': 'Germany',
  'IT': 'Italy',
  'FR': 'France',
  'NL': 'Netherlands',
  'ES': 'Spain',
  'LU': 'Luxembourg',
  'AU': 'Australia',
  'NZ': 'New Zealand',
  'BN': 'Brunei',
  'HK': 'Hong Kong',
  'MY': 'Malaysia',
  'PK': 'Pakistan',
  'PH': 'Philippines',
  'SG': 'Singapore',
  'TW': 'Taiwan',
  'VN': 'Vietnam',
  'KH': 'Cambodia',
  'GU': 'Guam',
  'LK': 'Sri Lanka',
  'TH': 'Thailand',
  'BE': 'Belgium',
};

const carGroupList: string[] = [
  'Mini',
  'Subcompact',
  'Economy',
  'Compact',
  'Midsize',
  'Intermediate',
  'Standard',
  'Fullsize',
  'Luxury',
  'Premium',
  'Special',
  'Van',
  'Convertible',
];

export const AddUpdateCoupon = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const [id, setId] = useState(0);
  const [couponType, setCouponType] = useState(couponTypeOptions[0].value);
  const [couponName, setCouponName] = useState('');
  const [description, setDescription] = useState('');
  const [corpDiscountNumber, setCorpDiscountNumber] = useState('');
  const [promotionCode, setPromotionCode] = useState('');
  const [orderStartTime, setOrderStartTime] = useState<Date | null>(
    // new Date('2022-08-01T00:00:00.000Z'),
    new Date(),
  );
  const [orderEndTime, setOrderEndTime] = useState<Date | null>(new Date());
  const [pickupTime, setPickupTime] = useState<Date | null>(new Date());
  const [pickupEndTime, setPickupEndTime] = useState<Date | null>(new Date());
  const [countriesCheckedList, setCoutriesCheckedList] = useState<number[]>([]);
  const [brandsCheckedList, setBrandsCheckedList] = useState<number[]>([]);
  const [carGroupCheckedList, setCarGroupCheckedList] = useState<number[]>([]);
  const [countriescl, setCoutriescl] = useState<number[]>();
  const [brandscl, setBrandscl] = useState<number[]>();
  const [carGroupcl, setCarGroupcl] = useState<number[]>();
  const [issuedCount, setIssuedCount] = useState(0);
  const [minDays, setMinDays] = useState(0);
  const [couponStatus, setCouponStatus] = useState('Inactive');
  const [remark, setRemark] = useState('');

  const handleSave = async () => {
    // Validate
    if (
      !couponName ||
      !corpDiscountNumber ||
      !promotionCode ||
      // brandsCheckedList.length == 0 ||
      // countriesCheckedList.length == 0 ||
      // carGroupCheckedList.length == 0 ||
      !orderStartTime ||
      !orderEndTime ||
      !pickupTime ||
      !pickupEndTime
    ) {
      // fill more fields
      await Toast.fire({
        title: 'Required fields must be filled out.',
        icon: 'warning',
      });
      return;
    } else if (orderEndTime < orderStartTime ||
      pickupEndTime < pickupTime ||
      pickupTime < orderStartTime ||
      pickupEndTime < orderEndTime){
      await Toast.fire({
        title: 'End date must be greater than start date',
        icon: 'warning',
      });
      return;
    }  else {
      // start to call api to save coupon
      //mapping
      const pListBrands: string[] = [];
      brandsCheckedList.forEach((index) => {
        const brandKey = Object.keys(brandsList)[index];
        pListBrands.push(brandKey);
      });
      const pListCountries: string[] = [];
      countriesCheckedList.forEach((index) => {
        const countryKey = Object.keys(countriesList)[index];
        pListCountries.push(countryKey);
      });
      const pListCarGroup: string[] = [];
      carGroupCheckedList.forEach((index) => {
        pListCarGroup.push(carGroupList[index]);
      });

      let data: ICoupon = {
        couponType: couponType,
        couponName: couponName,
        description: description,
        corpDiscountNumber: corpDiscountNumber,
        promotionCode: promotionCode,
        issuedCount: issuedCount,
        minDays: minDays,
        listBrands: pListBrands,
        listCountries: pListCountries,
        carGroup: pListCarGroup,
        orderStartTime: orderStartTime,
        orderEndTime: orderEndTime,
        pickupTime: pickupTime,
        pickupEndTime: pickupEndTime,
        status: couponStatus,
        remark: remark,
      };
      // update
      if (id > 0) {
        data = { ...data, id };
      }
      try {
        await CouponApi.addCoupon(data);
        await Toast.fire({
          title: 'Coupon saved',
          icon: 'success',
        });
        return;
      } catch (e) {
        console.log(e);
        await Toast.fire({
          title: 'Something wrong happened, coupon could not be saved.',
          icon: 'warning',
        });
        return;
      }
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  // mount component and try to load the initial value for state
  useEffect(() => {
    if (location.state) {
      const o: any = location.state;
      setCouponType(o.type);
      setCouponName(o.coupon_name);
      setDescription(o.offer_description);
      setCorpDiscountNumber(o.corporate_discount_number);
      setPromotionCode(o.promotion_code);
      setIssuedCount(o.issued_count);
      setMinDays(o.min_days);
      setId(o?.id ? o.id: -1);
      const brands = o.brand.split(',');
      const countries = o.destination.split(',');
      const cars = o.car_group.split(',');
      const brands_checked_arr: number[] = [];
      const countries_checked_arr: number[] = [];
      const cars_checked_arr: number[] = [];

      brands.forEach((b: any) => {
        const index = Object.keys(brandsList).indexOf(b);
        if (index !== -1) {
          brands_checked_arr.push(index);
        }
      });
      setBrandsCheckedList(brands_checked_arr);
      setBrandscl(brands_checked_arr);
      // console.log('brands', brandsCheckedList);

      countries.forEach((b: any) => {
        const index = Object.keys(countriesList).indexOf(b);
        if (index !== -1) {
          countries_checked_arr.push(index);
        }
      });
      setCoutriesCheckedList(countries_checked_arr);
      setCoutriescl(countries_checked_arr);

      cars.forEach((b: any) => {
        const index = carGroupList.indexOf(b);
        if (index !== -1) {
          cars_checked_arr.push(index);
        }
      });
      setCarGroupCheckedList(cars_checked_arr);
      setCarGroupcl(cars_checked_arr);
    } else {
      console.log('No state');
    }
  }, []);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Add/Update</Typography>
        <Stack direction="row">
          <Button
            variant="contained"
            size="small"
            sx={{ mx: 1, px: 4 }}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ px: 4 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
      <Box
        sx={{ width: 1, height: 10, backgroundColor: lightBlue[500], mt: 2 }}
        mb={3}
      ></Box>
      <Stack sx={{ px: 2 }} spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <Stack sx={{ width: 1 / 2, pr: 1 }}>
            <Typography variant="subtitle1">Coupon Type<span style={{ color: '#ff3b3b' }}>*</span> :</Typography>
            <DropdownSearch
              value={couponType}
              options={couponTypeOptions}
              placeholder={'please select'}
              onSelect={(option) => {
                setCouponType(option.value);
              }}
            />
          </Stack>

          <Stack sx={{ width: 1 / 2, pl: 1 }}>
            <Typography variant="subtitle1">Coupon Name<span style={{ color: '#ff3b3b' }}>*</span>:</Typography>

            <TextField
              size="small"
              type="text"
              fullWidth
              value={couponName}
              onChange={(event) => {
                setCouponName(event.target.value);
              }}
              sx={{ mt: 0, bgcolor: 'background.paper' }}
            />
          </Stack>
        </Stack>

        <Stack sx={{}}>
          <Typography variant="subtitle1">Description:</Typography>
          <TextareaAutosize
            aria-label="offer description"
            placeholder="Please enter description"
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            // css style imported from css file
          />
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Stack sx={{ width: 1/2 }}>
            <Typography variant="subtitle1">CorpDiscountNmbr <span style={{ color: '#ff3b3b' }}>*</span>:</Typography>
            <TextField
              size="small"
              type="text"
              fullWidth
              value={corpDiscountNumber}
              onChange={(event) => {
                setCorpDiscountNumber(event.target.value);
              }}
              sx={{ mt: 0, bgcolor: 'background.paper' }}
            />
          </Stack>
          <Stack sx={{ width: 1 / 2, pl: 1 }}>
            <Typography variant="subtitle1">Promotion Code <span style={{ color: '#ff3b3b' }}>*</span>:</Typography>
            <TextField
              size="small"
              type="text"
              fullWidth
              value={promotionCode}
              onChange={(event) => {
                setPromotionCode(event.target.value);
              }}
              sx={{ mt: 0, bgcolor: 'background.paper' }}
            />
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Stack sx={{ width: 1 / 2 }}>
            <Typography variant="subtitle1">Issued Count :</Typography>
            <TextField
              size="small"
              type="number"
              fullWidth
              value={issuedCount}
              onChange={(event) => {
                const n = parseInt(event.target.value);
                if (n < 0) setIssuedCount(0);
                else setIssuedCount(n);
              }}
              sx={{ mt: 0, bgcolor: 'background.paper' }}
            />
          </Stack>
          <Stack sx={{ width: 1 / 2, pl: 1 }}>
            <Typography variant="subtitle1">Min Days :</Typography>
            <TextField
              size="small"
              type="number"
              fullWidth
              value={minDays}
              onChange={(event) => {
                const n = parseInt(event.target.value);
                if (n < 0) setMinDays(0);
                else setMinDays(n);
              }}
              sx={{ mt: 0, bgcolor: 'background.paper' }}
            />
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Stack sx={{ width: 1 / 2, pr: 1 }}>
            <Typography variant="subtitle1">Coupon Available Brands :</Typography>
            <CheckboxList
              listItems={Object.values(brandsList)}
              onChange={(checkList) => {
                console.log(checkList);
                setBrandsCheckedList(checkList);
              }}
              initialChecked={brandscl}
            />
          </Stack>

          <Stack sx={{ width: 1 / 2, pl: 1 }}>
            <Typography variant="subtitle1">Coupon Use Country :</Typography>
            <CheckboxList
              listItems={Object.values(countriesList)}
              onChange={(checkList) => {
                console.log(checkList);
                setCoutriesCheckedList(checkList);
              }}
              initialChecked={countriescl}
            />
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          {/* Order and pickup/return time */}
          <Stack direction="column" width={1 / 2} pr={1} spacing={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack>
                <Typography variant="subtitle1">Order Start Time <span style={{ color: '#ff3b3b' }}>*</span>:</Typography>
                <DesktopDateTimePicker
                  // label="For desktop"
                  value={orderStartTime}
                  onChange={(newValue) => {
                    setOrderStartTime(newValue);
                  }}
                  inputFormat="dd-MM-yyyy hh:mm a"
                  minutesStep={5}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle1">Order End Time <span style={{ color: '#ff3b3b' }}>*</span>:</Typography>
                <DesktopDateTimePicker
                  value={orderEndTime}
                  onChange={(newValue) => {
                    setOrderEndTime(newValue);
                  }}
                  minDateTime={orderStartTime}
                  inputFormat="dd-MM-yyyy hh:mm a"
                  minutesStep={5}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>

              <Stack>
                <Typography variant="subtitle1">Pick-Up Time <span style={{ color: '#ff3b3b' }}>*</span>:</Typography>
                <DesktopDateTimePicker
                  value={pickupTime}
                  onChange={(newValue) => {
                    setPickupTime(newValue);
                  }}
                  minDateTime={orderStartTime}
                  inputFormat="dd-MM-yyyy hh:mm a"
                  minutesStep={5}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle1">Pick-Up End Time <span style={{ color: '#ff3b3b' }}>*</span>:</Typography>
                <DesktopDateTimePicker
                  // label="For desktop"
                  value={pickupEndTime}
                  onChange={(newValue) => {
                    setPickupEndTime(newValue);
                  }}
                  minDateTime={pickupTime}
                  inputFormat="dd-MM-yyyy hh:mm a"
                  minutesStep={5}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Stack>
          <Stack sx={{ width: 1 / 2, pl: 1 }}>
            <Typography variant="subtitle1">Car Group :</Typography>
            <CheckboxList
              listItems={carGroupList}
              onChange={(checkList) => {
                console.log(checkList);
                setCarGroupCheckedList(checkList);
              }}
              initialChecked={carGroupcl}
            />
          </Stack>
        </Stack>

        <Stack sx={{ width: 1 / 2, pr: 1 }}>
          <Typography variant="subtitle1">Status <span style={{ color: '#ff3b3b' }}>*</span>:</Typography>
          <DropdownSearch
            value={couponStatus}
            options={couponStatusOptions}
            placeholder={'please select'}
            onSelect={(option) => {
              console.log(option);
              setCouponStatus(option.value);
            }}
          />
        </Stack>
        <Stack sx={{}}>
          <Typography variant="subtitle1">Remark :</Typography>
          <TextareaAutosize
            aria-label="Remark"
            placeholder="Please enter remark"
            value={remark}
            onChange={(event) => {
              setRemark(event.target.value);
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};
