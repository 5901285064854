export interface ILocale {
    id: number;
    name: string;
    code: string;
    default: boolean;
}

export enum LocaleDBFields{
    id='id',
    name='name',
    code='code',
    default='default'
}

export enum Languages {
  KO='ko',
  EN='en'
}
