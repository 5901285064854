import { AxiosClient } from './axios-client';
import axios from 'axios';

const path = 'admin';

export const AuthApi = {
  signIn: async (username: string, password: string) => {
    const body = {
      username,
      password
    }
    const headers = {
      'content-type': 'application/json',
      'x-api-key': process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : '',
    };
    return axios.post(`${process.env.REACT_APP_API_URL}/${path}/sign-in`, body, { headers });
  },
  getMe: async () => {
    try{
      const result = await AxiosClient.get(`${path}/me`);
      return result;
    }catch (e){
      return null;
    }
  },
  refreshTokens: async (refreshToken: string) => {
    const headers = {
      'content-type': 'application/json',
      'x-api-key': process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : '',
      'Authorization': `Bearer ${refreshToken}`
    };
    try{
      const tokens = await axios.post(`${process.env.REACT_APP_API_URL}/${path}/refresh`, {}, { headers: headers });
      return tokens.data;
    }catch (e) {
      return null;
    }
  },
  signOut: async (userId: number) => {
    try{
      await AxiosClient.post(`${path}/sign-out?id=${userId}`);
      return true;
    }catch (e) {
      return false;
    }
  }
}
