import { Box, Card, CardActions, CardContent, FormControl, Grid, InputAdornment, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TranslationTable } from '../TranslationTable';
import { ILocale, IOptionItem } from '../../../../constants';
import { CitiesApi, CountriesApi, ErrorMessageApi, LocationsApi, TranslationApi } from '../../../../api';
import { Toast } from '../../../../components/Toast';
import { AddTagDialog } from '../AddTagDialog';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import Button from '@mui/material/Button';
import { DropdownSearch } from '../../../../components/DropdownSearch';
import { useQuery } from 'react-query';
import { getComparator, Order, stableSort, uniqByKeepFirst } from '../../utils';

const uuid = require('uuid');
const ROWS_IN_PAGE = 50;
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
interface CustomPaginationProps {
  totalRows: number;
  page: number;
  onChange: (event: any, page: number) => void;
}
const CustomPagination = (props: CustomPaginationProps) => {
  const { onChange, page, totalRows } = props;
  return (
    <Stack spacing={2}>
      <Pagination
        page={page}
        count={totalRows}
        onChange={onChange}
        renderItem={(item) => (
          <PaginationItem
            components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack>
  )
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const createRowData = (rawData: any) => {
  return {
    id: uuid.v4(),
    isEditMode: true, // alway enable edit mode
    ...rawData
  };
};
const TRANSLATION_TABS = [
  { id: 0, key: 'products', display: 'Products', table: '' },
  { id: 1, key: 'errors', display: 'Errors', table: '' },
  { id: 2, key: 'location', display: 'Location', table: 'location_description' },
  { id: 3, key: 'city', display: 'City', table: 'cities_translation' },
  // { id: 4, key: 'general', display: 'General', table: 'translation' },
]
export const TranslationTabTable = () => {
  const [tab, setTab] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [addCount, setAddCount] = useState(0);
  const [locales, setLocales] = useState<ILocale[]>([]);
  const [originalRows, setOriginalRows] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [changedRows, setChangedRows] = useState<any[]>([]);
  const [openTrans, setOpenTrans] = useState(false);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [saving, setSaving] = useState(false);
  const [keyword, setKeyWord] = useState('');
  const [filter, setFilter] = useState(false);
  //filter options
  const fetchCountriesOptions = async () => {
    const result = await CountriesApi.getCountries();
    const options: IOptionItem[] = [];
    result.forEach((value) => {
      options.push({
        value: value.code,
        label: value.name
      })
    });
    return options;
  }
  const { data: countries, status: countryStatus, error } = useQuery<IOptionItem[], Error>(['getCountriesOptions'], fetchCountriesOptions, { retry: 1 });

  const [country, setCountry]= useState('');

  const [categories, setCategories] = useState<IOptionItem[]>([
    { value: 'LAP', label: 'Airport' },
    { value: 'LHT', label: 'Hotel' },
    { value: 'LDT', label: 'City/Downtown' },
    { value: 'LAM', label: 'Train Station' },
  ])
  const [category, setCategory]= useState('');

  // sort state
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] =  useState('tag');
  const handleRequestSort = (event:any, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }
  useEffect(()=> {
    fetchLocales();
    fetchRows(tab);
  },[addCount])
  const fetchLocales = async () => {
    const localesRes = await TranslationApi.getLocales();
    setLocales(localesRes);
  }

  useEffect(()=> {
    const r = stableSort(rows, getComparator(order, orderBy));
    setRows(r);
  },[order]);

  // useEffect(()=> {
  //   // for locations: filter duplicate OAG CODE
  //   if (tab === 2){
  //     const filteredRows = uniqByKeepFirst(rows, (it: any) => it.tag);
  //     setPagination(filteredRows.length);
  //     setRowsInAPage(filteredRows.slice((page - 1) * ROWS_IN_PAGE, (page - 1) * ROWS_IN_PAGE + ROWS_IN_PAGE));
  //   }
  // }, [rows, page]);

  const updateChangedRow = (changedRows:  any[]) => {
    setRows((prevState)=> {
      changedRows.forEach((r)=> {
        const index = prevState.findIndex((item) => item.id === r.id);
        if (index > -1 ) prevState[index] = r;
      });
      return prevState;
    })
    setOriginalRows((prevState)=> {
      changedRows.forEach((r)=> {
        const index = prevState.findIndex((item) => item.id === r.id);
        if (index > -1 ) prevState[index] = r;
      });
      return prevState;
    })
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setRows([]);
    setKeyWord('');
    setSkip(0);
    setPage(1);
    setTab(newValue);
    fetchRows(newValue);
    setChangedRows([]);
    setCountry('');
    setCategory('');
  };
  const setPagination = (count: number) => {
    if( count % ROWS_IN_PAGE === 0) {
      setTotalRows(count / ROWS_IN_PAGE);
    }else{
      setTotalRows(Math.ceil(count / ROWS_IN_PAGE));
    }
  }
  const fetchRows = async (value: number) => {
    setLoaded(false);
    switch (value) {
    case 0:
      break;
    case 1:
      await fetchErrorsTranslation();
      break;
    case 2:
      await fetchLocationsTranslation();
      break;
    case 3:
      await fetchCitiesTranslation();
      break;
    case 4:
      await fetchTagsTranslation();
      break;
    default:
    }
    setLoaded(true);
  }
  const fetchErrorsTranslation = async () => {
    const messages = await ErrorMessageApi.list(skip, ROWS_IN_PAGE);
    setOriginalRows(messages.tags.map(msg => (createRowData(msg))));
    setRows(messages.tags.map(msg => (createRowData(msg))));
    setPagination(messages.count);
  }
  const fetchTagsTranslation = async () => {
    const messages = await TranslationApi.getTranslationMessage();
    setOriginalRows(messages.tags.map(msg => (createRowData(msg))));
    setRows(messages.tags.map(msg => (createRowData(msg))));
    setPagination(messages.count);
  }
  const fetchLocationsTranslation = async () =>{
    const descriptions = await LocationsApi.getDescriptionTranslation(skip, ROWS_IN_PAGE);
    setOriginalRows(descriptions.tags.map(msg => (createRowData(msg))));
    setRows(descriptions.tags.map(msg => (createRowData(msg))));
    setPagination(descriptions.count);
  }
  const fetchCitiesTranslation = async () =>{
    const cities = await CitiesApi.getTranslation(skip, ROWS_IN_PAGE);
    setRows(cities.tags.map(msg => (createRowData(msg))));
    setOriginalRows(cities.tags.map(msg => (createRowData(msg))));
    setPagination(cities.count);
  }

  const handleChange = (data: any[], changedRow: any) => {
    setChangedRows(prevState => {
      const index = prevState.findIndex((item) => item.id === changedRow.id);
      if (index > -1){
        prevState[index] = changedRow;
      }else{
        prevState.push(changedRow);
      }
      return prevState;
    })
    setRows(data);
  }

  const handleAddTranslation = (tag: string, message: string, locale: string) => {
    setOpenTrans(false);
    TranslationApi.addTagTranslation(tag, locale, message)
      .then(async (res: any)=> {
        setAddCount(addCount+1);
        await Toast.fire({
          title: 'Added.',
          icon: 'success'
        });
      })
      .catch(async (err)=>{
        await Toast.fire({
          title: err.message,
          icon: 'error'
        });
      })
  }

  // const handleDelete = (rowId: string) => {
  //   const row = rows.find(r => r.id === rowId);
  //   TranslationApi.deleteTag(row.tag)
  //     .then(async (res) => {
  //       setAddCount(addCount+1);
  //       await Toast.fire({
  //         title: 'Deleted.',
  //         icon: 'success'
  //       });
  //
  //     })
  //     .catch(async (err) => {
  //       await Toast.fire({
  //         title: err.message,
  //         icon: 'error'
  //       });
  //     })
  // }

  useEffect(()=> {
    if (!country && !category && !keyword){
      fetchRows(tab);
    }else{
      filterRows(tab);
    }
  },[skip, filter])
  const filterRows = async (value: number) => {
    setLoaded(false);
    let res: any;
    switch (value) {
    case 0:
      break;
    case 1:
      res = await ErrorMessageApi.filter(skip, ROWS_IN_PAGE, keyword);
      break;
    case 2:
      res = await LocationsApi.filterDescriptionTranslation(skip, ROWS_IN_PAGE, { keyword, country_code: country, category_id: category });
      break;
    case 3:
      res = await CitiesApi.filterTranslation(skip, ROWS_IN_PAGE, { keyword, country_code: country });
      break;
    case 4:
      // await fetchTagsTranslation();
      break;
    default:
    }
    setRows(res.tags.map((msg: any) => (createRowData(msg))));
    setPagination(res.count);
    setLoaded(true);
  }
  const handleChangePage = async (event: any, page: number) => {
    setPage(page);
    setSkip((page - 1) * ROWS_IN_PAGE);
    setChangedRows([]);
  }

  const handleMultiSave = async () => {
    setSaving(true);
    if (tab === 1) {
      const data1 = changedRows.map((item) => (
        {
          error_code: item.tag,
          english_message: item.en,
          korean_message: item.ko,
        }
      ));
      try {
        await ErrorMessageApi.updateMultiple(data1);
        updateChangedRow(changedRows);
        await Toast.fire({
          icon: 'success',
          title: 'Updated'
        });
        setChangedRows([]);
        setSaving(false);
      } catch (e: any) {
        await Toast.fire({
          icon: 'error',
          title: e.message
        });
        setSaving(false);
      }
    } else if (tab === 2) {
      let data1: any[] = [];
      changedRows.forEach((row) => {
        const t = locales.map((locale) => (
          {
            locale: locale.code,
            location: row.tag,
            description: row[locale.code]
          }
        ))
        data1 = data1.concat(t);
      })
      try{
        await LocationsApi.updateDescriptionTranslation(data1);
        updateChangedRow(changedRows);
        await Toast.fire({
          icon: 'success',
          title: 'Updated'
        });
        setChangedRows([]);
        setSaving(false);
      }catch (e:any) {
        await Toast.fire({
          icon: 'error',
          title: e.message
        });
        setSaving(false);
      }
    } else if (tab === 3) {
      let data1: any[] = [];
      changedRows.forEach((row) => {
        const t = locales.map((locale) => (
          {
            locale: locale.code,
            city_code: row.tag,
            city_name: row[locale.code]
          }
        ))
        data1 = data1.concat(t);
      })
      try{
        await CitiesApi.updateTranslation(data1);
        updateChangedRow(changedRows);
        await Toast.fire({
          icon: 'success',
          title: 'Updated'
        });
        setChangedRows([]);
        setSaving(false);
      }catch (e:any) {
        await Toast.fire({
          icon: 'error',
          title: e.message
        });
        setSaving(false);
      }
    } else if (tab === 4) {
      let data: any[] = [];
      changedRows.forEach((row) => {
        const t = locales.map((locale) => (
          {
            locale: locale.code,
            tag: row.tag,
            message: row[locale.code]
          }
        ))
        data = data.concat(t);
      })
      try{
        await TranslationApi.updateMultipleTranslation(data);
        updateChangedRow(changedRows);
        await Toast.fire({
          icon: 'success',
          title: 'Updated'
        });
        setChangedRows([]);
        setSaving(false);
      }catch (e:any) {
        await Toast.fire({
          icon: 'error',
          title: e.message
        });
        setSaving(false);
      }
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleFilter();
    }
  }
  const handleFilter = () => {
    setSkip(0);
    setPage(1);
    setFilter(!filter);
  }

  const handleTranslateApply = async () => {
    try {
      const response = await TranslationApi.apply();

      if (response.status === 200 || response.status === 201) {
        alert('Application Completed');
      } else {
        throw response;
      }
    } catch (e) {
      alert(e);
    }
  }

  return (
    <>
      {error ? (
        <span>{error.message}</span>
      ) : (
        <>
          <Card variant="outlined">
            <LoadingButton
              loading={saving}
              disabled={!loaded}
              variant="contained"
              onClick={handleTranslateApply}
            >
              Translation Apply
            </LoadingButton>

            <CardContent>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tab}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  {TRANSLATION_TABS.map((value, index, array) => (
                    <Tab
                      key={index}
                      label={value.display}
                      {...a11yProps(index)}
                      disabled={!loaded}
                    />
                  ))}
                </Tabs>
              </Box>
              <Grid container spacing={2} marginTop={2}>
                {(tab === 3 || tab === 2) && (
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <DropdownSearch
                        isLoading={countryStatus === 'loading'}
                        value={country}
                        options={countryStatus === 'success' ? countries : []}
                        placeholder={'Country'}
                        onSelect={(option) => {
                          setCountry(option.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                {tab === 2 && (
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <DropdownSearch
                        isLoading={false}
                        value={category}
                        options={categories}
                        placeholder={'Category'}
                        onSelect={(option) => {
                          setCategory(option.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={3}
                  >
                    <FormControl fullWidth>
                      <TextField
                        disabled={!loaded}
                        value={keyword}
                        onChange={(e) => setKeyWord(e.target.value)}
                        size="small"
                        id="keyword"
                        placeholder="Search tag"
                        onKeyDown={handleKeyDown}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                disabled={!loaded}
                                aria-label="toggle clean"
                                edge="end"
                                onClick={() => setKeyWord('')}
                              >
                                <BackspaceOutlinedIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <Stack direction="column" justifyContent="end">
                      <Button
                        variant="outlined"
                        size="large"
                        disabled={!loaded}
                        onClick={handleFilter}
                      >
                        Search
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <TabPanel value={tab} index={0}>
                No data
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <TranslationTable
                  loading={!loaded}
                  locales={locales}
                  tagColumnName={'CODE'}
                  rows={rows}
                  disableAdd={true}
                  disableDelete={true}
                  onChange={handleChange}
                  onRequestSort={handleRequestSort}
                  order={order}
                />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <TranslationTable
                  loading={!loaded}
                  locales={locales}
                  tagColumnName={'OAG CODE'}
                  rows={rows}
                  disableAdd={true}
                  disableDelete={true}
                  onChange={handleChange}
                  onRequestSort={handleRequestSort}
                  order={order}
                />
              </TabPanel>
              <TabPanel value={tab} index={3}>
                <TranslationTable
                  loading={!loaded}
                  locales={locales}
                  tagColumnName={'CODE'}
                  rows={rows}
                  disableAdd={true}
                  disableDelete={true}
                  onChange={handleChange}
                  onRequestSort={handleRequestSort}
                  order={order}
                />
              </TabPanel>
              {/*<TabPanel value={tab} index={4}>*/}
              {/*  <TranslationTable*/}
              {/*    loading={!loaded}*/}
              {/*    locales={locales}*/}
              {/*    tagColumnName={'TAG'}*/}
              {/*    rows={rowsInAPage}*/}
              {/*    disableAdd={false}*/}
              {/*    disableDelete={false}*/}
              {/*    onSave={handleSingleSave}*/}
              {/*    onChange={handleChange}*/}
              {/*    onAdd={handleAdd}*/}
              {/*    onDelete={handleDelete}/>*/}
              {/*</TabPanel>*/}
            </CardContent>
            <Stack direction="row" justifyContent="center" marginX={3}>
              <LoadingButton
                loading={saving}
                disabled={!loaded}
                variant="contained"
                onClick={handleMultiSave}
              >
                Save
              </LoadingButton>
            </Stack>
            <Stack direction="row" justifyContent="center" marginTop={2}>
              <Typography variant="caption" display="block" gutterBottom>
                If move to the next page, changes will be discarded, please save
                current changes
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="center" marginX={3}>
              <CustomPagination
                page={page}
                totalRows={totalRows}
                onChange={handleChangePage}
              />
            </Stack>
            <CardActions></CardActions>
          </Card>

          <AddTagDialog
            open={openTrans}
            locale={'ko'}
            onClose={() => setOpenTrans(false)}
            onSubmit={handleAddTranslation}
          />
        </>
      )}
    </>
  );
}
