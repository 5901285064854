import { ActionType, IAction, LANGUAGE_NAME, Languages } from '../../constants';
import { LangState } from '../../context/language.context';

export const LanguageReducer = (state: LangState, action: IAction) => {
  switch (action.type) {
  case ActionType.SET_LANGUAGE:
    localStorage.setItem(LANGUAGE_NAME, action.payload);
    return {
      language: action.payload
    };
  default:
    localStorage.setItem(LANGUAGE_NAME, Languages.EN);
    return {
      language: Languages.EN
    };
  }
}
