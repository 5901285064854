import React, { useContext, useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { IColumn } from '../../../../constants';
import { grey } from '@mui/material/colors';
import { IHeaderCell } from '../../constants';
import Checkbox from '@mui/material/Checkbox';
import { UserContext } from '../../../../context/user.context';

const uuid = require('uuid');

interface Props {
  headerRows: IHeaderCell[][];
  columns: IColumn[];
  data: any[];
  total: number;
  setCheckPayment: (orderId: string) => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
}

export const PaymentDataTable = (props: Props): JSX.Element => {
  const {
    headerRows,
    columns,
    data,
    total,
    setCheckPayment,
    onChangePage
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { state: currentUser } = useContext(UserContext);

  useEffect(()=> {
    setPage(0);
    setRowsPerPage(10);
  },[total])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    onChangePage(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            {headerRows.map((headerRow, i1) => (
              <TableRow key={'r' + i1}>
                {headerRow.map((headerCell, i2) => {
                  if (headerCell.only_super_admin && currentUser.agency_id){
                    return;
                  }
                  return(
                    <TableCell
                      key={'r' + i1 + 'c' + i2}
                      align={headerCell.align}
                      rowSpan={headerCell.rowspan}
                      colSpan={headerCell.colspan}
                      style={{
                        minWidth: headerCell.minWidth,
                        verticalAlign: 'bottom',
                        border: 1,
                        borderStyle: 'solid',
                        borderColor: grey[200],
                      }}
                    >
                      <b>{headerCell.label}</b>
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      if (column.only_super_admin && currentUser.agency_id){
                        return;
                      }
                      const field = column.db_field;
                      // @ts-ignore
                      const value = row[field];
                      if (column.db_field === 'no') {
                        return (
                          <TableCell key={index} align={column.align}>
                            <b>{index + 1}</b>
                          </TableCell>
                        );
                      }
                      return column.db_field === 'pay_to_supplier' ? (
                        <TableCell key={uuid.v4()}>
                          <Checkbox checked={value === 1} onChange={(e)=> setCheckPayment(row['order_id']) } />
                        </TableCell>
                      ) : (
                        <TableCell key={uuid.v4()} align={column.align}>
                          {column.format
                            ? column.format(value)
                            : typeof value == 'number'
                              ? value.toLocaleString('en-US')
                              : (value ? value : '-')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
