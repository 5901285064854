import { AxiosClient } from './axios-client';
import { IAdminRole } from '../constants';

const path = 'admin/roles';

export const AdminRoleApi = {
  getAll: async () => {
    const res: IAdminRole[] = await AxiosClient.get(path);
    return res;
  },
  getOne: async (id: number): Promise<IAdminRole> =>{
    const res: any = await AxiosClient.get(`${path}/${id}`);
    const permissions = res.admin_roles_permissions.map((per: any) => per.permission_id);
    delete res.admin_roles_permissions;
    return { ...res, permissions };
  },
  create: (name: string,
    description: string,
    permissions: number[]) =>{
    return AxiosClient.post(path, {
      name,
      description,
      permissions
    })
  },
  update: (id: number,
    name: string,
    description: string,
    permissions: number[]) => {
    return AxiosClient.patch(`${path}/${id}`, {
      name,
      description,
      permissions
    })
  },
  del: (id: number) => {
    return AxiosClient.delete(`${path}/${id}`);
  }
}
