import React from 'react';
import { IOptionItem } from '../../constants';
import Select, { MultiValue } from 'react-select';

interface Props {
    options: IOptionItem[];
    placeholder?: string;
    value?: any;
    isLoading?: boolean;
    isMulti?: boolean;
    isDisabled?: boolean;
    onSelect: (option: any) => void;
}

export const DropdownSearch = (props: Props): JSX.Element => {
  const { options, placeholder, value, isLoading, isMulti, isDisabled, onSelect } = props;

  const handleChange = (option: IOptionItem | null, actionMeta: any) => {
    if (option) {
      onSelect(option);
    } else {
      onSelect({
        label: '', value: ''
      })
    }
  }

  const handleMultiChange = (option: MultiValue<IOptionItem>, actionMeta: any) => {
    onSelect(option);
  }

  if (!isMulti) { //single
    return (
      <>
        <Select
          isDisabled={isDisabled}
          placeholder={placeholder}
          options={options}
          isClearable={true}
          isSearchable={true}
          onChange={handleChange}
          menuPortalTarget={document.body}
          value={
            options.filter(option =>
              option.value === value)
          }
          isLoading={isLoading}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>
      </>
    )
  } else { // multi
    return (
      <Select
        isDisabled={isDisabled}
        placeholder={placeholder}
        options={options}
        isClearable={true}
        isSearchable={true}
        onChange={handleMultiChange}
        menuPortalTarget={document.body}
        value={value}
        isMulti={isMulti}
        isLoading={isLoading}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>
    )
  }

}
