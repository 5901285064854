import { SvgIcon } from '@mui/material';

export function KRIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="-36 -24 72 48">
      <rect fill="#fff" x="-36" y="-24" width="72" height="48" />
      <g transform="rotate(-56.3099325)">
        <g id="b2">
          <path
            id="b"
            d="M-6-25H6M-6-22H6M-6-19H6"
            stroke="#000"
            strokeWidth="2"
          />
          <use xlinkHref="#b" y="44" />
        </g>
        <path stroke="#fff" strokeWidth="1" d="M0,17v10" />
        <circle fill="#c60c30" r="12" />
        <path
          fill="#003478"
          d="M0-12A6,6 0 0 0 0,0A6,6 0 0 1 0,12A12,12 0 0,1 0-12Z"
        />
      </g>
      <g transform="rotate(-123.6900675)">
        <use xlinkHref="#b2" />
        <path stroke="#fff" strokeWidth="1" d="M0-23.5v3M0,17v3.5M0,23.5v3" />
      </g>
    </SvgIcon>
  );
}

export function USIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 7410 3900">
      <rect width="7410" height="3900" fill="#b22234" />
      <path
        d="M0,450H7410m0,600H0m0,600H7410m0,600H0m0,600H7410m0,600H0"
        stroke="#fff"
        strokeWidth="300"
      />
      <rect width="2964" height="2100" fill="#3c3b6e" />
      <g fill="#fff">
        <g id="s18">
          <g id="s9">
            <g id="s5">
              <g id="s4">
                <path
                  id="s"
                  d="M247,90 317.534230,307.082039 132.873218,172.917961H361.126782L176.465770,307.082039z"
                />
                <use xlinkHref="#s" y="420" />
                <use xlinkHref="#s" y="840" />
                <use xlinkHref="#s" y="1260" />
              </g>
              <use xlinkHref="#s" y="1680" />
            </g>
            <use xlinkHref="#s4" x="247" y="210" />
          </g>
          <use xlinkHref="#s9" x="494" />
        </g>
        <use xlinkHref="#s18" x="988" />
        <use xlinkHref="#s9" x="1976" />
        <use xlinkHref="#s5" x="2470" />
      </g>
    </SvgIcon>
  );
}
