import React, { useContext, useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { EPermission, Languages, RoutePath, SIDEBAR_WIDTH } from '../../constants';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { ProfileModal } from '../ProfileModal';
import './styles.css';
import { Box, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StyledMenu } from '../StyledMenu';
import { LangContext } from '../../context/language.context';
import { LanguageModal } from '../LanguageModal';
import { UserContext } from '../../context/user.context';

const drawerWidth = SIDEBAR_WIDTH;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


interface Props {
    onOpenSidebar?: () => void;
    open: boolean;
}

const pages = [
  {
    label: 'reservation',
    path: RoutePath.RESERVATION,
    permission: EPermission.RESERVATION,
  },
  {
    label: 'ppd',
    path: RoutePath.PPD_RESERVATION,
    permission: EPermission.SYSTEM,
  },
  {
    label: 'client',
    path: RoutePath.CLIENTS,
    permission: EPermission.CLIENT,
  },
  {
    label: 'payment',
    path: RoutePath.PAYMENT,
    permission: EPermission.PAYMENT,
  },
  {
    label: 'system',
    path: RoutePath.SETTINGS,
    permission: EPermission.SYSTEM,
    menus: [
      {
        label: 'translation',
        path: RoutePath.TRANSLATION,
      },
      {
        label: 'countries',
        path: RoutePath.COUNTRIES,
      },
      {
        label: 'cities',
        path: RoutePath.CITIES,
      },
      {
        label: 'location',
        path: RoutePath.LOCATIONS,
      },
      {
        label: 'coupon',
        path: RoutePath.COUPON,
      },
      {
        label: 'adminUsers',
        path: RoutePath.ADMIN_USERS
      },
      {
        label: 'roles',
        path: RoutePath.USER_ROLES
      },
      {
        label: 'faq',
        path: RoutePath.FAQ
      },
      {
        label: 'exchangeRateHistory',
        path: RoutePath.EXCHANGE_RATE_HISTORY
      }
    ]
  },
  {
    label: 'users',
    path: RoutePath.USERS,
    permission: EPermission.USER,
  },
];

const languages = [
  {
    language: Languages.KO,
    icon: '/korean_flag.png'
  },
  {
    language: Languages.EN,
    icon: '/us_flag.png'
  }
]

export const Header = (props: Props): JSX.Element => {
  const { dispatch: { translate, setLanguage }, state } = useContext(LangContext);
  const { state: currentUser } = useContext(UserContext);
  const { onOpenSidebar, open } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [langAnchorEl, setLangAnchorEl] = useState(null);
  const [lang, setLang] = useState(languages[0]);
  const openMenu = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(()=> {
    const selectedLang = languages.filter(l => l.language === state.language);
    setLang(selectedLang[0]);
  },[state.language])

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleOpen = ($event: any) => {
    setAnchorEl($event.currentTarget);
  }

  const handleNavigate = (path: string) => {
    navigate(path);
    setAnchorEl(null);
  };

  const renderMenuButton = (page: any) => {
    if ('menus' in page) {
      return (
        <div key={`${page.label}-div`}>
          <Button
            id={`${page.label}-button`}
            aria-controls={openMenu ? `${page.label}-menu` : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            key={page.path}
            size="medium"
            onClick={handleOpen}
            sx={{ my: 2, color: 'white' }}
            variant="contained"
            disableElevation
            endIcon={<KeyboardArrowDownIcon />}
          >
            {translate(page.label)}
          </Button>
          <StyledMenu
            id={`${page.label}-menu`}
            MenuListProps={{
              'aria-labelledby': `${page.label}-button`,
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
          >
            {
              page.menus.map((menu: any) => (
                <MenuItem key={menu.label} disableRipple onClick={() => handleNavigate(menu.path) }>
                  {translate(menu.label)}
                </MenuItem>
              ))
            }
          </StyledMenu>
        </div>
      )
    }
    return (
      <Button
        key={page.path}
        size="medium"
        onClick={() => handleNavigate(page.path)}
        sx={{ my: 2, color: 'white', display: 'block' }}
      >
        {translate(page.label)}
      </Button>
    )
  }

  return (
    <AppBar position="fixed" open={open} color='primary'>
      <Toolbar>
        <img
          onClick={() => navigate('/')}
          src={'/logo_trimo.png'}
          alt="logo"
          className='header-logo'
        />

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {pages.map((page) => {
            return currentUser.permissions.includes(page.permission.valueOf()) && renderMenuButton(page);
          })}
        </Box>
        <IconButton onClick={(e: any) => {setLangAnchorEl(e.currentTarget)}}>
          <img
            src={lang.icon}
            alt="flag"
            className="flag"
          />
        </IconButton>
        <IconButton onClick={(e: any) => {setUserAnchorEl(e.currentTarget)}}>
          <AccountCircleRoundedIcon fontSize='large' sx={{ color: 'white' }}/>
        </IconButton>
      </Toolbar>
      <ProfileModal anchorEl={userAnchorEl} onClose={() => setUserAnchorEl(null) }/>
      <LanguageModal anchorEl={langAnchorEl} languages={languages} onClose={() => setLangAnchorEl(null) } onChange={setLanguage} />
    </AppBar>
  );
}
