import { KeyValue } from './index';

export interface IAdminUser{
    id? : string;
    username: string;
    email: string;
    status: string;
    first_name?: string;
    last_name: string;
    phone_mobile?: string;
    address?: string;
    created_date?: string;
    modified_date? :string;
    created_by?: string;
    admin_users_roles?: any[];
    agencies?: any;
    agency_id?: string;
}

export interface IAdminRole {
  id: number;
  name: string;
  description: string;
  created_date: string;
  modified_date: string;
  created_by: string;
  modified_by: string;
  permissions: number[];
}

export const  AdminStatusLabel: KeyValue = {
  'IN_ACTIVE':'In Active',
  'ACTIVE': 'Active',
  'UNDER_REVIEW':'Under Review'
}

export const AdminUserStatus = [
  'IN_ACTIVE' , 'ACTIVE', 'UNDER_REVIEW'
]

export interface IUser {
  id: number;
  email: string;
  created_date: string;
  modified_date:string;
  deleted: number;
  phone: string;
  login_type: string;
  external_id: string;
  korean_name: string;
  first_name: string;
  last_name: string;
  phone_prefix: string;
  refund_bank: string;
  refund_account_number: string;
  refund_account_holder: string;
  address: string;
  hertz_membership: string;
  airline: string;
  mileage_number: string;
  mailing_email: number;
  mailing_text_msg: number;
}
