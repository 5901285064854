import { BackdropLoading } from '../../../../components/BackdropLoading';
import Box from '@mui/material/Box';
import { Grid, Paper, Stack, TextField } from '@mui/material';
import ActiveLastBreadcrumbs from '../../../../components/Breadcrumbs';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { DataTable } from '../../../../components/DataTable';
import Button from '@mui/material/Button';
import { IBreadcrumb, ICity, ICityTranslation, ILocation, IOptionItem, RoutePath, TIMEZONE } from '../../../../constants';
import { cityTransColumns, locationColumns } from '../../constants';
import { CitiesApi, CountriesApi } from '../../../../api';
import { DropdownSearch } from '../../../../components/DropdownSearch';
import { Toast } from '../../../../components/Toast';

const breadcrumbs: IBreadcrumb[] = [
  { name: 'Cities', href: RoutePath.CITIES },
  { name: 'City', href: '#' }
]
export const CityDetail = (): JSX.Element=>{
  const { code } = useParams();
  const [updateCounter, setUpdateCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('Loading...');
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<ICity>({
    alt: 0, code: '', country_code: '', latitude: 0, longitude: 0, name: '', timezone: '', un_locode: ''
  });
  const [drafData, setDrafData] = useState<ICity>({
    alt: 0, code: '', country_code: '', latitude: 0, longitude: 0, name: '', timezone: '', un_locode: ''
  });
  const [countriesOptions, setCountriesOptions] = useState<IOptionItem[]>([]);
  const [translation, setTranslation] = useState<ICityTranslation[]>([]);
  const [locations, setLocations] = useState<ILocation[]>([]);

  useEffect(() => {
    fetchData();
  }, [updateCounter]);

  const fetchData = async () => {
    setLoading(true);
    try{
      const data = await CitiesApi.getCity(code as string);

      // get general data
      setData({ ...data, country_name: data.countries.name });

      // set draf data
      setDrafData({ ...data, country_name: data.countries.name });

      // get translation data
      const transTemp: ICityTranslation[] = [];
      data.cities_translation.forEach((element: any) => {
        transTemp.push({
          city_code: element.city_code,
          locale: element.locale,
          city_name: element.city_name,
        });
      });
      setTranslation(transTemp);

      // get locations data
      // const locationsTemp: ILocation[] = [];
      // data.location.forEach((element: any) => {
      //   locationsTemp.push(element)
      // });
      setLocations(data.locations);

      // set loading equal false
      setLoading(false);

      // countries options
      const result = await CountriesApi.getCountries();
      const options: IOptionItem[] = [];
      result.forEach((value) => {
        options.push({
          value: value.code,
          label: value.name
        })
      });
      setCountriesOptions(options);
    }catch (e: any) {
      setMessage(e.message);
    }
  }

  const cancelEditMode = () => {
    setEditMode(false);
    // reset draf data
    setDrafData(data);
  }

  const editModeButtons = () => {
    return (
      <Stack direction='row' spacing={1}>
        <Button variant='outlined' onClick={cancelEditMode}>Cancel</Button>
        <Button variant='contained' onClick={handleSubmitSave}>Save</Button>
      </Stack>
    )
  }

  const readModeButton = () => {
    return (
      <Button  variant='contained' onClick={() => setEditMode(true)}>Edit</Button>
    )
  }

  const handleChangeData = (value: string, field: string) => {
    switch (field) {
    case 'name':
      setDrafData((prevState) => {
        return {
          ...prevState, name: value
        }
      })
      break;
    case 'code':
      setDrafData((prevState) => {
        return {
          ...prevState, code: value
        }
      })
      break;
    case 'latitude':
      setDrafData((prevState) => {
        return {
          ...prevState, latitude: parseInt(value)
        }
      })
      break;
    case 'longitude':
      setDrafData((prevState) => {
        return {
          ...prevState, longitude: parseInt(value)
        }
      })
      break;
    case 'un_locode':
      setDrafData((prevState) => {
        return {
          ...prevState, un_locode: value
        }
      })
      break;
    case 'alt':
      setDrafData((prevState) => {
        return {
          ...prevState, alt: parseInt(value)
        }
      })
      break;
    case 'timezone':
      setDrafData((prevState) => {
        return {
          ...prevState, timezone:value
        }
      })
      break;
    case 'country':
      setDrafData((prevState) => {
        return {
          ...prevState, country_code: value
        }
      })
      break;
    default:
    }
  }

  const handleSubmitSave = async () => {
    if (!drafData.name || !drafData.longitude || !drafData.latitude || !drafData.country_code){
      await Toast.fire({
        title: 'Required fields must be filled out',
        icon: 'warning'
      });
      return;
    }

    setLoading(true);

    CitiesApi.updateCity(drafData)
      .then(async (res)=>{
        setEditMode(false);
        setUpdateCounter(old=> old + 1);
        await Toast.fire({
          title: 'Updated',
          icon: 'success'
        });
      })
      .catch( async (err)=> {
        await Toast.fire({
          title: err.message,
          icon: 'error'
        });
        setLoading(false);
      })
  }

  const subPanel = () => {
    return(
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">City translation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable
              loading={loading}
              columns={cityTransColumns} data={translation} onView={() => {
              }} onDelete={() => {
              }} onChangePage={() => {
              }}
              disableActions={true}/>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant='h6'>Locations</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable loading={loading} columns={locationColumns} data={locations}
              disableActions={true}/>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }

  return (
    <>
      {
        loading ? <span>{message}</span>
          :
          <Box sx={{ flex: 1 }}>
            <Stack direction='row' justifyContent='space-between'>
              <Stack justifyContent='center'>
                <ActiveLastBreadcrumbs items={breadcrumbs}/>
              </Stack>

              <Stack justifyContent='center'>
                {
                  editMode ? editModeButtons() : readModeButton()
                }
              </Stack>
            </Stack>

            <Paper sx={{ width: '100%', p: 2, mt: 3 }}>
              <Typography variant="h4" gutterBottom component="div">
                                General
              </Typography>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <b>Name: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        value={drafData.name}
                        size="small"
                        variant="outlined"
                        placeholder='Required'
                        onChange={(e) => handleChangeData(e.target.value, 'name')}
                      />
                    </div>

                    :
                    data.name}
                </Grid>
                <Grid item xs={6}>
                  <b>Code: </b> {editMode ?
                    <div>
                      <TextField
                        sx={{ width: '100%' }}
                        disabled={true}
                        value={data.code}
                        size="small"
                        variant="outlined"
                        placeholder='Required'
                        onChange={(e) => handleChangeData(e.target.value, 'code')}
                      /></div> :
                    data.code}
                </Grid>
                <Grid item xs={6}>
                  <b>Latitude: </b> {editMode ?
                    <div><TextField
                      sx={{ width: '100%' }}
                      value={drafData.latitude}
                      size="small"
                      variant="outlined"
                      placeholder='Required'
                      type={'number'}
                      onChange={(e) => handleChangeData(e.target.value, 'latitude')}
                    /></div> : data.latitude}
                </Grid>
                <Grid item xs={6}>
                  <b>Longitude: </b> {editMode ?
                    <div><TextField
                      sx={{ width: '100%' }}
                      value={drafData.longitude}
                      size="small"
                      variant="outlined"
                      placeholder='Required'
                      type={'number'}
                      onChange={(e) => handleChangeData(e.target.value, 'longitude')}
                    /></div> : data.longitude}
                </Grid>
                <Grid item xs={6}>
                  <b>UN Locode: </b> {editMode ?
                    <div><TextField
                      sx={{ width: '100%' }}
                      value={drafData.un_locode}
                      size="small"
                      variant="outlined"
                      placeholder='Optional'
                      onChange={(e) => handleChangeData(e.target.value, 'un_locode')}
                    /></div> : data.un_locode}
                </Grid>
                <Grid item xs={6}>
                  <b>Alt: </b> {editMode ?
                    <div><TextField
                      sx={{ width: '100%' }}
                      value={drafData.alt}
                      size="small"
                      variant="outlined"
                      type={'number'}
                      placeholder='Optional'
                      onChange={(e) => handleChangeData(e.target.value, 'alt')}
                    /></div> : data.alt}
                </Grid>
                <Grid item xs={6}>
                  <b>Timezone: </b> {editMode ?
                    <DropdownSearch
                      options={TIMEZONE}
                      placeholder={'Optional'}
                      value={drafData.timezone}
                      onSelect={(option) => handleChangeData(option.value, 'timezone') }  />
                    : data.timezone}
                </Grid>
                <Grid item xs={6}>
                  <b>Country: </b> {editMode ?
                    <DropdownSearch
                      options={countriesOptions}
                      placeholder={'Required'}
                      value={drafData.country_code}
                      onSelect={(option) => handleChangeData(option.value, 'country') }  />
                    : data.country_name}
                </Grid>
              </Grid>
            </Paper>

            <Box sx={{ mt: 2 }}>
              {subPanel()}
            </Box>
          </Box>
      }
    </>
  );
}
