import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { DataTable } from '../../../../components/DataTable';
import { RoutePath } from '../../../../constants';
import { AddCountryDialog } from '../../components/AddCountryDialog';
import { CountriesApi } from '../../../../api';
import ResponsiveDialog from '../../../../components/ResponsiveDialog';
import { useNavigate } from 'react-router-dom';
import { countryColumns } from '../../constants';
import { Toast } from '../../../../components/Toast';
import { PageHeader } from '../../../../components/PageHeader';
import { FilterBox } from '../../../../components/FilterBox';
import { useQuery } from 'react-query';

const columns = countryColumns;

export const Countries = (): JSX.Element => {
  const [openAdd, setOpenAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [originalCountries, setOriginalCountries] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedCode, setSelectedCode]= useState('');
  const [keyword, setKeyword]= useState('');
  const navigate = useNavigate();
  const { data, error } = useQuery<any[], Error>(['getCountries'], () => CountriesApi.getCountries(), { retry: 1 });
  useEffect(()=>{
    if (data){
      setOriginalCountries(data);
      setCountries(data);
      setLoading(false);
    }
  },[data]);

  if (error){
    return <span>{error.message}</span>
  }

  const handleView = (value: any) => {
    navigate(`/${RoutePath.COUNTRIES}/${value.code}`);
  }

  const handleOpenDelete = (value: any) => {
    setSelectedCode(value.code);
    setOpenDelete(true);
  }

  const handleDelete = () => {
    CountriesApi.deleteCountry(selectedCode)
      .then( async (res)=>{
        await Toast.fire({
          title: 'Added.',
          icon: 'success'
        });
        setOpenDelete(false);
      })
      .catch(async (err)=>{
        await Toast.fire({
          title: err.message,
          icon: 'error'
        });
        setOpenDelete(false);
      })
  }

  const handleChangePage = () => {

  }

  const handleFilter = () => {
    if (keyword === ''){
      setCountries(originalCountries);
    }else{
      const filterData = originalCountries.filter((country) =>  country.name.toLowerCase().includes(keyword.toLowerCase()));
      setCountries(filterData);
    }
  }

  return(
    <Box sx={{ flex: 1 }}>
      <PageHeader pageTitle='Countries' actionTitle='Add' onAction={()=> setOpenAdd(true)}/>
      <FilterBox keyword={keyword} placeholer={'Search name'} onChange={setKeyword} onFilter={handleFilter} />
      <DataTable
        loading={loading}
        columns={columns}
        data={countries}
        onView={handleView}
        onDelete={handleOpenDelete}
        onChangePage={handleChangePage}/>

      <AddCountryDialog open={openAdd} onClose={()=> setOpenAdd(false)}/>
      <ResponsiveDialog open={openDelete}
        title={'Remove?'}
        content={'Are you sure to remove the country?'}
        agreeBtnText={'Sure'}
        disagreeBtnText={'Back'}
        handleAgree={handleDelete}
        handleDisagree={() => setOpenDelete(false)}/>
    </Box>
  );
}
