import { AxiosClient } from './axios-client';

const path = 'faq';

interface IUpdateFAQ {
  id: number;
  question_kr: string;
  answer_kr: string;
  question_us: string;
  answer_us: string;
  sort_order: number;
  status: string;
}

interface IAddFAQ {
  question_kr: string;
  answer_kr: string;
  question_us: string;
  answer_us: string;
  sort_order: number;
  status: string;
}

export const FAQApi = {
  getAll: async () => {
    const res: any[] = await AxiosClient.get(`${path}`);
    return res;
  },
  updateFAQ: async (newData: IUpdateFAQ) => {
    newData.sort_order as number;
    console.log('API: newData: ', newData);
    console.log('API: newData: ', typeof newData.sort_order);
    const res = await AxiosClient.patch(`${path}/update`, newData);
    return res;
  },
  addFAQ: async (addData: IAddFAQ) => {
    addData.sort_order as number;
    const res = await AxiosClient.post(`${path}`, addData);
    return res;
  },
};
