import { Box, Card, CardActions, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const createData = (
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
) => {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Data 1', 1, 1, 1, 1),
  createData('Data 2', 2, 2, 2, 2),
  createData('Data 3', 3, 3, 3, 3),
];

export const Dashboard = () => {
  return (
    <Box sx={{ flex: 1, margin: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Example card
                  </Typography>
                  <Typography variant="h5" component="div">
                    Example card
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Example card
                  </Typography>
                  <Typography variant="body2">
                    No data
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Example card
                  </Typography>
                  <Typography variant="h5" component="div">
                    Example card
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Example card
                  </Typography>
                  <Typography variant="body2">
                    No data
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Example card
                  </Typography>
                  <Typography variant="h5" component="div">
                    Example card
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Example card
                  </Typography>
                  <Typography variant="body2">
                    No data
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <caption>A basic table example with a caption</caption>
              <TableHead>
                <TableRow>
                  <TableCell>Column 1</TableCell>
                  <TableCell align="right">Column 2</TableCell>
                  <TableCell align="right">Column 3</TableCell>
                  <TableCell align="right">Column 4</TableCell>
                  <TableCell align="right">Column 5</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.calories}</TableCell>
                    <TableCell align="right">{row.fat}</TableCell>
                    <TableCell align="right">{row.carbs}</TableCell>
                    <TableCell align="right">{row.protein}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  )
}
