import React, { Fragment } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { SignIn } from './features/auth/pages/SignIn';
import { Layout } from './components/Layout';
import { LocalesList } from './features/translation/pages/LocalesList';
import { Countries } from './features/location/pages/Countries';
import { Cities } from './features/location/pages/Cities';
import { Translation } from './features/translation/pages/Translation';
import { PrivateRoute } from './components/PrivateRoute';
import { AdminUsers } from './features/admin-user/pages/Users';
import { CountryDetail } from './features/location/pages/CountryDetail';
import { RoutePath } from './constants';
import { CityDetail } from './features/location/pages/CityDetail';
import { Locations } from './features/location/pages/Locations';
import { LocationDetail } from './features/location/pages/LocationDetail';
import { Categories } from './features/location/pages/Categories';
import { Reservation } from './features/reservation/pages/Reservation';
import { PaymentTable } from './features/payment/pages';
import { ImportLocation } from './features/location/pages/ImportLocation';
import { Coupon } from './features/coupon/pages';
import { AddUpdateCoupon } from './features/coupon/pages/AddUpdateCoupon';
import { FAQ } from './features/faq/pages';
import { FAQEdit } from './features/faq/pages/FAQEdit';
import { AdminUserDetail } from './features/admin-user/pages/UserDetail';
import { AdminRoles } from './features/admin-user/pages/Roles';
import { AdminRoleDetail } from './features/admin-user/pages/RoleDetail';
import { Dashboard } from './features/dashboard';
import { ChangePassword } from './features/admin-user/pages/ChangePassword';
import { ErrorMessage } from './features/system/pages/ErrorMessage';
import { Users } from './features/user/pages/Users';
import { UserDetail } from './features/user/pages/UserDetail';
import { ExchangeRateHistory } from './features/exchange-rate/pages';
import { Clients } from './features/client/pages/Clients';
import { ClientDetail } from './features/client/pages/ClientDetail';
import { PPDReservation } from './features/ppd-reservation/pages';

function App() {

  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Dashboard />}/>
              {/*<Route path={RoutePath.SETTINGS} element={<GeneralSetting />} />*/}
              <Route path={RoutePath.LOCALES} element={<LocalesList />} />
              <Route path={RoutePath.COUNTRIES} element={<Countries />} />
              <Route path={`${RoutePath.COUNTRIES}/:code`} element={<CountryDetail />}/>
              <Route path={RoutePath.CITIES} element={<Cities />} />
              <Route path={`${RoutePath.CITIES}/:code`} element={<CityDetail />}/>
              <Route path={RoutePath.CATEGORIES} element={<Categories />} />
              <Route path={RoutePath.LOCATIONS} element={<Locations />} />
              <Route path={RoutePath.IMPORT_LOCATION} element={<ImportLocation />}/>
              <Route path={`${RoutePath.LOCATIONS}/:id`} element={<LocationDetail />}/>
              <Route path={RoutePath.TRANSLATION} element={<Translation />} />
              <Route path={RoutePath.ADMIN_USERS} element={<AdminUsers />} />
              <Route path={`${RoutePath.ADMIN_USERS}/:id`} element={<AdminUserDetail />}/>
              <Route path={RoutePath.USER_ROLES} element={<AdminRoles />} />
              <Route path={`${RoutePath.USER_ROLES}/:id`} element={<AdminRoleDetail />}/>
              <Route path={RoutePath.RESERVATION} element={<Reservation />} />
              <Route path={RoutePath.PPD_RESERVATION} element={<PPDReservation />} />
              <Route path={RoutePath.PAYMENT} element={<PaymentTable />} />
              <Route path={RoutePath.COUPON} element={<Coupon />} />
              <Route path={RoutePath.ADD_UPDATE_COUPON} element={<AddUpdateCoupon />}/>
              <Route path={RoutePath.FAQ} element={<FAQ />} />
              <Route path={`${RoutePath.FAQ}/add_update_faq`} element={<FAQEdit />}/>
              <Route path={`${RoutePath.CHANGE_PW}`} element={<ChangePassword />}/>
              <Route path={`${RoutePath.ERRORS}`} element={<ErrorMessage />}/>
              <Route path={`${RoutePath.USERS}`} element={<Users />}/>
              <Route path={`${RoutePath.USERS}/:id`} element={<UserDetail />}/>
              <Route path={RoutePath.CLIENTS} element={<Clients />}/>
              <Route path={`${RoutePath.CLIENTS}/:id`} element={<ClientDetail />}/>
              <Route
                path={RoutePath.EXCHANGE_RATE_HISTORY}
                element={<ExchangeRateHistory />}
              />
            </Route>
          </Route>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Fragment>
    </BrowserRouter>
  );
}

export default App;
