import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { DataTable } from '../../../../components/DataTable';
import { citiesColumns } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { CitiesApi } from '../../../../api';
import { AddCityDialog } from '../../components/AddCityDialog';
import ResponsiveDialog from '../../../../components/ResponsiveDialog';
import { RoutePath } from '../../../../constants';
import { Toast } from '../../../../components/Toast';
import { PageHeader } from '../../../../components/PageHeader';
import { FilterBox } from '../../../../components/FilterBox';
import { useQuery } from 'react-query';

const columns = citiesColumns;

export const Cities = (): JSX.Element => {
  const [openAdd, setOpenAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState<any[]>([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedCode, setSelectedCode] = useState('');
  const [originalCities, setOriginalCities] = useState<any[]>([]);
  const [keyword, setKeyword]= useState('');
  const navigate = useNavigate();
  const { data, error } = useQuery<any[], Error>(['getCities'], () => CitiesApi.getCities(), { retry: 1 });
  useEffect(() => {
    if (data){
      setCities(data);
      setOriginalCities(data);
      setLoading(false);
    }
  }, [data]);

  if (error){
    return <span>{error.message}</span>
  }

  const handleView = (value: any) => {
    navigate(`/${RoutePath.CITIES}/${value.code}`);
  }

  const handleDelete = () => {
    CitiesApi.removeCity(selectedCode)
      .then(async (res)=>{
        await Toast.fire({
          title: 'Removed.',
          icon: 'success'
        });

        //update UI
        // const removedIndex = cities.findIndex((value) => value.coode === selectedCode);
        const newRows = cities.filter((value, index, array)=> value.code !== selectedCode);
        // newRows.splice(removedIndex,1);
        setCities(newRows);

        setOpenDelete(false);
      })
      .catch(async (err)=>{
        await Toast.fire({
          title: err.message,
          icon: 'error'
        });
        setOpenDelete(true);
      })
  }

  const handleOpenDelete = (value: any) => {
    setSelectedCode(value.code);
    setOpenDelete(true);
  }

  const handleChangePage = () => {

  }

  const handleFilter = () => {
    if (keyword === ''){
      setCities(originalCities);
    }else{
      const filterData = originalCities.filter((city) =>  city.name.toLowerCase().includes(keyword.toLowerCase()) || city.code.toLowerCase().startsWith(keyword.toLowerCase()));
      setCities(filterData);
    }
  }

  return (
    <>
      <Box sx={{ flex: 1 }}>
        <PageHeader pageTitle='Cities' actionTitle='Add' onAction={()=> setOpenAdd(true)}/>
        <FilterBox keyword={keyword} placeholer={'Search code or name'} onChange={setKeyword} onFilter={handleFilter} />
        <DataTable
          loading={loading}
          columns={columns}
          data={cities}
          onView={handleView}
          onDelete={handleOpenDelete}
          onChangePage={handleChangePage}/>

        <AddCityDialog open={openAdd} onClose={()=> setOpenAdd(false)}/>

        <ResponsiveDialog
          open={openDelete}
          title={'Remove?'}
          content={'Are you sure to remove the city?'}
          agreeBtnText={'Sure'}
          disagreeBtnText={'Back'}
          handleAgree={handleDelete}
          handleDisagree={()=> setOpenDelete(false)}/>
      </Box>
    </>
  );
}
