import axios from 'axios';
import { AxiosClient } from './axios-client';

const path = 'reservations';

export const ReservationApi = {
  getAll: async (offset: number, limit: number) => {
    const response: { res_num: number; res: any[] } = await AxiosClient.get(
      `v2/reservation?limit=${limit}&offset=${offset}`,
    );
    return response;
  },
  getPPDAll: async (startDate: string, endDate: string) => {
    const response: { res_num: number; res: any[] } = await AxiosClient.get(
      `v2/reservation/ppd-v2?startDate=${startDate}&endDate=${endDate}`,
    );
    return response;
  },
  filter: async (
    supplier: string,
    dateRange: { startDate: string; endDate: string },
    keyword: string,
    offset: number,
    limit: number,
  ) => {
    const queryString = {};
    if (supplier) {
      Object.assign(queryString, { supplier: supplier });
    }
    if (dateRange.startDate && dateRange.endDate) {
      Object.assign(queryString, {
        begin_date: dateRange.startDate,
        end_date: dateRange.endDate,
      });
    }
    if (keyword) {
      Object.assign(queryString, { keyword: keyword });
    }
    Object.assign(queryString, { offset, limit });
    const response: { res_num: number; res: any[] } = await AxiosClient.get(
      `${path}/filter`,
      { params: queryString },
    );
    return response;
  },
  download: async (
    supplier: string,
    dateRange: { startDate: string; endDate: string },
    keyword: string,
    agencyId: string,
  ) => {
    const queryString: Record<string, string> = {};

    if (supplier) queryString.supplier = supplier;
    if (dateRange.startDate && dateRange.endDate) {
      queryString.begin_date = dateRange.startDate;
      queryString.end_date = dateRange.endDate;
    }
    if (keyword) queryString.keyword = keyword;
    if (agencyId) queryString.agency_id = agencyId;

    const response = await AxiosClient.get(`${path}/download`, {
      params: queryString,
      responseType: 'arraybuffer',
    });
    return response;
  },
  cancel: async (
    last_name: string,
    res_id: string,
    supplier: string,
    admin_user_name: string,
  ) => {
    return await AxiosClient.post('v2/reservation/cancel-v2', {
      last_name: last_name,
      reservation_id: res_id,
      vendor_id: supplier,
      admin_user_name: admin_user_name,
    });
  },
  refresh: async (start_date: string, end_date: string) => {
    const response: {
      statusCode: number;
      message?: number | string | string[];
      error?: string;
    } = await AxiosClient.get(
      `v2/reservation/refresh?start_date=${start_date}&end_date=${end_date}`,
    );
    return response;
  },
  resendEmail: async (changedResId: string) => {
    const response: { success: boolean; errorCode: number } =
      await AxiosClient.post('v2/reservation/mailresend', {
        res_id: changedResId,
      });
    return response;
  },
  cancelOT: async (res_id: string, admin_user_name: string) => {
    const response = await AxiosClient.post(
      'v2/reservation/cancel-onlineTravel',
      {
        res_id,
        admin_user_name: admin_user_name,
      },
    );
    return response;
  },
  changeResId: async (resId: string, changedResId: string) => {
    const response: { success: boolean; errorCode: number } =
      await AxiosClient.post('v2/reservation/confirmed-v2', {
        res_id: resId,
        changed_res_id: changedResId,
      });
    return response;
  },
  kakaoResend: async (resId: string) => {
    const response: { result: boolean } = await AxiosClient.post(
      'v2/reservation/talkresend',
      {
        res_id: resId,
      },
    );
    return response;
  },
};
