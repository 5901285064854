import { AxiosClient } from './axios-client';
import { ISetting } from '../constants';

const path = 'setting';

export const SettingsApi = {
  getSettingByCategory: async (category: string) => {
    const response: any[] = await AxiosClient.get(`${path}/by-category?category=${category}`);
    return response;
  },
  getSetting: async (setting: ISetting) => {
    const response: any = await AxiosClient.get(`${path}/?category=${setting.category}&name=${setting.name}`);
    return response;
  },
  uploadLogo: (file: any) => {
    const url = 'upload/logo';
    const body = new FormData();
    body.append('file', file);
    return AxiosClient.post(url, body);
  },
  saveSetting: (data: ISetting) => {
    return AxiosClient.patch(`${path}`, data);
  },
  saveMultipleSettings: (data: ISetting[]) => {
    return AxiosClient.patch(`${path}/multiple`, data);
  }
}
